import ArchiveIcon from '@material-ui/icons/Archive';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green, orange } from '@material-ui/core/colors';
import { TmplStatus } from 'xacmn';
import { Chip } from '@material-ui/core';

interface TplStatusProp {
  tplStatus: TmplStatus;
}

const TplStatus: React.FC<TplStatusProp> = ({ tplStatus }) => {
  const chipIcon = () => {
    switch (tplStatus) {
      case 'draft':
        return <BorderColorIcon style={{ color: orange[800] }} />;

      case 'published':
        return <CheckCircleIcon style={{ color: green[500] }} />;
      case 'archived':
        return <ArchiveIcon />;
    }
  };

  const chipStyle = () => {
    switch (tplStatus) {
      case 'draft':
        return { backgroundColor: orange[200] };
      case 'published':
        return { backgroundColor: 'lightgreen' };
      case 'archived':
        return { backgroundColor: 'lightgrey' };
      default:
        return {};
    }
  };

  return <Chip icon={chipIcon()} label={tplStatus} style={chipStyle()} />;
};

export default TplStatus;
