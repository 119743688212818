import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Scrollbars } from 'react-custom-scrollbars';
import { useAppDispatch, useAppSelector as useSelector } from '../../app/configureStore';
import TaskCardCtr from '../OA/TaskCardCtr';
import { Task } from 'xacmn';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { selectDisplayCompleted, setDisplayCompleted } from './taskSlice';
import TaskAdd from './TaskAdd';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
      padding: theme.spacing(0, 2),
      marginTop: `${theme.spacing(2)}px`,
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
    header: {
      marginTop: `${theme.spacing(1)}px`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerTitle: {
      flex: '0 1 auto',
      display: 'flex',
      alignItems: 'center',
    },
    headerSwitch: {
      flex: '0 1 auto',
    },
    switchLabel: {
      marginRight: '0px',
    },
    taskContainer: {
      margin: `${theme.spacing(2)}px auto`,
    },
    scrollBarContainer: {
      flexGrow: 1,
    },
  }),
);

interface TaskCardListProps {
  tasks: Task[];
  loadingName: boolean;
}

const TaskCardList: React.FC<TaskCardListProps> = ({ tasks, loadingName }) => {
  const classes = useStyles();
  const displayCompleted = useSelector(selectDisplayCompleted());
  const dispatch = useAppDispatch();

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>
          <Typography>Other tasks:</Typography>
        </div>
        <div className={classes.headerSwitch}>
          <FormControlLabel
            className={classes.switchLabel}
            control={
              <Switch
                checked={displayCompleted}
                onChange={() => dispatch(setDisplayCompleted(!displayCompleted))}
                name="showCompletedTasks"
                color="primary"
              />
            }
            label="Completed"
          />
        </div>
      </div>
      <TaskAdd loadingName={loadingName} />
      <div className={classes.scrollBarContainer}>
        <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
          {tasks.map((task) => (
            <div className={classes.taskContainer} key={task.assignmentId}>
              <TaskCardCtr task={task} loadingName={loadingName} />
            </div>
          ))}
        </Scrollbars>
      </div>
    </Paper>
  );
};

export default TaskCardList;
