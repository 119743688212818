import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TaskCtr from './TaskCtr';
import TaskBtnBar from './TaskBtnBar';
import TaskRSB from './TaskRSB';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentLeft: {
      flex: 0.8,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    contentMain: {
      flex: 1,
      display: 'flex',
      overflow: 'auto',
    },
    buttonBar: {
      padding: '14px',
      flex: '0 0 64px',
      borderTop: `2px solid ${grey[200]}`,
    },
    rightSideBar: {
      flex: 0.2,
      display: 'flex',
      flexDirection: 'column',
      minWidth: '300px',
      padding: theme.spacing(2),
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  }),
);

const TaskFrame: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.contentLeft}>
        <div className={classes.contentMain}>
          <TaskCtr />
        </div>
        <Paper className={classes.buttonBar} square elevation={0}>
          <TaskBtnBar />
        </Paper>
      </div>

      <Paper className={classes.rightSideBar} square elevation={0}>
        <TaskRSB />
      </Paper>
    </>
  );
};

export default TaskFrame;
