import * as React from 'react';
import {
  CellUICfg,
  DummyFn,
  Loc,
  Cell,
  OnSetSheetLTEventHandler,
  OnSetSheetRBEventHandler,
} from 'xacmn';
import Measure from 'react-measure';
import CSS from 'csstype';
import styled from 'styled-components';
import { ReactComponent as EditableIcon } from './editable.svg';
import ExcelCellEditOverlayFloat, {
  ExcelCellEditOverlayFloatProps,
} from './ExcelCellEditOverlayFloat';

export interface ExcelCellEditOverlayProps {
  cell: Cell;
  cellUICfg?: CellUICfg | null;
  sheetLoc?: Loc;
  height?: CSS.Property.Height;
  width?: CSS.Property.Width;
  onToggleCellEditable: DummyFn;
  onSetSheetLT: OnSetSheetLTEventHandler;
  onSetSheetRB: OnSetSheetRBEventHandler;
}

const Overlay = styled.div<{
  $width?: CSS.Property.Width;
  $height?: CSS.Property.Height;
  $editable: boolean;
}>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, ${(props) => (props.$editable ? 0.8 : 0)});
`;

const AnchorBox = styled.div`
  width: 100%;
  height: 100%;
`;

const EditableMark = styled(EditableIcon)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
  }
`;

interface ExcelCellEditOverlayContextValue {
  overAddress: string | null;
  setOverAddress: (index: string | null) => void;
}

const ExcelCellEditOverlayContext = React.createContext<
  ExcelCellEditOverlayContextValue | undefined
>(undefined);

const ExcelCellEditOverlayProvider: React.FC = ({ children }) => {
  const [overAddress, setOverAddress] = React.useState<string | null>(null);
  return (
    <ExcelCellEditOverlayContext.Provider value={{ overAddress, setOverAddress }}>
      {children}
    </ExcelCellEditOverlayContext.Provider>
  );
};

const ExcelCellEditOverlay: React.FC<ExcelCellEditOverlayProps> = (props) => {
  let [x, setX] = React.useState(0);
  let [y, setY] = React.useState(0);
  const context = React.useContext(ExcelCellEditOverlayContext);
  if (!context) {
    throw new Error('ExcelCellEditOverlay must be used within a ExcelCellEditOverlayProvider');
  }
  const { overAddress, setOverAddress } = context;

  const editable = !!props.cellUICfg?.editable;

  const overlayProps: ExcelCellEditOverlayFloatProps = { ...props, x, y };

  function setOverFalse() {
    setOverAddress(null);
  }

  return (
    <Overlay
      $width={overlayProps.width}
      $height={overlayProps.height}
      $editable={editable}
      onMouseEnter={() => setOverAddress(props.cell.cellAddress)}
      onMouseLeave={setOverFalse}
      onWheel={setOverFalse}
      onScroll={setOverFalse}
    >
      {overAddress === props.cell.cellAddress ? (
        <Measure
          bounds
          onResize={(contentRect) => {
            setX(contentRect.bounds?.left || 0);
            setY(contentRect.bounds?.top || 0);
          }}
        >
          {({ measureRef }) => {
            return (
              <AnchorBox ref={measureRef}>
                <ExcelCellEditOverlayFloat {...overlayProps} />
              </AnchorBox>
            );
          }}
        </Measure>
      ) : undefined}
      {editable ? <EditableMark /> : undefined}
    </Overlay>
  );
};

const ExcelCellEditOverlayWithContext: React.FC<ExcelCellEditOverlayProps> = (props) => {
  return (
    <ExcelCellEditOverlayProvider>
      <ExcelCellEditOverlay {...props} />
    </ExcelCellEditOverlayProvider>
  );
};

export default ExcelCellEditOverlayWithContext;
