import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CircularProgress from '@material-ui/core/CircularProgress';
import { grey } from '@material-ui/core/colors';
import { Task, TaskToCreate, NewTaskUserData } from 'xacmn';
import styles from './TaskCard.module.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    taskDesc: {
      cursor: 'text',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    descEditor: {
      fontSize: '0.875rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.54)',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      outline: 'none',
      resize: 'none',
      width: '100%',
    },
    progress: {
      color: grey[500],
    },
  }),
);

interface TaskCardDescProps {
  task: Task | undefined | TaskToCreate;
  saveTaskField: (newTask: Partial<NewTaskUserData>) => Promise<void>;
}

const TaskCardDesc: React.FC<TaskCardDescProps> = ({ task, saveTaskField }) => {
  const classes = useStyles();
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [progressSize, setProgressSize] = React.useState(40);
  const taRef = React.useRef<HTMLTextAreaElement>(null);
  const gone = React.useRef(false);

  React.useEffect(() => {
    return () => {
      gone.current = true;
    };
  }, []);

  const saveDesc = (_newDesc: Task['taskDesc']) => {
    setEditing(false);
    const newDesc = _newDesc?.trim();
    if (newDesc !== task?.taskDesc) {
      if (taRef.current) {
        setProgressSize(taRef.current.clientHeight * 0.6);
      }
      setSaving(true);
      saveTaskField({ taskDesc: newDesc }).finally(() => {
        if (gone.current) return;
        setSaving(false);
      });
    }
  };

  return editing || saving ? (
    <div className={styles.editorContainer}>
      <TextareaAutosize
        className={classes.descEditor}
        ref={taRef}
        aria-label="task description"
        placeholder="description"
        defaultValue={task?.taskDesc || ''}
        onBlur={(e) => saveDesc(e.target.value as string)}
      />
      {saving ? (
        <div className={styles.editorProgressContainer}>
          <CircularProgress className={classes.progress} size={progressSize} />
        </div>
      ) : null}
    </div>
  ) : (
    <Typography
      className={classes.taskDesc}
      variant="body2"
      color="textSecondary"
      component="pre"
      gutterBottom
      onClick={() => {
        setEditing(true);
        setTimeout(() => {
          if (taRef?.current) {
            taRef.current.focus();
            taRef.current.select();
          }
        }, 1);
      }}
    >
      {task?.taskDesc || ' '}
    </Typography>
  );
};

export default TaskCardDesc;
