import * as React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import useFileUpload from './useFileUpload';

const FileUploadInput = styled.input`
  display: none;
`;

const TplListBtnBar: React.FC = () => {
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>();
  const { handleAdd } = useFileUpload(selectedFile);

  React.useEffect(() => {
    handleAdd();
  }, [handleAdd]);

  return (
    <>
      <FileUploadInput
        accept=".xlsx,.xlsm"
        id="contained-button-file"
        type="file"
        onChange={(e) => setSelectedFile(e.target.files?.[0])}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          Add Template
        </Button>
      </label>
    </>
  );
};

export default TplListBtnBar;
