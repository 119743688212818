import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './Calculating.module.css';
import { getTimesText } from './excelUtil';

interface CalculatingProp {
  calculating: boolean | null | undefined;
  failedCounter?: number;
}

const Calculating: React.FC<CalculatingProp> = ({ calculating, failedCounter }) => {
  if (!failedCounter) failedCounter = 0;
  return (
    <div className={styles['loadingio-spinner-bars-536qw5pfiys']}>
      {calculating === true ? (
        <>
          <div className={styles['ldio-a46a25ohh76']}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div>
            <Typography color="textSecondary">Running Excel formulas</Typography>
          </div>
        </>
      ) : calculating === false ? (
        failedCounter > 0 ? (
          <div className={styles['updated']}>
            <Typography color="textSecondary">{`Excel formulas failed ${getTimesText(
              failedCounter,
            )}`}</Typography>
          </div>
        ) : (
          <div className={styles['updated']}>
            <Typography color="textSecondary">Excel formulas updated</Typography>
          </div>
        )
      ) : null}
    </div>
  );
};

export default Calculating;
