import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TenantUser, UserInfo } from 'xacmn';
import { getErrorMessageOfResponse } from 'xacmn';
import { notify } from '../general/generalSlice';
import { AppDispatch, State } from '../../app/configureStore';
import { wait, finishWaiting } from '../general/generalSlice';
import { apiUrl } from '../../config';

export type UsersState = {
  usersList: UserInfo[] | null;
  currentUser?: UserInfo;
};

const initialState: UsersState = { usersList: null };

export const getUsersList = createAsyncThunk<
  UserInfo[],
  void,
  { state: State; dispatch: AppDispatch }
>('users/getUsersList', async (arg, thunkAPI) => {
  const server = apiUrl;
  const path = '/admin';

  const token = sessionStorage.getItem('id_token');
  const res = await fetch(`${server}${path}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'listUsers',
    }),
  });
  if (res.status !== 200) {
    const errMsg = await getErrorMessageOfResponse(res);
    thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
    return [];
  }
  const users = await res.json();
  thunkAPI.dispatch(
    notify({ variant: 'success', message: `${users.length} users retrieved successfully` }),
  );
  return users as UserInfo[];
});

export const createUser = createAsyncThunk<
  UserInfo | undefined,
  TenantUser,
  { state: State; dispatch: AppDispatch }
>('users/createUser', async (userToCreate: TenantUser, thunkAPI) => {
  thunkAPI.dispatch(wait());
  try {
    const server = apiUrl;
    const path = '/admin';

    const token = sessionStorage.getItem('id_token');
    const res = await fetch(`${server}${path}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'createUser',
        data: userToCreate,
      }),
    });
    if (res.status !== 200) {
      const errMsg = await getErrorMessageOfResponse(res);
      thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
      throw new Error(`User creation failed - ${errMsg}`);
    }
    const user = await res.json();
    thunkAPI.dispatch(
      notify({ variant: 'success', message: `User ${user.name} created successfully` }),
    );
    return user as UserInfo;
  } finally {
    thunkAPI.dispatch(finishWaiting());
  }
});

export const disableUser = createAsyncThunk<
  UserInfo | undefined,
  string,
  { state: State; dispatch: AppDispatch }
>('users/disableUser', async (userToDisable: string, thunkAPI) => {
  const server = apiUrl;
  const path = '/admin';

  const token = sessionStorage.getItem('id_token');
  const res = await fetch(`${server}${path}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'disableUser',
      data: userToDisable,
    }),
  });
  if (res.status !== 200) {
    const errMsg = await getErrorMessageOfResponse(res);
    thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
    throw new Error(`User disable failed - ${errMsg}`);
  }
  const user = await res.json();
  thunkAPI.dispatch(
    notify({ variant: 'success', message: `User ${user.name} disabled successfully` }),
  );
  return user as UserInfo;
});

export const getUser = createAsyncThunk<
  UserInfo | undefined,
  { userName: string; toNotify: boolean },
  { state: State; dispatch: AppDispatch }
>('users/getUser', async ({ userName, toNotify }, thunkAPI) => {
  const server = apiUrl;
  const path = '/admin';

  const token = sessionStorage.getItem('id_token');
  const res = await fetch(`${server}${path}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'getUser',
      data: userName,
    }),
  });
  if (res.status !== 200) {
    const errMsg = await getErrorMessageOfResponse(res);
    if (toNotify) {
      thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
    }
    throw new Error(`User retrieve failed - ${errMsg}`);
  }
  const user = await res.json();
  if (toNotify) {
    thunkAPI.dispatch(
      notify({ variant: 'success', message: `User ${user.name} retrieved successfully` }),
    );
  }
  return user as UserInfo;
});

export const updateUser = createAsyncThunk<
  UserInfo,
  TenantUser,
  { state: State; dispatch: AppDispatch }
>('users/updateUser', async (userToUpdate: TenantUser, thunkAPI) => {
  thunkAPI.dispatch(wait());
  try {
    const server = apiUrl;
    const path = '/admin';

    const token = sessionStorage.getItem('id_token');
    const res = await fetch(`${server}${path}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'updateUser',
        data: userToUpdate,
      }),
    });
    if (res.status !== 200) {
      const errMsg = await getErrorMessageOfResponse(res);
      thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
      throw new Error(`User update failed - ${errMsg}`);
    }
    const user = await res.json();
    thunkAPI.dispatch(
      notify({ variant: 'success', message: `User ${userToUpdate.name} updated successfully` }),
    );
    return user;
  } finally {
    thunkAPI.dispatch(finishWaiting());
  }
});

export const UsersSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      state.usersList = action.payload;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(disableUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
  },
});

export const selectUsersList = () => (state: State) => {
  return state.users.usersList;
};

export const selectCurrentUser = () => (state: State) => {
  return state.users.currentUser;
};

export default UsersSlice.reducer;
