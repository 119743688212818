import { UserInfo, Validity } from '../types';

/* eslint-disable no-empty */
export async function getErrorMessageOfResponse(
  res: Response,
): Promise<string> {
  let msg: string | undefined = undefined;
  try {
    msg = await res.text();
  } catch (e) {}
  if (!msg) {
    try {
      msg = res.body?.toString();
    } catch (e) {}
  }
  if (!msg) {
    try {
      msg = await res.json();
      if (msg && typeof msg === 'object') {
        msg = JSON.stringify(msg);
      }
    } catch (e) {}
  }
  if (!msg) {
    try {
      msg = res.statusText;
    } catch (e) {}
  }
  if (!msg) {
    msg = 'unknown error';
  }
  return `${res.status} - ${msg}`;
}

export function getErrorMessageOfError(err: unknown): string {
  let msg: string | undefined = undefined;
  try {
    msg = (err as Error).message;
  } catch (e) {}
  if (!msg) {
    try {
      if (err && typeof err === 'object') {
        msg = JSON.stringify(err);
      }
    } catch (e) {}
  }
  if (!msg) {
    try {
      msg = err as string;
    } catch (e) {}
  }
  if (!msg) {
    msg = 'unknown error';
  }
  return msg;
}

export function isAdminUser(userInfo: UserInfo): boolean {
  return userInfo.role === 'Administrator';
}

const EMAIL_CHECK_RE =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,100}@[a-zA-Z0-9-]{2,100}(?:\.[a-zA-Z0-9-]{2,5})*$/;

export async function validateEmail(
  apiUrl: string,
  emailParam: string,
  setIsGood: (validity: Validity) => void,
  setEmail: (email: string) => void,
): Promise<void> {
  const _email = emailParam.trim();

  // basic checks
  if (_email.length <= 2) {
    setIsGood(Validity.NA);
    return;
  }
  if (!EMAIL_CHECK_RE.test(_email)) {
    setIsGood(Validity.No);
    return;
  }

  setIsGood(Validity.WIP);
  return await fetch(`${apiUrl}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify({
      action: 'verifyEmail',
      payload: _email,
    }),
  })
    .then((res) => res.json())
    .then((r) => {
      // https://emailverification.whoisxmlapi.com/api/documentation/making-requests
      const good =
        r.formatCheck === 'true' &&
        // r.freeCheck === 'false' &&
        // r.smtpCheck === 'true' &&
        // r.catchAllCheck === 'false' &&
        // r.disposableCheck === 'false';
        r.dnsCheck === 'true';
      if (good) {
        setIsGood(Validity.Yes);
        setEmail(r.emailAddress);
      } else {
        setIsGood(Validity.No);
      }
    })
    .catch((error) => {
      setIsGood(Validity.NA);
      const msg = getErrorMessageOfError(error);
      console.error(msg);
    });
}
