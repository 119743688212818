import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Excel from 'exceljs';
import {
  CellUICfg,
  DummyFn,
  Loc,
  Cell,
  OnSetSheetLTEventHandler,
  OnSetSheetRBEventHandler,
} from 'xacmn';
import CSS from 'csstype';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { EditableIcon, TopLeftIcon, BottomRightIcon, ReadOnlyIcon } from './Icons';

export interface ExcelCellEditOverlayFloatProps {
  cell: Cell;
  cellUICfg?: CellUICfg | null;
  sheetLoc?: Loc;
  height?: CSS.Property.Height;
  width?: CSS.Property.Width;
  x?: number;
  y?: number;
  onToggleCellEditable: DummyFn;
  onSetSheetLT: OnSetSheetLTEventHandler;
  onSetSheetRB: OnSetSheetRBEventHandler;
}

const OverlayFloat = styled.div<{
  $width?: CSS.Property.Width;
  $height?: CSS.Property.Height;
  $x?: number;
  $y?: number;
}>`
  box-sizing: border-box;
  position: fixed;
  z-index: 2000;
  pointer-events: none;
  left: ${(props) => `${props.$x}px`};
  top: ${(props) => `${props.$y}px`};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-width: 2px;
  border-style: solid;
  border-color: forestgreen;
`;

const OverlayTools = styled.div`
  position: absolute;
  box-sizing: content-box;
  pointer-events: auto;
  min-width: ${3 * 30}px;
  bottom: 100%;
  left: -2px;
  border-width: 2px;
  border-style: solid;
  border-color: black;
  background-color: rgba(200, 256, 200, 0.8);
`;

const TELEPORTED_TO = document.getElementById('root');

const EDITABLE_MIN_SIZE = 8;

function tooSmall(w?: CSS.Property.Width, h?: CSS.Property.Height): boolean {
  if (typeof w === 'string' && typeof h === 'string') {
    if (w.endsWith('px') && h.endsWith('px')) {
      const width = parseInt(w.slice(0, -2));
      const height = parseInt(h.slice(0, -2));
      return width <= EDITABLE_MIN_SIZE || height <= EDITABLE_MIN_SIZE;
    }
  }
  return false;
}

const ExcelCellEditOverlayFloat: React.FC<ExcelCellEditOverlayFloatProps> = ({
  cell,
  cellUICfg,
  sheetLoc,
  height,
  width,
  x,
  y,
  onToggleCellEditable,
  onSetSheetLT,
  onSetSheetRB,
}) => {
  if (!TELEPORTED_TO) return null;
  if (tooSmall(width, height)) return null;

  const editable = !!cellUICfg?.editable;
  const currRow = cell.fullAddress?.row;
  const currCol = cell.fullAddress?.col;
  if (typeof currRow === 'string' || typeof currCol === 'string') return null;
  const displayLT =
    (currRow || 0) <= (sheetLoc?.bottom || 0) && (currCol || 0) <= (sheetLoc?.right || 0);
  const displayRB =
    (currRow || 0) >= (sheetLoc?.top || 0) && (currCol || 0) >= (sheetLoc?.left || 0);
  const displayEdit =
    cell.type !== Excel.ValueType.Formula &&
    (currRow || 0) >= (sheetLoc?.top || 0) &&
    (currRow || 0) <= (sheetLoc?.bottom || 0) &&
    (currCol || 0) >= (sheetLoc?.left || 0) &&
    (currCol || 0) <= (sheetLoc?.right || 0);

  const cpnt = (
    <OverlayFloat $width={width} $height={height} $x={x} $y={y}>
      <OverlayTools>
        <IconButton onClick={onToggleCellEditable} size="small" disabled={!displayEdit}>
          {editable ? <ReadOnlyIcon /> : <EditableIcon $disabled={!displayEdit} />}
        </IconButton>
        <IconButton
          onClick={() => {
            const sheetName = cell.fullAddress?.sheetName;
            const left = currCol || 0;
            const top = currRow || 0;
            if (
              typeof sheetName === 'string' &&
              typeof left === 'number' &&
              typeof top === 'number'
            ) {
              onSetSheetLT({ sheetName, left, top });
            }
          }}
          size="small"
          disabled={!displayLT}
        >
          <TopLeftIcon $disabled={!displayLT} />
        </IconButton>
        <IconButton
          onClick={() => {
            const sheetName = cell.fullAddress?.sheetName;
            const right = currCol || 0;
            const bottom = currRow || 0;
            if (typeof sheetName === 'string') {
              onSetSheetRB({ sheetName, bottom, right });
            }
          }}
          size="small"
          disabled={!displayRB}
        >
          <BottomRightIcon $disabled={!displayRB} />
        </IconButton>
      </OverlayTools>
    </OverlayFloat>
  );

  return ReactDOM.createPortal(cpnt, TELEPORTED_TO);
};

export default ExcelCellEditOverlayFloat;
