import React from 'react';
import { Tmpl } from 'xacmn';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Placeholder from '../general/Placeholder';

interface TmplsProps {
  tmpls: Tmpl[] | null | undefined;
  currentTmpl?: Tmpl;
  setTmpl: (tmpl: Tmpl) => void;
}

const Tmpls: React.FC<TmplsProps> = ({ tmpls, currentTmpl, setTmpl }) => {
  const createListItem = React.useCallback(
    (tmpl: Tmpl) => {
      return (
        <ListItem
          button
          selected={tmpl.tmplId === currentTmpl?.tmplId}
          onClick={(event) => setTmpl(tmpl)}
          key={tmpl.tmplId}
        >
          <ListItemText primary={tmpl.tmplName} />
        </ListItem>
      );
    },
    [currentTmpl?.tmplId, setTmpl],
  );

  let cpnt;

  if (!tmpls) {
    cpnt = <Placeholder info="Loading..." />;
  } else if (tmpls.length === 0) {
    cpnt = <Placeholder info="Create a template first" />;
  } else {
    cpnt = (
      <List component="nav" dense disablePadding>
        {tmpls.map(createListItem)}
      </List>
    );
  }

  return cpnt;
};

export default Tmpls;
