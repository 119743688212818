import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import TemplateFrame from '../features/template/TplFrame';
import WorkflowFrame from '../features/workflow/WorkflowRoutes';
import UsersFrame from '../features/users/UsersFrame';

const FeatureRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="template/*" element={<TemplateFrame />} />
      <Route path="users/*" element={<UsersFrame />} />
      <Route path="workflow/*" element={<WorkflowFrame />} />
      <Route path="*" element={<WorkflowFrame />} />
    </Routes>
  );
};

export default FeatureRoutes;
