import * as React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useAppDispatch } from '../../app/configureStore';
import CSS from 'csstype';
import { CellUICfg, Cell, OnSetSheetLTArgs, OnSetSheetRBArgs } from 'xacmn';
import ExcelCellEditOverlay from './ExcelCellEditOverlay';
import { selectCellUICfg, toggleCellEditable, selectLoc, setSheetLoc } from './excelUICfgSlice';

export interface ExcelCellEditOverlayCtrProps {
  cell: Cell;
  height?: CSS.Property.Height;
  width?: CSS.Property.Width;
}

const ExcelCellEditOverlayCtr: React.FC<ExcelCellEditOverlayCtrProps> = ({
  cell,
  height,
  width,
}) => {
  const cellUICfg: CellUICfg | undefined = useSelector(selectCellUICfg(cell.cellAddress));
  const loc = useSelector(selectLoc(cell.fullAddress?.sheetName || ''));
  const dispatch = useAppDispatch();

  function onToggleCellEditable(): void {
    dispatch(toggleCellEditable({ cellAddress: cell.cellAddress }));
  }

  function onSetSheetLT(args: OnSetSheetLTArgs): void {
    dispatch(setSheetLoc({ ...loc, ...args }));
  }

  function onSetSheetRB(args: OnSetSheetRBArgs): void {
    dispatch(setSheetLoc({ ...loc, ...args }));
  }

  return (
    <ExcelCellEditOverlay
      cell={cell}
      cellUICfg={cellUICfg}
      sheetLoc={loc}
      height={height}
      width={width}
      onToggleCellEditable={onToggleCellEditable}
      onSetSheetLT={onSetSheetLT}
      onSetSheetRB={onSetSheetRB}
    />
  );
};
export default ExcelCellEditOverlayCtr;
