import * as React from 'react';
import { Toolbar, List, ListItem, ListItemText, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { getLoggedInUserInfo } from '../utils';

const useStyles = makeStyles({
  navbarDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  linkText: {
    textDecoration: `none`,
    textTransform: `uppercase`,
    color: `white`,
  },
});

const NavMenu = () => {
  const classes = useStyles();

  // TODO: wrap router around Frame first in order to get account
  const location = useLocation();
  // TODO: do not read account from URL?
  const account = location.pathname.split('/')[1];

  const loggedInUserInfo = getLoggedInUserInfo();

  const navLinks =
    loggedInUserInfo?.['custom:role'] === 'Administrator'
      ? [
          { title: 'Tasks', path: `/${account}/workflow` },
          { title: 'Templates', path: `/${account}/template` },
          { title: 'Users', path: `/${account}/users` },
        ]
      : [{ title: 'Tasks', path: `/${account}/workflow` }];

  return (
    <Toolbar>
      <Container maxWidth="md" className={classes.navbarDisplayFlex}>
        <List component="nav" aria-labelledby="main navigation" className={classes.navDisplayFlex}>
          {navLinks.map(({ title, path }) => (
            <a href={path} key={title} className={classes.linkText}>
              <ListItem button>
                <ListItemText primary={title} />
              </ListItem>
            </a>
          ))}
        </List>
      </Container>
    </Toolbar>
  );
};
export default NavMenu;
