import { LambdaAuthorizedEventV2, UserInfo } from '../types';

export const getUserInfo = (event: LambdaAuthorizedEventV2): UserInfo => {
  const info = event.requestContext.authorizer?.lambda;
  if (!info) {
    throw new Error('user information not available');
  }
  return info as unknown as UserInfo;
};

export const getTenantName = (event: LambdaAuthorizedEventV2): string => {
  return getUserInfo(event).tenantName;
};
