import * as React from 'react';
import * as Excel from 'exceljs';
import { Cell } from 'xacmn';

interface ExcelCellEditDateProps {
  excelMtdt: Cell;
  className: string;
  val?: Excel.CellValue;
  onBlur?: (value: unknown) => void;
  onFocus?: () => void;
}

const ExcelCellEditDate: React.FC<ExcelCellEditDateProps> = ({
  excelMtdt,
  className,
  val,
  onBlur,
  onFocus,
}) => {
  const _onBlur =
    onBlur &&
    ((e: React.FocusEvent<HTMLInputElement, Element>) => {
      const value = e.target.value;
      // valid date string
      if (value.length > 0) {
        onBlur(e.target.value);
      }
    });
  return (
    <input
      type="date"
      className={className}
      value={(val as string).slice(0, 10)}
      onChange={_onBlur}
      onBlur={_onBlur}
      onFocus={onFocus}
    />
  );
};

export default ExcelCellEditDate;
