import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Tmpl, Task, getErrorMessageOfResponse } from 'xacmn';
import { State } from '../../app/configureStore';
import { generateShortCode } from '../../utils';
import { notify } from '../general/generalSlice';
import { apiUrl } from '../../config';

export interface WorkflowState {
  tmpls: Tmpl[] | null;
  tasks: Task[] | null;
  currentTmpl?: Tmpl;
}

const initialState: WorkflowState = {
  tmpls: null,
  tasks: null,
};

export const getTmpls = createAsyncThunk('workflow/getTmpls', async (arg, thunkAPI) => {
  const server = apiUrl;
  const path = '/admin';

  const token = sessionStorage.getItem('id_token');
  const res = await fetch(`${server}${path}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'getTmpls',
    }),
  });
  if (res.status !== 200) {
    if (res.status === 404) {
      thunkAPI.dispatch(
        notify({ variant: 'info', message: 'You have not created any template yet.' }),
      );
    } else {
      const errMsg = await getErrorMessageOfResponse(res);
      thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
    }
    return [];
  }
  const tmpls = (await res.json()) as Tmpl[];

  return tmpls;
});

export const getMyTasks = createAsyncThunk('workflow/getMyTasks', async (arg, thunkAPI) => {
  const server = apiUrl;
  const path = '/task';

  const token = sessionStorage.getItem('id_token');
  const res = await fetch(`${server}${path}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'getTasksByUser',
    }),
  });
  if (res.status !== 200) {
    if (res.status === 404) {
      thunkAPI.dispatch(notify({ variant: 'info', message: 'You have not created any task yet.' }));
    } else {
      const errMsg = await getErrorMessageOfResponse(res);
      thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
    }
    return [];
  }
  const tasks = (await res.json()) as Task[];
  for (const task of tasks) {
    const shortCodes = await generateShortCode(task.transId);
    task.shortTransId = shortCodes[0];
    task.transColorCode = shortCodes[1];
  }
  return tasks;
});

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setCurrentTmpl: (state, action: PayloadAction<Tmpl>) => {
      const tmpl = action.payload;
      state.currentTmpl = tmpl;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTmpls.fulfilled, (state, action) => {
      state.tmpls = action.payload;
    });
    builder.addCase(getMyTasks.fulfilled, (state, action) => {
      state.tasks = action.payload;
    });
  },
});

export const { setCurrentTmpl } = workflowSlice.actions;
export default workflowSlice.reducer;

export const selectCurrentTmpl = () => (state: State) => {
  return state.workflow.currentTmpl;
};

export const selectTmpls = () => (state: State) => {
  return state.workflow.tmpls?.filter(
    (tmpl) => tmpl.tmplStatus === 'published' || !tmpl.tmplStatus,
  );
};

export const selectMyTasks = () => (state: State) => {
  return state.workflow.tasks;
};
