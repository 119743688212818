import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import excelReducer from '../features/excel/excelSlice';
import excelUICfgReducer from '../features/excel/excelUICfgSlice';
import workflowReducer from '../features/workflow/workflowSlice';
import tplReducer from '../features/template/tplSlice';
import taskReducer from '../features/task/taskSlice';
import generalReducer from '../features/general/generalSlice';
import wsmsgReducer from '../features/wsmsg/wsmsgSlice';
import usersReducer from '../features/users/usersSlice';

const reducer = {
  excel: excelReducer,
  excelUICfg: excelUICfgReducer,
  workflow: workflowReducer,
  tpl: tplReducer,
  task: taskReducer,
  general: generalReducer,
  wsmsg: wsmsgReducer,
  users: usersReducer,
};

export default function setUpStore() {
  const store = configureStore({
    reducer,
  });

  return store;
}

export type State = ReturnType<ReturnType<typeof setUpStore>['getState']>;
export type AppDispatch = ReturnType<typeof setUpStore>['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, unknown, Action<string>>;
