import React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import classNames from 'classnames';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import CloseIcon from '@material-ui/icons/Close';
import { NotificationType, NotificationVariant } from 'xacmn';
import styles from './Notification.module.css';
import { SvgIcon } from '@material-ui/core';
import { selectNotification } from './generalSlice';

const variantIcon: Record<NotificationVariant, typeof SvgIcon> = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const DURATION = 10000;

const Notification: React.FC = () => {
  const classes = useStyles();
  const notification: NotificationType | undefined = useSelector(selectNotification());
  const [open, setOpen] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(timer); // Clear the interval when progress reaches 100
            return 0;
          } else {
            return prevProgress + 1;
          }
        });
      }, (notification?.duration || DURATION) / 100); // Divide by 100 to increment 1% every interval
      return () => {
        clearInterval(timer);
      };
    }
    return () => {}; // Add a default return statement
  }, [open, notification?.duration]);

  // TODO: multiple notification support
  React.useEffect(() => {
    if (notification) {
      setOpen(true);
      setProgress(0);
    }
  }, [notification]);

  const handleClose = React.useCallback(
    (event: React.SyntheticEvent, reason: SnackbarCloseReason): void => {
      setOpen(false);
    },
    [],
  );

  const variant = notification?.variant || 'info';
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      className={styles.Snackbar}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={notification?.duration || DURATION}
      onClose={handleClose}
    >
      <div style={{ width: '100%' }}>
        <SnackbarContent
          className={classNames(classes[variant], styles.SnackbarContent)}
          message={
            <span className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              {notification?.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={styles.close}
              onClick={(evt) => {
                handleClose(evt, 'clickaway');
              }}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
        <LinearProgress
          classes={{
            colorPrimary: styles['MuiLinearProgress-colorPrimary'],
            barColorPrimary: styles['MuiLinearProgress-barColorPrimary'],
          }}
          variant="determinate"
          value={100 - progress}
        />
      </div>
    </Snackbar>
  );
};

export default Notification;
