import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import { NewTaskUserData, Task, TaskToCreate } from 'xacmn';
import { useAppDispatch } from '../../app/configureStore';
import { saveTask } from '../task/taskSlice';
import { notify } from '../general/generalSlice';
import TaskCardPriority from './TaskCardPriority';
import TaskCardDueAt from './TaskCardDueAt';
import TaskCardAssignee from './TaskCardAssignee';
import TaskCardCompleted from './TaskCardCompleted';
import TaskCardMenu from './TaskCardMenu';
import TaskCardTitle from './TaskCardTitle';
import TaskCardDesc from './TaskCardDesc';
import TaskCardType from './TaskCardType';
import { isExistingTask } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    markerLine: {
      minHeight: theme.spacing(3),
    },
  }),
);

interface TaskCardProps {
  task: Task | undefined | TaskToCreate;
  assignedName: string;
  setNewTaskProp?: (newTask: Partial<NewTaskUserData>) => void;
}

const TaskCard: React.FC<TaskCardProps> = ({ task, assignedName, setNewTaskProp }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const _isExistingTask = isExistingTask(task);

  const saveTaskField = React.useCallback(
    async (newTask: Partial<NewTaskUserData>) => {
      if (!_isExistingTask) {
        if (setNewTaskProp) {
          setNewTaskProp(newTask);
        }
        return;
      }
      return dispatch(saveTask({ ...task, ...newTask })).then((fulfilledAction) => {
        if (fulfilledAction?.payload) {
          dispatch(
            notify({
              variant: 'success',
              message: 'Task has been successfully saved',
            }),
          );
        }
      });
    },
    [_isExistingTask, dispatch, setNewTaskProp, task],
  );

  const handleToggle = React.useCallback(
    (setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>) => () => {
      setOpenFlag((prevOpen) => !prevOpen);
    },
    [],
  );

  const handleClose = React.useCallback(
    (
        setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>,
        ref: React.RefObject<HTMLElement>,
      ) =>
      (event: React.MouseEvent<EventTarget>) => {
        if (ref.current && ref.current.contains(event.target as HTMLElement)) {
          return;
        }

        setOpenFlag(false);
      },
    [],
  );

  const handleListKeyDown = React.useCallback(
    (setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>) => (event: React.KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpenFlag(false);
      }
    },
    [],
  );

  return (
    <Card raised={false}>
      <CardContent>
        <Box mb={1} className={classes.markerLine}>
          <TaskCardPriority
            priority={task?.priority}
            readonly={!task}
            handleListKeyDown={handleListKeyDown}
            handleToggle={handleToggle}
            handleClose={handleClose}
            saveTaskField={saveTaskField}
          />
          {_isExistingTask && !task.aborted && !task.completed && !task.reassignedAt ? (
            <TaskCardMenu
              task={task}
              handleListKeyDown={handleListKeyDown}
              handleToggle={handleToggle}
              handleClose={handleClose}
            />
          ) : null}
          <TaskCardType
            task={task}
            readonly={!task}
            handleListKeyDown={handleListKeyDown}
            handleToggle={handleToggle}
            handleClose={handleClose}
            saveTaskField={saveTaskField}
          />
        </Box>
        <TaskCardTitle task={task} saveTaskField={saveTaskField} />
        <TaskCardDesc task={task} saveTaskField={saveTaskField} />
        <Box mt={1} className={classes.markerLine}>
          <TaskCardDueAt dueAt={task?.dueAt} readonly={!task} saveTaskField={saveTaskField} />
          {_isExistingTask ? <TaskCardCompleted task={task} /> : null}
          <TaskCardAssignee
            task={task}
            assignedName={assignedName}
            handleListKeyDown={handleListKeyDown}
            handleToggle={handleToggle}
            handleClose={handleClose}
            saveTaskField={saveTaskField}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TaskCard;
