import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dashboard from './Dashboard';
import WorkflowBtnBar from './WorkflowBtnBar';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentLeft: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    contentMain: {
      flex: 1,
      display: 'flex',
      overflow: 'auto',
    },
    buttonBar: {
      padding: '14px',
      flex: '0 0 64px',
      borderTop: `2px solid ${grey[200]}`,
      background: grey[50],
    },
  }),
);

const TaskListFrame: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.contentLeft}>
        <div className={classes.contentMain}>
          <Dashboard />
        </div>
        <Paper className={classes.buttonBar} square elevation={0}>
          <WorkflowBtnBar />
        </Paper>
      </div>
    </>
  );
};

export default TaskListFrame;
