import * as React from 'react';
import { useParams } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppDispatch } from '../../app/configureStore';
import { disableUser, getUser } from './usersSlice';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PublishIcon from '@material-ui/icons/Publish';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutline from '@material-ui/icons/CheckCircle';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Email from './Email';
import Placeholder from '../general/Placeholder';
import { validateEmail as validateEmail_, TenantUser, UserInfo, roles, Validity } from 'xacmn';
import { createUser, updateUser } from './usersSlice';
import { useNavigate } from 'react-router-dom';
import { getLoggedInUserInfo } from '../../utils';
import { Typography } from '@material-ui/core';
import { apiUrl } from '../../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    formControl: {
      minWidth: 120,
    },
    goodIcon: {
      marginTop: theme.spacing(3),
      color: 'green',
    },
    badIcon: {
      marginTop: theme.spacing(3),
      color: 'red',
    },
    circularProgress: {
      marginTop: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

const USERNAME_CHECK_RE = /^[a-zA-Z][._\-0-9a-zA-Z]{3,15}$/;

interface UserProps {
  editingProfile?: boolean;
}

const User: React.FC<UserProps> = ({ editingProfile }) => {
  const { userName: userNameParam } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(true);

  const [userName, setUserName] = React.useState(userNameParam || '');
  const [fullName, setFullName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [enabled, setEnabled] = React.useState(true);
  const [yourRole, setYourRole] = React.useState<string | undefined>('');

  const [isUserNameGood, setIsUserNameGood] = React.useState<Validity>(Validity.NA);
  const [isEmailGood, setIsEmailGood] = React.useState<Validity>(Validity.NA);

  const classes = useStyles();

  const validateUserName = React.useCallback(
    async (userNameParam: string) => {
      const _userName = userNameParam.trim();

      // basic checks
      if (_userName.length <= 2) {
        setIsUserNameGood(Validity.NA);
        return;
      }
      if (!USERNAME_CHECK_RE.test(_userName)) {
        setIsUserNameGood(Validity.No);
        return;
      }

      setIsUserNameGood(Validity.WIP);
      dispatch(getUser({ userName: _userName, toNotify: false }))
        .unwrap()
        .then((userInfo: UserInfo | undefined) => {
          if (userInfo) {
            setIsUserNameGood(Validity.No);
          } else {
            setIsUserNameGood(Validity.Yes);
          }
        })
        .catch((error) => {
          setIsUserNameGood(Validity.Yes);
        });
    },
    [dispatch],
  );

  const validateEmail = React.useCallback(async (emailParam: string) => {
    await validateEmail_(apiUrl, emailParam, setIsEmailGood, setEmail);
  }, []);

  React.useEffect(() => {
    if (userNameParam) {
      dispatch(getUser({ userName: userNameParam, toNotify: true }))
        .unwrap()
        .then((userInfo: UserInfo | undefined) => {
          if (userInfo) {
            setUserName(userInfo.userName);
            setFullName(userInfo.name);
            setEmail(userInfo.email);
            setYourRole(userInfo.role);
            setEnabled(userInfo.enabled !== false);
            validateEmail(userInfo.email);
            setLoading(false);
          }
          // TODO: handle loading failure
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [dispatch, userNameParam, validateEmail]);

  const goBack = React.useCallback(() => navigate('..'), [navigate]);
  const submit = React.useCallback(
    async (user: TenantUser): Promise<void> => {
      if (userNameParam) {
        dispatch(updateUser(user)).then((fulfilledAction) => {
          if (!fulfilledAction.type.endsWith('/rejected')) {
            navigate(editingProfile ? '../..' : '..');
          }
        });
      } else {
        dispatch(createUser(user)).then((fulfilledAction) => {
          if (!fulfilledAction.type.endsWith('/rejected')) {
            navigate('..');
          }
        });
      }
    },
    [dispatch, editingProfile, navigate, userNameParam],
  );

  const disable = React.useCallback(
    async (userName: string): Promise<void> => {
      if (userNameParam && !editingProfile) {
        dispatch(disableUser(userName)).then((fulfilledAction) => {
          if (!fulfilledAction.type.endsWith('/rejected')) {
            navigate('..');
          }
        });
      }
    },
    [dispatch, editingProfile, navigate, userNameParam],
  );

  const userInfo = getLoggedInUserInfo();
  const iAmAdmin = userInfo?.['custom:role'] === 'Administrator';
  const isSelf = userNameParam === userInfo?.username;
  const isEditing = !!userNameParam;
  if (!iAmAdmin && !editingProfile) {
    return null;
  }
  if (!iAmAdmin && !isSelf) {
    return null;
  }
  if (editingProfile && !isSelf) {
    return null;
  }

  if (isEditing && loading) {
    return <Placeholder info="Loading..." />;
  }

  const isValid = () => {
    return (
      isEmailGood === Validity.Yes &&
      fullName.trim().length >= 2 &&
      (userNameParam || (!userNameParam && isUserNameGood === Validity.Yes))
    );
  };

  return (
    <>
      <form id="xlapp-user-form" className={classes.root}>
        <Grid container spacing={3} style={{ width: '70%' }}>
          {editingProfile ? null : (
            <Grid item xs={11}>
              <Button
                variant="contained"
                form="xlapp-user-form"
                value="Back"
                onClick={(e) => {
                  e.preventDefault();
                  goBack();
                }}
                startIcon={<KeyboardArrowLeftIcon />}
              >
                Back
              </Button>
            </Grid>
          )}
          <Grid item xs={11}>
            <TextField
              required
              fullWidth
              id="user-name"
              label="Login Name"
              value={userName}
              disabled={isEditing || isUserNameGood === Validity.WIP}
              onChange={(e) => {
                setUserName(e.target.value as string);
              }}
              onBlur={() => {
                if (!isEditing) {
                  validateUserName(userName);
                }
              }}
            />
            <Typography variant="caption" color="textSecondary">
              Use 3 to 15 characters, starting with a letter, and including letters, numbers,
              underscores (_), hyphens (-), and periods (.).
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {isUserNameGood === Validity.Yes ? (
              <CheckCircleOutline className={classes.goodIcon} />
            ) : isUserNameGood === Validity.No ? (
              <HighlightOff className={classes.badIcon} />
            ) : isUserNameGood === Validity.WIP ? (
              <CircularProgress size={24} className={classes.circularProgress} />
            ) : null}
          </Grid>
          <Grid item xs={11}>
            <TextField
              required
              fullWidth
              id="full-name"
              label="Full Name"
              value={fullName}
              disabled={!enabled}
              onChange={(e) => setFullName(e.target.value as string)}
            />
          </Grid>
          <Grid item xs={11}>
            <Email
              required
              fullWidth
              id="email"
              label="Email"
              value={email}
              disabled={!enabled || editingProfile || isEmailGood === Validity.WIP}
              onChange={(e) => setEmail(e.target.value as string)}
              onBlur={() => {
                validateEmail(email);
              }}
            />
            <Typography variant="caption" color="textSecondary">
              The user will receive their initial password via this email.
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {isEmailGood === Validity.Yes ? (
              <CheckCircleOutline className={classes.goodIcon} />
            ) : isEmailGood === Validity.No ? (
              <HighlightOff className={classes.badIcon} />
            ) : isEmailGood === Validity.WIP ? (
              <CircularProgress size={24} className={classes.circularProgress} />
            ) : null}
          </Grid>
          <Grid item xs={11}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id="your-role-label">Role</InputLabel>
              <Select
                labelId="your-role-label"
                id="your-role-select"
                value={yourRole}
                onChange={(e) => setYourRole(e.target.value as string)}
                disabled={!enabled || editingProfile || (isSelf && iAmAdmin)}
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="caption" color="textSecondary">
              Only administrators can manage templates, maintain users and download filled 
              Excel files of tasks.
            </Typography>
          </Grid>
          {enabled ? (
            <Grid item xs={11} style={{ display: 'flex' }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                form="xlapp-user-form"
                value="Submit"
                disabled={!isValid()}
                onClick={(e) => {
                  e.preventDefault();
                  const user = {
                    userName,
                    name: fullName,
                    email,
                    role: yourRole,
                  };
                  submit(user);
                }}
                startIcon={<PublishIcon />}
              >
                Submit
              </Button>
              {isEditing && !editingProfile && !isSelf ? (
                <Button
                  style={{ marginLeft: 'auto' }}
                  variant="contained"
                  disabled={!isValid()}
                  onClick={(e) => {
                    e.preventDefault();
                    disable(userName);
                  }}
                  startIcon={<HighlightOffIcon />}
                >
                  Disable
                </Button>
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      </form>
    </>
  );
};
export default User;
