import * as React from 'react';
import { useParams } from 'react-router-dom';
import ExcelSheetsCtr from '../excel/ExcelSheetsCtr';
import Placeholder from '../general/Placeholder';
import { useAppDispatch } from '../../app/configureStore';
import { cleanExcel } from '../excel/excelSlice';
import { Base64 } from 'js-base64';

const TplPreview: React.FC = () => {
  const [cleaned, setCleaned] = React.useState(false);
  const { encodedKey } = useParams();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(cleanExcel());
    setCleaned(true);
  }, [dispatch]);

  if (!encodedKey || !cleaned) {
    return <Placeholder />;
  }
  return <ExcelSheetsCtr fileKey={Base64.decode(encodedKey)} />;
};

export default TplPreview;
