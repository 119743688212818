import * as React from 'react';
import { Mode } from 'xacmn';
import { useAppSelector, useAppDispatch } from '../../app/configureStore';
import { selectShallCalculate, calculate, selectCalculateFailedCounter } from './excelSlice';

interface RunCalculateProps {
  fileKey: string;
  mode?: Mode;
}

const MAX_FAILED_COUNT = 3;

const RunCalculate: React.FC<RunCalculateProps> = ({ fileKey, mode }) => {
  const dispatch = useAppDispatch();
  const shallCalculate = useAppSelector(selectShallCalculate());
  const failedCounter = useAppSelector(selectCalculateFailedCounter());

  React.useEffect(() => {
    if (shallCalculate && mode === 'dataEntry') {
      // TODO: identify those actually used in formula
      if (failedCounter > MAX_FAILED_COUNT) {
        return;
      }
      dispatch(calculate(fileKey));
    }
  }, [dispatch, failedCounter, fileKey, mode, shallCalculate]);

  return null;
};

export default RunCalculate;
