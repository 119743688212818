import * as React from 'react';
import * as Excel from 'exceljs';
import { Cell } from 'xacmn';

interface ExcelCellEditRichTextProps {
  excelMtdt: Cell;
  className: string;
  val?: Excel.CellValue;
  onBlur?: (value: unknown) => void;
  onFocus?: () => void;
}

const ExcelCellEditRichText: React.FC<ExcelCellEditRichTextProps> = ({
  excelMtdt,
  className,
  val,
  onBlur,
  onFocus,
}) => {
  return (
    <textarea
      className={className}
      defaultValue={val as any}
      onBlur={onBlur && ((e) => onBlur(e.target.value))}
      onFocus={onFocus}
    />
  );
};

export default ExcelCellEditRichText;
