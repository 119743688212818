import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/configureStore';
import { uploadTmpl } from './tplSlice';
import { isTrackDone } from '../wsmsg/wsmsgSlice';
import { getErrorMessageOfError, Tmpl } from 'xacmn';
import { notify } from '../general/generalSlice';

export default function useFileUpload(selectedFile: File | undefined, tmplId?: string) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [uplTplRslt, setUplTplRslt] = useState<
    { encodedKey: string; trackId: string; tmpl: Tmpl } | undefined
  >();
  const isUplDone = useAppSelector(isTrackDone(uplTplRslt?.trackId));

  useEffect(() => {
    if (isUplDone && uplTplRslt?.encodedKey) {
      navigate(`uicfg/${uplTplRslt.encodedKey}`, { replace: true });
    }
    // TODO: handle track failure
  }, [navigate, uplTplRslt, isUplDone]);

  const handleAdd = useCallback(async () => {
    if (!selectedFile) return;
    try {
      const r = await dispatch(uploadTmpl({ selectedFile, tmplId })).unwrap();
      if (!r) return;
      setUplTplRslt(r);
    } catch (rejectedValueOrSerializedError) {
      dispatch(
        notify({
          message: getErrorMessageOfError(rejectedValueOrSerializedError),
          variant: 'error',
        }),
      );
    }
  }, [dispatch, selectedFile, tmplId]);

  return {
    handleAdd,
  };
}
