import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import {
  composeFileKey,
  TaskUser,
  TaskReassignParams,
  TaskAssignmentParams,
  TaskToCreate,
  getErrorMessageOfResponse,
  parseFileKey,
  getErrorMessageOfError,
} from 'xacmn';
import { Task, Tmpl, Trans } from 'xacmn';
import { AppDispatch, State } from '../../app/configureStore';
import { selectCellChanged, changeAll } from '../excel/excelSlice';
import { wait, finishWaiting, redirectTo, notify } from '../general/generalSlice';
import { fetchTmpl } from '../template/tplSlice';
import { apiUrl } from '../../config';
import _ from 'lodash';

export interface TaskState {
  tmpl?: Tmpl;
  trans?: Trans;
  task?: Task;
  taskUsers?: TaskUser[];
  taskTypes?: string[];
  transTasks?: Task[];
  displayCompleted?: boolean;
}

const initialState: TaskState = {};

export const getUsersForTask = createAsyncThunk(
  'task/getUsersForTask',
  async (_unused, thunkAPI) => {
    thunkAPI.dispatch(wait());
    try {
      const server = apiUrl;
      const token = sessionStorage.getItem('id_token');

      const taskPath = '/task';

      const taskRes = await fetch(`${server}${taskPath}?`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // TODO: user Bearer
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          action: 'getUsersForTask',
        }),
      });
      let taskUsers: TaskUser[];
      if (taskRes.status === 404) {
        taskUsers = [];
      } else {
        taskUsers = (await taskRes.json()) as TaskUser[];
      }

      return taskUsers;
    } finally {
      thunkAPI.dispatch(finishWaiting());
    }
  },
);

export const getTaskTypes = createAsyncThunk('task/getTaskTypes', async (_unused, thunkAPI) => {
  const server = apiUrl;
  const token = sessionStorage.getItem('id_token');

  const taskPath = '/task';

  const taskRes = await fetch(`${server}${taskPath}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'getTaskTypes',
    }),
  });
  let taskTypes: string[];
  if (taskRes.status === 404) {
    taskTypes = [];
  } else {
    taskTypes = (await taskRes.json()) as string[];
  }

  return taskTypes;
});

export const getTasksByTrans = createAsyncThunk(
  'task/getTasksByTrans',
  async (transId: string, thunkAPI) => {
    const server = apiUrl;
    const token = sessionStorage.getItem('id_token');

    const taskPath = '/task';

    const transTasksRes = await fetch(`${server}${taskPath}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'getTasksByTrans',
        data: transId,
      }),
    });
    const transTasks = (await transTasksRes.json()) as Task[];

    return transTasks;
  },
);
export const getTask = createAsyncThunk('task/getTask', async (assignmentId: string, thunkAPI) => {
  thunkAPI.dispatch(wait());
  try {
    const server = apiUrl;
    const token = sessionStorage.getItem('id_token');

    const taskPath = '/task';

    const taskRes = await fetch(`${server}${taskPath}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'getTaskById',
        data: assignmentId,
      }),
    });
    const task = (await taskRes.json()) as Task;
    const transId = task.transId;

    await thunkAPI.dispatch(getTasksByTrans(transId));

    const path = '/trans';

    const res = await fetch(`${server}${path}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'getTrans',
        data: {
          transId,
        },
      }),
    });
    const trans = (await res.json()) as Trans;

    const tmpl = await fetchTmpl(trans.tmplId);

    thunkAPI.dispatch(changeAll(trans.cellsChanged));

    return {
      task,
      trans,
      tmpl,
    };
  } finally {
    thunkAPI.dispatch(finishWaiting());
  }
});

export const completeTask = createAsyncThunk<
  Task | false,
  TaskAssignmentParams,
  { state: State; dispatch: AppDispatch }
>('task/completeTask', async (param: TaskAssignmentParams, thunkAPI) => {
  thunkAPI.dispatch(wait());
  try {
    const server = apiUrl;
    const token = sessionStorage.getItem('id_token');
    const pathTask = '/task';

    const resTask = await fetch(`${server}${pathTask}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'completeTask',
        data: param,
      }),
    });

    if (resTask.status === 200) {
      if (thunkAPI.getState().task.task?.assignmentId === param.assignmentId) {
        thunkAPI.dispatch(redirectTo('workflow'));
      }
      return (await resTask.json()) as Task;
    }
    return false;
  } catch (e) {
    const err = e as Error;
    console.error(`Failed to complete task - ${err.message}`);
    return false;
  } finally {
    thunkAPI.dispatch(finishWaiting());
  }
});

export const abortTask = createAsyncThunk<
  Task | false,
  TaskAssignmentParams,
  { state: State; dispatch: AppDispatch }
>('task/abortTask', async (param: TaskAssignmentParams, thunkAPI) => {
  thunkAPI.dispatch(wait());
  try {
    const server = apiUrl;
    const token = sessionStorage.getItem('id_token');
    const pathTask = '/task';

    const resTask = await fetch(`${server}${pathTask}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'abortTask',
        data: param,
      }),
    });

    if (resTask.status === 200) {
      if (thunkAPI.getState().task.task?.assignmentId === param.assignmentId) {
        thunkAPI.dispatch(redirectTo('workflow'));
      }
      return (await resTask.json()) as Task;
    }
    return false;
  } catch (e) {
    const err = e as Error;
    console.error(`Failed to delete task - ${err.message}`);
    return false;
  } finally {
    thunkAPI.dispatch(finishWaiting());
  }
});

export const assignTask = createAsyncThunk<
  { param: TaskReassignParams; newTask: Task } | false,
  TaskReassignParams,
  { state: State; dispatch: AppDispatch }
>('task/assignTask', async (param: TaskReassignParams, thunkAPI) => {
  thunkAPI.dispatch(wait());
  try {
    const server = apiUrl;
    const token = sessionStorage.getItem('id_token');
    const pathTask = '/task';

    const resTask = await fetch(`${server}${pathTask}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'assignTask',
        data: param,
      }),
    });

    if (resTask.status === 200) {
      if (thunkAPI.getState().task.task?.assignmentId === param.assignmentId) {
        thunkAPI.dispatch(redirectTo('workflow'));
      }
      const newTask = (await resTask.json()) as Task;
      return {
        param,
        newTask,
      };
    }
    return false;
  } catch (e) {
    const err = e as Error;
    console.error(`Failed to assign task - ${err.message}`);
    return false;
  } finally {
    thunkAPI.dispatch(finishWaiting());
  }
});

export const createTask = createAsyncThunk(
  'task/createTask',
  async (newTask: TaskToCreate, thunkAPI) => {
    const server = apiUrl;
    const pathTask = '/task';
    const token = sessionStorage.getItem('id_token');

    const resTask = await fetch(`${server}${pathTask}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'createTask',
        data: {
          ...newTask,
          title: newTask.title || 'New Task',
          taskType: newTask.taskType || 'misc',
        },
      }),
    });
    const task = (await resTask.json()) as Task;

    return task;
  },
);

// TODO: new Trans type extract
export const createMainTask = createAsyncThunk(
  'task/createMainTask',
  async (newTrans: { tmplId: string; fileId: string }, thunkAPI) => {
    thunkAPI.dispatch(wait());
    try {
      const cellsChanged = selectCellChanged()(thunkAPI.getState() as State);
      const server = apiUrl;
      const path = '/trans';

      const token = sessionStorage.getItem('id_token');
      const res = await fetch(`${server}${path}?`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // TODO: user Bearer
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          action: 'saveTrans',
          data: { ...newTrans, cellsChanged },
        }),
      });
      const trans = (await res.json()) as Trans;

      const transId = trans.transId;
      const pathTask = '/task';

      const resTask = await fetch(`${server}${pathTask}?`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // TODO: user Bearer
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          action: 'createTask',
          data: {
            transId,
            tmplId: newTrans.tmplId,
            fileId: newTrans.fileId,
          },
        }),
      });
      const task = (await resTask.json()) as Task;

      const tmpl = await fetchTmpl(trans.tmplId);

      return {
        trans,
        task,
        tmpl,
      };
    } finally {
      thunkAPI.dispatch(finishWaiting());
    }
  },
);

export const getFilledExcel = createAsyncThunk('task/getFilledExcel', async (__, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as State;
    const fileKey = selectTaskFileKey()(state);
    if (!fileKey) return;
    const wb = state.excel.workbook;
    if (!wb) {
      return;
    }
    const changed = _.mapKeys(state.excel.changed, (v, k) => {
      return "'" + k.slice(wb.name.length + 3);
    });
    const server = apiUrl;
    const path = '/formula';

    const token = sessionStorage.getItem('id_token');
    const res = await fetch(`${server}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'fillExcelFile',
        data: {
          key: fileKey,
          inputs: changed,
        },
      }),
    });
    if (res.status !== 200) {
      const errMsg = `Failed to download the Excel file:  ${await getErrorMessageOfResponse(res)}`;
      throw new Error(errMsg);
    }

    const excelBlob = await res.blob();

    // download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(excelBlob);
    link.download = parseFileKey(fileKey)?.fileName;
    document.body.appendChild(link);
    try {
      link.click();
      thunkAPI.dispatch(
        notify({
          variant: 'success',
          message: `${link.download} downloaded successfully. After saving and opening it, you might need to press CTRL+ALT+F9 to force Excel to refresh all formulas.`,
          duration: 30000,
        }),
      );
    } finally {
      document.body.removeChild(link);
    }
  } catch (err) {
    thunkAPI.dispatch(
      notify({
        variant: 'error',
        message: getErrorMessageOfError(err),
      }),
    );
  }
});

export const saveTask = createAsyncThunk('task/saveTask', async (newTask: Task, thunkAPI) => {
  const server = apiUrl;
  const path = '/task';

  const token = sessionStorage.getItem('id_token');
  const res = await fetch(`${server}${path}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'saveTask',
      data: newTask,
    }),
  });
  const task = (await res.json()) as Task;

  return task;
});

export const saveTrans = createAsyncThunk('task/saveTrans', async (arg, thunkAPI) => {
  thunkAPI.dispatch(wait());
  try {
    const oldTrans = selectTrans()(thunkAPI.getState() as State);
    const cellsChanged = selectCellChanged()(thunkAPI.getState() as State);
    const server = apiUrl;
    const path = '/trans';

    const token = sessionStorage.getItem('id_token');
    const res = await fetch(`${server}${path}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'saveTrans',
        data: { ...oldTrans, cellsChanged },
      }),
    });
    const trans = (await res.json()) as Trans;

    return trans;
  } finally {
    thunkAPI.dispatch(finishWaiting());
  }
});

function resetState(state: WritableDraft<TaskState>): void {
  state.tmpl = undefined;
  state.trans = undefined;
  state.task = undefined;
  state.taskUsers = undefined;
  state.taskTypes = undefined;
  state.transTasks = undefined;
}

const endTaskExtraReducer = (
  state: WritableDraft<TaskState>,
  action: PayloadAction<
    false | Task,
    string,
    {
      arg: TaskAssignmentParams;
      requestId: string;
      requestStatus: 'fulfilled';
    },
    never
  >,
) => {
  const rslt = action.payload;
  if (rslt !== false) {
    if (state.task?.assignmentId === rslt.assignmentId) {
      resetState(state);
    } else {
      if (!state.transTasks || state.transTasks.length === 0) return;
      const taskChangedIdx = state.transTasks.findIndex(
        (transTask) => transTask.assignmentId === rslt.assignmentId,
      );
      if (taskChangedIdx !== -1) {
        state.transTasks[taskChangedIdx] = rslt;
      }
    }
  }
};

export const taskSlice = createSlice({
  name: 'trans',
  initialState,
  reducers: {
    setDisplayCompleted: (state, action: PayloadAction<boolean>) => {
      state.displayCompleted = action.payload;
    },
    reset: resetState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTask.fulfilled, (state, action) => {
      state.trans = action.payload.trans;
      state.tmpl = action.payload.tmpl;
      state.task = action.payload.task;
    });
    builder.addCase(getTasksByTrans.fulfilled, (state, action) => {
      state.transTasks = action.payload;
    });
    builder.addCase(createMainTask.fulfilled, (state, action) => {
      state.trans = action.payload.trans;
      state.tmpl = action.payload.tmpl;
      state.task = action.payload.task;
    });
    builder.addCase(saveTrans.fulfilled, (state, action) => {
      state.trans = action.payload;
    });
    builder.addCase(saveTask.fulfilled, (state, action) => {
      if (state.task?.assignmentId === action.payload.assignmentId) {
        state.task = action.payload;
      } else {
        if (!state.transTasks || state.transTasks.length === 0) return;
        const taskChangedIdx = state.transTasks.findIndex(
          (transTask) => transTask.assignmentId === action.payload.assignmentId,
        );
        if (taskChangedIdx !== -1) {
          state.transTasks[taskChangedIdx] = action.payload;
        }
      }
    });
    builder.addCase(getUsersForTask.fulfilled, (state, action) => {
      state.taskUsers = action.payload;
    });
    builder.addCase(getTaskTypes.fulfilled, (state, action) => {
      state.taskTypes = action.payload;
    });
    builder.addCase(completeTask.fulfilled, endTaskExtraReducer);
    builder.addCase(abortTask.fulfilled, endTaskExtraReducer);
    builder.addCase(assignTask.fulfilled, (state, action) => {
      const rslt = action.payload;
      if (rslt !== false) {
        if (state.task?.assignmentId === rslt.param.assignmentId) {
          resetState(state);
        } else {
          if (!state.transTasks || state.transTasks.length === 0) return;
          const taskChangedIdx = state.transTasks.findIndex(
            (transTask) => transTask.assignmentId === rslt.param.assignmentId,
          );
          if (taskChangedIdx !== -1) {
            state.transTasks[taskChangedIdx] = rslt.newTask;
          }
        }
      }
    });
  },
});

export const { setDisplayCompleted, reset } = taskSlice.actions;
export default taskSlice.reducer;

export const selectFileKey = () => (state: State) => {
  const tenantName = state.task.trans?.tenantName;
  const tmplId = state.task.trans?.tmplId;
  const fileId = state.task.trans?.fileId;
  if (!fileId || !tmplId || !tenantName) return null;
  const fileName = state.task.tmpl?.filesArc[fileId].fileName;
  if (!fileName) return null;
  return composeFileKey({ tenantName, tmplId, fileId, fileName });
};

export const selectTask = () => (state: State) => {
  return state.task.task;
};

export const selectTransTasks = () => (state: State) => {
  return state.task.transTasks
    ?.filter(
      (transTask) =>
        // completed, aborted or reassigned tasks have appended "!" to assignedToSub
        transTask.assignmentId !== state.task.task?.assignmentId &&
        !transTask.reassignedAt &&
        (state.task.displayCompleted || !transTask.completed) &&
        !transTask.aborted,
    )
    .sort((a, b) => {
      const codeFn = (t: Task) => {
        return `${t.assignedToSub === state.task.task?.assignedToSub ? 0 : 1}${
          t.priority === 'high' ? 0 : t.priority === 'medium' ? 1 : t.priority === 'low' ? 2 : 3
        }${
          t.dueAt ? t.dueAt.toString() : new Date('2999-1-1').toString()
        }${t.assignedAt.toString()}`;
      };
      const orderA = codeFn(a);
      const orderB = codeFn(b);
      if (orderA > orderB) {
        return 1;
      } else if (orderA < orderB) {
        return -1;
      } else {
        return 0;
      }
    });
};

export const selectTaskTmpl = () => (state: State) => {
  return state.task.tmpl;
};

export const selectTaskTypes = () => (state: State) => {
  return state.task.taskTypes;
};

export const selectTaskUsers = () => (state: State) => {
  return state.task.taskUsers;
};

export const selectTaskUserNameBySub = (sub?: string) => (state: State) => {
  if (!sub) return undefined;
  return state.task.taskUsers?.find((user) =>
    // a aborted or completed task will have its assignedToSub appended with "!"
    sub.slice(-1) === '!' ? user.sub === sub.slice(0, -1) : user.sub === sub,
  )?.name;
};

export const selectTrans = () => (state: State) => {
  return state.task.trans;
};

export const selectTaskFileKey = () => (state: State) => {
  const fileId = state.task.task?.fileId;
  const tmpl = state.task.tmpl;
  if (!fileId || !tmpl) return null;
  const tenantName = tmpl.tenantName;
  const tmplId = tmpl.tmplId;
  const fileName = tmpl.filesArc[fileId].fileName;
  if (!fileName) return null;
  return composeFileKey({ tenantName, tmplId, fileId, fileName });
};

export const selectDisplayCompleted = () => (state: State) => {
  return !!state.task.displayCompleted;
};
