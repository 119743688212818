import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WsMsgClient, WsMsgInfo, WsMsgUIEl, WsTrackStatus } from 'xacmn';
import _ from 'lodash';
import { AppDispatch, State } from '../../app/configureStore';
import { notify } from '../general/generalSlice';

export type WsMsgState = {
  [trackId: string]: WsMsgInfo[];
};

const initialState: WsMsgState = {};

export interface NewTrackAction extends WsMsgClient {
  message: string;
}

const DONE_LAPSE = 10000; // while done, wait for this time before deleting the track

export const receiveWsMsg = createAsyncThunk<void, string, { state: State; dispatch: AppDispatch }>(
  'wsmsg/receiveWsMsg',
  async (msg: string, thunkAPI) => {
    let wsMsgInfo: WsMsgInfo;
    try {
      wsMsgInfo = JSON.parse(msg);
    } catch (err) {
      console.error(`invalid message received from web socket - ${msg}`);
      return;
    }
    // ignore non-existent trackId from server
    if (!wsMsgInfo.trackId || !thunkAPI.getState().wsmsg[wsMsgInfo.trackId]) return;

    thunkAPI.dispatch(newMessage(wsMsgInfo));
    if (wsMsgInfo.status === WsTrackStatus.failed) {
      thunkAPI.dispatch(notify({ variant: 'error', message: wsMsgInfo.message, duration: 10000 }));
    }
    if (wsMsgInfo.status === WsTrackStatus.done || wsMsgInfo.status === WsTrackStatus.failed) {
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          thunkAPI.dispatch(deleteTrack(wsMsgInfo.trackId));
          resolve();
        }, DONE_LAPSE);
      });
    }
  },
);

export const wsmsgSlice = createSlice({
  name: 'wsmsg',
  initialState,
  reducers: {
    newTrack: (state, action: PayloadAction<NewTrackAction>) => {
      state[action.payload.trackId] = [
        {
          ...action.payload,
          status: WsTrackStatus.created,
          createdAt: new Date().toISOString(),
        },
      ];
    },
    newMessage: (state, action: PayloadAction<WsMsgInfo>) => {
      const wsMsgInfo = action.payload;
      state[wsMsgInfo.trackId].push(wsMsgInfo);
    },
    deleteTrack: (state, action: PayloadAction<string>) => {
      return _.omit(state, action.payload);
    },
  },
});

export const { newTrack, newMessage, deleteTrack } = wsmsgSlice.actions;

export const selectWsStatus = (uiEl: WsMsgUIEl) => (state: State) => {
  const wsMsgInfo = Object.keys(
    _.pickBy(state.wsmsg, (wsMsgInfos: WsMsgInfo[]) => wsMsgInfos[0].uiEl === uiEl),
  ).reduce(
    (prevStatus: WsMsgInfo, trackId: string) => {
      const wsMsgInfos = state.wsmsg[trackId];
      const lastWsMsgInfo = wsMsgInfos[wsMsgInfos.length - 1];
      if (prevStatus.status === WsTrackStatus.done) {
        if (
          lastWsMsgInfo.status === WsTrackStatus.created ||
          lastWsMsgInfo.status === WsTrackStatus.ongoing ||
          lastWsMsgInfo.status === WsTrackStatus.failed
        ) {
          return lastWsMsgInfo;
        }
      } else if (prevStatus.status === WsTrackStatus.failed) {
        if (
          lastWsMsgInfo.status === WsTrackStatus.created ||
          lastWsMsgInfo.status === WsTrackStatus.ongoing
        ) {
          return lastWsMsgInfo;
        }
      } else if (prevStatus.status === WsTrackStatus.created) {
        if (lastWsMsgInfo.status === WsTrackStatus.ongoing) {
          return lastWsMsgInfo;
        } else if (
          lastWsMsgInfo.status === WsTrackStatus.created &&
          lastWsMsgInfo.createdAt > prevStatus.createdAt
        ) {
          return lastWsMsgInfo;
        }
      } else {
        if (
          lastWsMsgInfo.status === WsTrackStatus.ongoing &&
          lastWsMsgInfo.createdAt > prevStatus.createdAt
        ) {
          return lastWsMsgInfo;
        }
      }
      return prevStatus;
    },
    {
      uiEl,
      apiName: '',
      trackId: '',
      sub: '',
      message: '',
      status: WsTrackStatus.done,
      createdAt: new Date('2000-1-1'),
    },
  );
  return wsMsgInfo.trackId ? wsMsgInfo : null;
};

export const selectWsStatusByTrackId = (trackId?: string) => (state: State) => {
  if (!trackId) return undefined;
  const infos = state.wsmsg[trackId];
  if (!infos || infos.length === 0) return undefined;
  return _.last(infos)?.status;
};

export const isTrackFailed = (trackId?: string) => (state: State) => {
  return trackId && selectWsStatusByTrackId(trackId)(state) === WsTrackStatus.failed;
};

export const isTrackDone = (trackId?: string) => (state: State) => {
  return trackId && selectWsStatusByTrackId(trackId)(state) === WsTrackStatus.done;
};

export default wsmsgSlice.reducer;
