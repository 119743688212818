import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import UsersList from './UsersList';
import User from './User';
import Profile from './Profile';

const UsersRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<UsersList />} />
      <Route path="profile/:userName" element={<Profile />} />
      <Route path="user" element={<User />} />
      <Route path="user/:userName" element={<User />} />
    </Routes>
  );
};

export default UsersRoutes;
