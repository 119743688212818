import * as _ from 'lodash';
import tinycolor from 'tinycolor2';
import { ThemeTintColor, IndexedColor, ARGBColor, Color } from '../types';

const THEME_COLORS = [
  '#FFFFFF',
  '#000000',
  '#EEECE1',
  '#1F497D',
  '#4F81BD',
  '#C0504D',
  '#9BBB59',
  '#8064A2',
  '#4BACC6',
  '#F79646',
];

const INDEXED_COLORS = [
  '#000000',
  '#FFFFFF',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#FF00FF',
  '#00FFFF',
  '#000000',
  '#FFFFFF',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#FF00FF',
  '#00FFFF',
  '#800000',
  '#008000',
  '#000080',
  '#808000',
  '#800080',
  '#008080',
  '#C0C0C0',
  '#808080',
  '#9999FF',
  '#993366',
  '#FFFFCC',
  '#CCFFFF',
  '#660066',
  '#FF8080',
  '#0066CC',
  '#CCCCFF',
  '#000080',
  '#FF00FF',
  '#FFFF00',
  '#00FFFF',
  '#800080',
  '#800000',
  '#008080',
  '#0000FF',
  '#00CCFF',
  '#CCFFFF',
  '#CCFFCC',
  '#FFFF99',
  '#99CCFF',
  '#FF99CC',
  '#CC99FF',
  '#FFCC99',
  '#3366FF',
  '#33CCCC',
  '#99CC00',
  '#FFCC00',
  '#FF9900',
  '#FF6600',
  '#666699',
  '#969696',
  '#003366',
  '#339966',
  '#003300',
  '#333300',
  '#993300',
  '#993366',
  '#333399',
  '#333333',
  '#FFFFFF',
  '#000000',
];

function CalculateFinalLumValue(
  tint: number | null | undefined,
  lum: number,
): number {
  if (!tint) {
    return lum;
  }

  let lum1 = 0;

  if (tint < 0) {
    lum1 = lum * (1.0 + tint);
  } else {
    lum1 = lum * (1.0 - tint) + (255 - 255 * (1.0 - tint));
  }

  return lum1;
}

export const getThemeTintClr = (themeTintColor: ThemeTintColor): string => {
  const clr: tinycolor.Instance = tinycolor(THEME_COLORS[themeTintColor.theme]);
  const hsl: tinycolor.ColorFormats.HSLA = clr.toHsl();
  const calculateFinalLumValue: number =
    CalculateFinalLumValue(themeTintColor.tint, hsl.l * 255) / 255;
  hsl.l = calculateFinalLumValue;
  return tinycolor(hsl).toHexString().toUpperCase();
};

export const getColor = (clr: Color, def = '#FFFFFF'): string => {
  if (_.has(clr, 'theme')) {
    return getThemeTintClr(clr as ThemeTintColor);
  }
  if (_.has(clr, 'indexed')) {
    return INDEXED_COLORS[(clr as IndexedColor).indexed];
  }
  // argb
  const argb: string | undefined = (clr as ARGBColor).argb;
  // const opacity = parseInt(argb.slice(0, 2), 16);
  return argb ? `#${argb.slice(2, 8)}` : def;
};
