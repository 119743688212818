export const check = {
  isComposite: (o: any) =>
    typeof o === 'object' && !(o instanceof Date) && o !== null,
  isObject: (o: unknown) =>
    typeof o === 'object' &&
    !Array.isArray(o) &&
    !(o instanceof Date) &&
    o !== null,
  isNonemptyObject: (o: any) =>
    typeof o === 'object' &&
    !Array.isArray(o) &&
    !(o instanceof Date) &&
    o !== null &&
    Object.keys(o).length !== 0,
  isArray: (a: any) => Array.isArray(a),
  isNonemptyArray: (a: any) => Array.isArray(a) && a.length > 0,
  isDate: (o: any) => typeof o === 'object' && o instanceof Date,
  isUndefined: (a: any) => typeof a === 'undefined',
  isNull: (a: any) => a === null,
  isNothing: (a: any) => typeof a === 'undefined' || a === null,
  isEmpty: (a: unknown) =>
    typeof a === 'undefined' ||
    a === null ||
    (Array.isArray(a) && a.length === 0) ||
    (typeof a === 'string' && a === '') ||
    (typeof a === 'object' &&
      !(a instanceof Date) &&
      Object.keys(a).length === 0),
  isString: (s: any) => typeof s === 'string',
  isBool: (b: any) => typeof b === typeof true,
  isNonemptyString: (s: any) => typeof s === 'string' && s.length > 0,
  isInteger: (i: unknown) => Number.isInteger(i),
  isNumber: (n: unknown) => typeof n === 'number' && !Number.isNaN(n),
  // isStringANumber: (n: any) =>
  //   typeof n === "string" && n.length > 0 && !isNaN(n),
  isFunction: (f: any) => typeof f === 'function',
  isNumeric: (v: any) => !isNaN(v - parseFloat(v)),
  isPositiveNumeric: (v: any) => !isNaN(v - parseFloat(v)) && parseFloat(v) > 0,
};
