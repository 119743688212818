import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as Excel from 'exceljs';
import { Cell } from 'xacmn';
import ExcelCellEditNumber from './ExcelCellEditNumber';
import ExcelCellEditString from './ExcelCellEditString';
import ExcelCellEditDate from './ExcelCellEditDate';
import ExcelCellEditBoolean from './ExcelCellEditBoolean';
import ExcelCellEditRichText from './ExcelCellEditRichText';
import { getFontStyle } from './excelUtil';
import ExcelCellEditDropdown from './ExcelCellEditDropdown';

interface ExcelCellEditProps {
  excelMtdt: Cell;
  val?: Excel.CellValue;
  onBlur?: (value: unknown) => void;
  onFocus?: () => void;
}

const useStyles = makeStyles<Theme, { excelMtdt: Cell }>((theme: Theme) =>
  createStyles({
    edit: (prop) => ({
      width: '100%',
      height: '100%',
      padding: 0,
      border: 0,
      margin: 0,
      backgroundColor: prop.excelMtdt.bgColor,
      ...getFontStyle(prop.excelMtdt),
      '&:focus': {
        outline: 'none',
      },
    }),
  }),
);

const ExcelCellEdit: React.FC<ExcelCellEditProps> = ({ excelMtdt, val, onBlur, onFocus }) => {
  const classes = useStyles({ excelMtdt });
  let Component;

  if (excelMtdt.type === Excel.ValueType.Number) {
    Component = ExcelCellEditNumber;
  } else if (excelMtdt.type === Excel.ValueType.Date) {
    Component = ExcelCellEditDate;
  } else if (excelMtdt.type === Excel.ValueType.RichText) {
    Component = ExcelCellEditRichText;
  } else if (excelMtdt.type === Excel.ValueType.Boolean) {
    Component = ExcelCellEditBoolean;
  } else if (excelMtdt.dataValidation && excelMtdt.dataValidation.type === 'list') {
    Component = ExcelCellEditDropdown;
  } else if (excelMtdt.style?.alignment?.wrapText === true) {
    Component = ExcelCellEditRichText;
  } else {
    Component = ExcelCellEditString;
  }

  return (
    <Component
      excelMtdt={excelMtdt}
      className={classes.edit}
      onBlur={onBlur}
      val={val}
      onFocus={onFocus}
    />
  );
};

export default ExcelCellEdit;
