import React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Task, TaskToCreate, TaskUser } from 'xacmn';
import { selectTaskUsers } from '../task/taskSlice';

interface TaskUsersMenuProps {
  task: Task | TaskToCreate;
  handleListKeyDown: (event: React.KeyboardEvent) => void;
  handleClose: (event: React.MouseEvent<EventTarget>) => void;
  changeUser: (newUser: TaskUser) => void;
}

const TaskUsersMenu: React.FC<TaskUsersMenuProps> = ({
  task,
  changeUser,
  handleClose,
  handleListKeyDown,
}) => {
  const taskUsers = useSelector(selectTaskUsers());

  return (
    <MenuList id={'task-user-menu'} onKeyDown={handleListKeyDown}>
      {taskUsers
        ?.filter((user) => task.assignedToSub !== user.sub)
        .map((user) => (
          <MenuItem
            onClick={(event) => {
              handleClose(event);
              changeUser(user);
            }}
            dense
            key={user.userName}
          >
            {user.name} ({user.userName})
          </MenuItem>
        ))}
    </MenuList>
  );
};

export default TaskUsersMenu;
