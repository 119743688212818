import * as React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useAppDispatch } from '../../app/configureStore';
import { useNavigate, useParams } from 'react-router-dom';
import { createMainTask, getFilledExcel, reset, saveTrans, selectFileKey } from './taskSlice';
import { useButtonStackRootStyles } from '../general/utils';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { parseFileKey } from 'xacmn';
import { Base64 } from 'js-base64';

const TaskBtnBar: React.FC = () => {
  const { root } = useButtonStackRootStyles();
  const navigate = useNavigate();
  const { encodedFileKeyNew } = useParams();
  const fileKey = useSelector(selectFileKey());
  const dispatch = useAppDispatch();

  // debugger;
  // TODO: why render called two times?

  const renderBackBtn = React.useCallback(() => {
    return (
      <Button
        variant="contained"
        color="default"
        value="Submit"
        onClick={() => {
          dispatch(reset());
          navigate('..');
        }}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        Back to Task List
      </Button>
    );
  }, [dispatch, navigate]);

  // eslint-disable-next-line eqeqeq
  if (encodedFileKeyNew && encodedFileKeyNew != '0' && !fileKey) {
    const fileKeyNew = Base64.decode(encodedFileKeyNew);

    const create = () => {
      const { tmplId, fileId } = parseFileKey(fileKeyNew);
      dispatch(createMainTask({ tmplId, fileId }));
    };

    return (
      <div className={root}>
        {renderBackBtn()}
        <Button variant="contained" color="primary" value="Submit" onClick={create}>
          Create
        </Button>
      </div>
    );
  } else {
    if (fileKey) {
      const save = () => {
        dispatch(saveTrans());
      };
      return (
        <div className={root}>
          {renderBackBtn()}
          <Button variant="contained" color="primary" value="Submit" onClick={save}>
            Save
          </Button>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={() => {
              dispatch(getFilledExcel());
            }}
          >
            Download
          </Button>
        </div>
      );
    } else {
      return null;
    }
  }
};

export default TaskBtnBar;
