import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: 'none',
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
  }),
);

const Title: React.FC = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.title} variant="h6" noWrap>
      Power up Your Excel Files
      <span role="img" aria-label="Rocket">
        &nbsp;🚀
      </span>
    </Typography>
  );
};

export default Title;
