import React from 'react';
import { useAppSelector } from '../../app/configureStore';
import { selectCalculateFailedCounter, selectCalculating } from './excelSlice';
import Calculating from './Calculating';

const CalculatingCtr: React.FC = () => {
  const calculating = useAppSelector(selectCalculating());
  const failedCounter = useAppSelector(selectCalculateFailedCounter());

  return <Calculating calculating={calculating} failedCounter={failedCounter} />;
};

export default CalculatingCtr;
