import React from 'react';
import styles from './Progress.module.css';
import grey from '@material-ui/core/colors/grey';
import CircularProgress from '@material-ui/core/CircularProgress';

interface ProgressProps {
  open: boolean;
}

const Progress: React.FC<ProgressProps> = ({ open }) => {
  return (
    <div
      className={styles.container}
      style={{
        display: !open ? 'none' : 'block',
      }}
    >
      <CircularProgress
        style={{ color: grey[500] }}
        className={styles['xa-progress']}
        size={160}
        thickness={2.4}
      />
    </div>
  );
};

export default Progress;
