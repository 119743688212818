import React from 'react';
import Chip from '@material-ui/core/Chip';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { red, cyan, orange, grey } from '@material-ui/core/colors';
import { Task, NewTaskUserData } from 'xacmn';

interface TaskCardPriorityProps {
  priority: Task['priority'];
  readonly: boolean;
  handleListKeyDown: (
    setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>,
  ) => (event: React.KeyboardEvent) => void;
  handleToggle: (setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>) => () => void;
  handleClose: (
    setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>,
    ref: React.RefObject<HTMLElement>,
  ) => (event: React.MouseEvent<EventTarget>) => void;
  saveTaskField: (newTask: Partial<NewTaskUserData>) => Promise<void>;
}

interface TaskCardPriorityViewProps {
  priority: Task['priority'];
  saving: boolean;
  hideEmpty?: boolean;
  larger?: boolean;
}

export const TaskCardPriorityView: React.FC<TaskCardPriorityViewProps> = ({
  priority,
  saving,
  hideEmpty,
  larger,
}) => {
  if (hideEmpty && !priority) return null;
  return (
    <Chip
      size={larger ? 'medium' : 'small'}
      style={{
        backgroundColor:
          priority === 'high'
            ? red[50]
            : priority === 'medium'
            ? orange[50]
            : priority === 'low'
            ? cyan[50]
            : grey[200],
        cursor: 'pointer',
      }}
      icon={
        priority === 'high' ? (
          <KeyboardArrowUpIcon />
        ) : priority === 'medium' ? (
          <DragHandleIcon />
        ) : priority === 'low' ? (
          <KeyboardArrowDownIcon />
        ) : undefined
      }
      label={
        saving ? (
          <CircularProgress size={16} style={{ color: grey[800], marginTop: '2px' }} />
        ) : (
          priority || 'priority'
        )
      }
    />
  );
};

const TaskCardPriority: React.FC<TaskCardPriorityProps> = ({
  priority,
  readonly,
  handleListKeyDown,
  handleToggle,
  handleClose,
  saveTaskField,
}) => {
  const [openPriority, setOpenPriority] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const priorityRef = React.useRef<HTMLDivElement>(null);
  const gone = React.useRef(false);

  React.useEffect(() => {
    return () => {
      gone.current = true;
    };
  }, []);

  const choosePriority = (priority: Task['priority']) => {
    return (event: React.MouseEvent<EventTarget>) => {
      handleClose(setOpenPriority, priorityRef)(event);
      setSaving(true);
      saveTaskField({ priority: priority }).finally(() => {
        if (gone.current) return;
        setSaving(false);
      });
    };
  };

  const renderPriority = React.useCallback(
    (priorityToRender: Task['priority'], _saving = false) => {
      return <TaskCardPriorityView priority={priorityToRender} saving={_saving} />;
    },
    [],
  );

  return (
    <>
      <div
        style={{ display: 'inline' }}
        ref={priorityRef}
        onClick={!readonly ? handleToggle(setOpenPriority) : undefined}
      >
        {renderPriority(priority, saving)}
      </div>
      <Popper
        open={openPriority && !saving}
        anchorEl={priorityRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Paper>
            <ClickAwayListener onClickAway={handleClose(setOpenPriority, priorityRef)}>
              <MenuList
                autoFocusItem={true}
                id="task-priority-menu"
                onKeyDown={handleListKeyDown(setOpenPriority)}
              >
                {(['high', 'medium', 'low'] as const).map((priority) => (
                  <MenuItem onClick={choosePriority(priority)} dense key={priority}>
                    {renderPriority(priority)}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

export default TaskCardPriority;
