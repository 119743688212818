import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import styles from './ExcelStaticValueView.module.css';

interface ExcelStaticValueViewProps {
  value: string;
  style: React.CSSProperties;
}

function _showValue(v: string) {
  return ReactHtmlParser(_.escape(v).replace(/\n/g, '<br />'));
}

const ANIMATION_DURATION = 200;

const ExcelStaticValueView: React.FC<ExcelStaticValueViewProps> = ({ value, style }) => {
  const [displayedValue, setDisplayedValue] = useState(value);
  const valueRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (value !== displayedValue) {
      // Animate value change
      const valueElement = valueRef.current;
      if (valueElement) {
        valueElement.classList.add(styles['scroll-out']);

        setTimeout(() => {
          setDisplayedValue(value);
          valueElement.classList.remove(styles['scroll-out']);
          valueElement.classList.add(styles['scroll-in']);
        }, ANIMATION_DURATION);

        setTimeout(() => {
          valueElement.classList.remove(styles['scroll-in']);
        }, ANIMATION_DURATION * 2);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <span style={style} ref={valueRef} className={styles['value-display']}>
      {_showValue(displayedValue)}
    </span>
  );
};

export default ExcelStaticValueView;
