import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { ReactComponent as HomeIcon } from './xa_icon.svg';
import UserMenu from './UserMenu';
import NavMenu from './NavMenu';
import Search from './Search';
import Notifications from './Notifications';
import Title from './Title';
import TopBarRoutes from './TopBarRoutes';
import { SvgIcon } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      background: '#2E3B55',
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    menuSection: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
  }),
);

const TopBar: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <SvgIcon fontSize="large">
            <HomeIcon />
          </SvgIcon>
          <Title />
          {null && <Search />}
          <div className={classes.grow}>
            <TopBarRoutes />
          </div>
          <NavMenu />
          <div className={classes.menuSection}>
            {null && <Notifications />}
            <UserMenu />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopBar;
