import { grey } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useButtonStackRootStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export const useFrameStyles = makeStyles((theme) =>
  createStyles({
    contentLeft: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    contentMain: {
      flex: 1,
      display: 'flex',
      overflow: 'auto',
    },
    buttonBar: {
      padding: '14px',
      flex: '0 0 64px',
      borderTop: `2px solid ${grey[200]}`,
      background: grey[50],
    },
    rightSideBar: {
      flex: 0.2,
      minWidth: '300px',
      padding: theme.spacing(2),
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
  }),
);
