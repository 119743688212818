import * as React from 'react';
import * as Excel from 'exceljs';
import { Cell } from 'xacmn';
import Checkbox from '@material-ui/core/Checkbox';

interface ExcelCellEditBooleanProps {
  excelMtdt: Cell;
  className: string;
  val?: Excel.CellValue;
  onBlur?: (value: unknown) => void;
  onFocus?: () => void;
}

const ExcelCellEditBoolean: React.FC<ExcelCellEditBooleanProps> = ({
  excelMtdt,
  className,
  val,
  onBlur,
  onFocus,
}) => {
  return (
    <Checkbox
      checked={val as boolean}
      onChange={
        onBlur &&
        ((e) => {
          onBlur(!val);
        })
      }
      name={excelMtdt.cellAddress}
      size="small"
      color="primary"
      onFocus={onFocus}
    />
  );
};

export default ExcelCellEditBoolean;
