import React from 'react';
import { useAppSelector } from '../../app/configureStore';
import { isWaiting } from './generalSlice';
import { selectWsStatus } from '../wsmsg/wsmsgSlice';
import Progress from './Progress';
import { WsMsgUIEl, WsTrackStatus } from 'xacmn';

const ProgressCtr: React.FC = () => {
  const isWaitingForAPIs = useAppSelector(isWaiting());
  const waitingForWsDone = useAppSelector(selectWsStatus(WsMsgUIEl.Global));

  return (
    <Progress
      open={
        isWaitingForAPIs ||
        (!!waitingForWsDone &&
          waitingForWsDone.status !== WsTrackStatus.done &&
          waitingForWsDone.status !== WsTrackStatus.failed)
      }
    />
  );
};

export default ProgressCtr;
