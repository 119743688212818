import * as Excel from 'exceljs';
import { format } from 'date-fns';
import * as XATypes from '../types';
import * as _ from 'lodash';
import { getColor } from './excelcolor';
import { UICfg } from '../types';

// the default width of all columns is 8.43 characters, which corresponds to 64 pixels
const DEFAULT_COLUMN_WIDTH = 8.43;

const inMerge =
  (col: number, row: number) =>
  (merge: Readonly<Excel.Location>): boolean => {
    return (
      merge.top <= row &&
      row <= merge.bottom &&
      merge.left <= col &&
      col <= merge.right
    );
  };

function findInMerges(
  merges: XATypes.Merges,
  col: number,
  row: number,
): undefined | string {
  const pred = inMerge(col, row);
  const key = _.findKey(merges, pred);
  return key;
}

export function nextUILytRow(
  worksheet: Readonly<XATypes.Worksheet4UILyt>,
  loc: Readonly<Excel.Location>,
): number {
  let endRow = loc.top;
  let beginRow = endRow;
  while (endRow <= loc.bottom && beginRow <= endRow) {
    let beginCol = loc.left;
    while (beginCol <= loc.right) {
      const mergedKey = findInMerges(worksheet._merges, beginCol, endRow);
      if (mergedKey) {
        const mergedCell = worksheet._merges[mergedKey];
        endRow = Math.max(endRow, mergedCell.bottom);
        beginCol = mergedCell.right + 1;
      } else {
        beginCol++;
      }
    }
    if (endRow == beginRow) {
      break;
    } else {
      beginRow = endRow;
    }
  }
  return Math.min(loc.bottom, endRow) + 1;
}

export function nextUILytCol(
  worksheet: Readonly<XATypes.Worksheet4UILyt>,
  loc: Readonly<Excel.Location>,
): number {
  let endCol = loc.left;
  let beginCol = endCol;
  while (endCol <= loc.right && beginCol <= endCol) {
    let beginRow = loc.top;
    while (beginRow <= loc.bottom) {
      const mergedKey = findInMerges(worksheet._merges, endCol, beginRow);
      if (mergedKey) {
        const mergedCell = worksheet._merges[mergedKey];
        endCol = Math.max(endCol, mergedCell.right);
        beginRow = mergedCell.bottom + 1;
      } else {
        beginRow++;
      }
    }
    if (endCol == beginCol) {
      break;
    } else {
      beginCol = endCol;
    }
  }
  return Math.min(loc.right, endCol) + 1;
}

function buildUILytRow(
  worksheet: Readonly<XATypes.Worksheet4UILyt>,
  loc: Readonly<Excel.Location>,
  callbacks?: Readonly<XATypes.UILytCallbacks>,
): XATypes.UILytRow[] {
  const rows: XATypes.UILytRow[] = [];
  let curRow = loc.top;
  while (curRow <= loc.bottom) {
    const nr = nextUILytRow(worksheet, {
      top: curRow,
      bottom: loc.bottom,
      left: loc.left,
      right: loc.right,
    });
    const row: XATypes.UILytRow = {
      top: curRow,
      bottom: nr - 1,
      left: loc.left,
      right: loc.right,
    };
    if (callbacks) {
      const { heightPx } = callbacks.getHeight(row.top, row.bottom);
      row.heightPx = heightPx;
    }

    const nextCol = nextUILytCol(worksheet, row);
    if (nextCol <= loc.right) {
      const columns = buildUILytColumn(worksheet, row, callbacks);
      rows.push({
        ...row,
        columns: columns,
      });
    } else {
      if (callbacks) {
        const cell = callbacks.getCell(row);
        rows.push({ ...row, cell: cell });
      } else {
        rows.push({ ...row });
      }
    }
    curRow = nr;
  }
  return rows;
}

function buildUILytColumn(
  worksheet: Readonly<XATypes.Worksheet4UILyt>,
  loc: Readonly<Excel.Location>,
  callbacks?: Readonly<XATypes.UILytCallbacks>,
): XATypes.UILytColumn[] {
  const cols: XATypes.UILytColumn[] = [];
  let curCol = loc.left;
  while (curCol <= loc.right) {
    const nc = nextUILytCol(worksheet, {
      top: loc.top,
      left: curCol,
      bottom: loc.bottom,
      right: loc.right,
    });
    const col: XATypes.UILytColumn = {
      top: loc.top,
      left: curCol,
      bottom: loc.bottom,
      right: nc - 1,
    };
    if (callbacks) {
      const { widthPx } = callbacks.getWidth(col.left, col.right);
      col.widthPx = widthPx;
    }

    const nextRow = nextUILytRow(worksheet, col);
    if (nextRow <= loc.bottom) {
      const rows = buildUILytRow(worksheet, col, callbacks);
      cols.push({
        ...col,
        rows: rows,
      });
    } else {
      if (callbacks) {
        const cell = callbacks.getCell(col);
        cols.push({ ...col, cell: cell });
      } else {
        cols.push({ ...col });
      }
    }
    curCol = nc;
  }
  return cols;
}

export function buildUILyt(
  worksheet: Readonly<XATypes.Worksheet4UILyt>,
  callbacks?: Readonly<XATypes.UILytCallbacks>,
): XATypes.UILyt {
  const rows = buildUILytRow(worksheet, worksheet.dimensions, callbacks);

  const rowWithCol = rows.find((r) => r.columns);
  const widthPx = rowWithCol?.columns?.reduce(
    (prev, cur) => prev + (cur.widthPx || 0),
    0,
  );
  const rowsWithWidth = rows.map((r) => ({
    ...r,
    widthPx,
  }));

  return rowsWithWidth;
}

export function buildUILytFromWorksheet(
  worksheet: Readonly<Excel.Worksheet>,
  fileBaseName: string,
  loc?: Readonly<Excel.Location>,
  uiCfg?: UICfg,
): {
  lyt: XATypes.UILyt;
  mappings: Record<string, string[]>;
  defaultInputs: Record<string, unknown>;
  rowsHeightPx: { h: number; ch: number }[];
  colsWidthPx: { w: number; cw: number }[];
} {
  const _merges: XATypes.Merges = _.get(
    worksheet,
    '_merges',
  ) as unknown as XATypes.Merges;
  const dimensions: Excel.Location = _.cloneDeep(loc || worksheet.dimensions);
  if (loc) {
    if (dimensions.left < worksheet.dimensions.left) {
      dimensions.left = worksheet.dimensions.left;
    }
    if (dimensions.top < worksheet.dimensions.top) {
      dimensions.top = worksheet.dimensions.top;
    }
  }
  const ws: XATypes.Worksheet4UILyt = {
    dimensions,
    _merges: _merges,
  };

  const mappings: Record<string, string[]> = {};
  const defaultInputs: Record<string, unknown> = {};

  const rowsHeightPx: { h: number; ch: number }[] = [];
  for (let i = ws.dimensions.top; i <= ws.dimensions.bottom; i++) {
    const row = worksheet.getRow(i);
    const height = row.hidden
      ? 0
      : row.height ?? worksheet.properties.defaultRowHeight;
    const h = Math.round((height * 20) / 15) + 1;
    const idx = i - ws.dimensions.top;
    rowsHeightPx[idx] = {
      h,
      ch: h + (idx === 0 ? 0 : rowsHeightPx[idx - 1].ch),
    };
  }

  const colsWidthPx: { w: number; cw: number }[] = [];
  for (let i = ws.dimensions.left; i <= ws.dimensions.right; i++) {
    const col = worksheet.getColumn(i);
    const width =
      col.width || worksheet.properties.defaultColWidth || DEFAULT_COLUMN_WIDTH;
    const w = Math.round((width * 64) / DEFAULT_COLUMN_WIDTH) + 1;
    const idx = i - ws.dimensions.left;
    colsWidthPx[idx] = {
      w,
      cw: w + (idx === 0 ? 0 : colsWidthPx[idx - 1].cw),
    };
  }

  const callbacks: XATypes.UILytCallbacks = {
    getHeight: (top: number, bottom: number): { heightPx: number } => {
      let height = 0;
      for (
        let i = top - ws.dimensions.top;
        i <= bottom - ws.dimensions.top;
        i++
      ) {
        height += rowsHeightPx[i].h;
      }
      return { heightPx: height };
    },
    getWidth: (left: number, right: number): { widthPx: number } => {
      let width = 0;
      for (
        let i = left - ws.dimensions.left;
        i <= right - ws.dimensions.left;
        i++
      ) {
        width += colsWidthPx[i].w;
      }
      return { widthPx: width };
    },
    getCell: (loc: Readonly<Excel.Location>): XATypes.Cell => {
      let rightBottomCellBorders: Partial<Excel.Borders> = {};
      // verify merged cell
      let mergedCell: undefined | Excel.Location = undefined;
      if (loc.right > loc.left || loc.bottom > loc.top) {
        const cellAddress = findInMerges(_merges, loc.left, loc.top);
        if (!cellAddress) {
          throw new Error(`cannot find merged cell for ${loc.left},${loc.top}`);
        }
        mergedCell = _merges[cellAddress];
        // TODO: raise meaningful message on unsupported staggered Excel merged cells
        if (
          !(
            mergedCell &&
            loc.top >= mergedCell.top &&
            loc.left >= mergedCell.left &&
            loc.bottom <= mergedCell.bottom &&
            loc.right <= mergedCell.right
          )
        ) {
          throw new Error(
            `staggered Excel merged cells are not supported: ${loc.left},${loc.top}`,
          );
        }
        const rightBottomCell: Excel.Cell = <Excel.Cell>(
          worksheet
            .getRow(mergedCell.bottom)
            .getCell(colNumToLetter(mergedCell.right))
        );
        rightBottomCellBorders = rightBottomCell.style.border || {};
      }

      const excelCell: Excel.Cell = <Excel.Cell>(
        (mergedCell
          ? worksheet
              .getRow(mergedCell.top)
              .getCell(colNumToLetter(mergedCell.left))
          : worksheet.getRow(loc.top).getCell(colNumToLetter(loc.left)))
      );
      const cellAddress = `'[${fileBaseName}]${excelCell.fullAddress.sheetName}'!${excelCell.fullAddress.address}`;
      const xaName = excelCell.names.find((name) => name.startsWith('\\xa\\'));
      let cellPath;
      if (xaName) {
        cellPath = xaName
          .slice(4)
          .split(/[.\\[\]]/)
          .filter((c) => c.length > 0);
        mappings[cellAddress] = cellPath;
        if (excelCell.effectiveType != Excel.ValueType.Formula) {
          defaultInputs[cellAddress] = excelCell.value;
        }
      }
      const fullAddress = {
        ...excelCell.fullAddress,
        sheetName: excelCell.fullAddress.sheetName,
      };
      const cellUiCfg = uiCfg?.cells?.[cellAddress];
      const isStatic =
        (!xaName ||
          (!!xaName && excelCell.effectiveType === Excel.ValueType.Formula)) &&
        !cellUiCfg?.editable;
      const cell: XATypes.Cell = {
        ..._.pick(excelCell, [
          'value',
          'isMerged',
          'effectiveType',
          'isHyperlink',
          'hyperlink',
          'protection',
          'text',
          'dataValidation',
          'note',
          'result',
          'type',
          'formulaType',
          'style',
        ]),
        // TODO: handle non-solid pattern
        ...(excelCell.style?.fill?.type === 'pattern' &&
        excelCell.style?.fill?.fgColor
          ? { bgColor: getColor(excelCell.style.fill.fgColor, '#FFFFFF') }
          : {}),
        ...(excelCell.style.font?.color
          ? { fontColor: getColor(excelCell.style.font.color) }
          : {}),
        columnSpan: loc.right - loc.left + 1,
        isStatic,
        cellPath,
        cellAddress,
        fullAddress,
      };

      // font from row or column
      if (!cell.style) {
        cell.style = {};
      }
      cell.style.font = {
        ...(excelCell as XATypes.ExcelCell)._column.style.font,
        ...(excelCell as XATypes.ExcelCell)._row.style.font,
        ...cell.style?.font,
      };

      // borders
      cell.style.border = {
        ...cell.style.border,
        // borders for merged cell
        ...rightBottomCellBorders,
      };
      // remove duplicated borders
      let excelLeftCell: Excel.Cell;
      let excelTopCell: Excel.Cell;
      if (loc.left >= 2 && (!mergedCell || mergedCell.left == loc.left)) {
        excelLeftCell = <Excel.Cell>(
          worksheet.getRow(loc.top).getCell(colNumToLetter(loc.left - 1))
        );
        if (excelLeftCell?.style?.border?.right) {
          cell.style.border = _.omit(cell.style.border, 'left');
        }
      }
      if (loc.top >= 2 && (!mergedCell || mergedCell.top == loc.top)) {
        excelTopCell = <Excel.Cell>(
          worksheet.getRow(loc.top - 1).getCell(colNumToLetter(loc.left))
        );
        if (excelTopCell?.style?.border?.bottom) {
          cell.style.border = _.omit(cell.style.border, 'top');
        }
      }

      return cell;
    },
  };

  const lyt = buildUILyt(ws, callbacks);
  return { lyt, mappings, defaultInputs, rowsHeightPx, colsWidthPx };
}

function findSheetInUICfg(
  _sheetName: string,
  uiCfg?: XATypes.UICfg,
): XATypes.SheetUICfg | undefined {
  const uiCfgSheetNames = Object.keys(uiCfg?.sheets || {});
  const sheetName = uiCfgSheetNames.find(
    (sheetName) => _sheetName.toUpperCase() === sheetName.toUpperCase(),
  );
  return sheetName ? uiCfg?.sheets?.[sheetName] : undefined;
}

export function loadExcelWorkbook(
  workbook: Excel.Workbook,
  fileBaseName: string,
  uiCfg?: UICfg,
  toAddLTEmptyCells = false,
): XATypes.Workbook {
  let mappings: Record<string, string[]> = {};
  let defaultInputs: Record<string, unknown> = {};

  const worksheetsNOrigLocs: [XATypes.Worksheet, Excel.Location][] =
    workbook.worksheets
      .filter((sheet) => sheet.state === 'visible')
      .map((sheet): [XATypes.Worksheet | null, Excel.Location] => {
        console.debug(`processing sheet ${sheet.name}`);
        const origLoc: Excel.Location = _.pick(sheet.dimensions, [
          'top',
          'left',
          'bottom',
          'right',
        ]);
        let loc: Excel.Location;
        if (uiCfg) {
          const sheetFound = findSheetInUICfg(sheet.name, uiCfg);
          if (sheetFound && sheetFound.display) {
            loc = _.clone(sheetFound.loc);
          } else {
            return [null, origLoc];
          }
        } else {
          loc = origLoc;
        }
        const lytNMapping = buildUILytFromWorksheet(
          sheet,
          fileBaseName,
          loc,
          uiCfg,
        );
        mappings = { ...mappings, ...lytNMapping.mappings };
        defaultInputs = { ...defaultInputs, ...lytNMapping.defaultInputs };
        console.debug(
          `${sheet.name} parsed with ${lytNMapping.lyt.length} rows`,
        );
        return [
          {
            name: sheet.name,
            loc,
            lyt: lytNMapping.lyt,
            rowsHeightPx: lytNMapping.rowsHeightPx,
            colsWidthPx: lytNMapping.colsWidthPx,
          },
          origLoc,
        ];
      })
      .filter(([sheet, _origLoc]) => !!sheet) as [
      XATypes.Worksheet,
      Excel.Location,
    ][];
  let worksheets = worksheetsNOrigLocs.map(([sheet]) => sheet);
  if (toAddLTEmptyCells) {
    worksheets = addLTEmptyCells(fileBaseName, worksheetsNOrigLocs, uiCfg);
  }
  const wb = { name: fileBaseName, worksheets, mappings, defaultInputs };
  return wb;
}

export function colLetterToNum(letter: string): number {
  if (!(letter.length === 1 || letter.length === 2)) {
    throw new Error(`[colLetterToNum] ${letter}`);
  }
  if (letter.length === 1) {
    return letter.charCodeAt(0) - 'A'.charCodeAt(0) + 1;
  } else {
    // 2 letters
    return (
      (letter.charCodeAt(0) - 'A'.charCodeAt(0) + 1) * 26 +
      (letter.charCodeAt(1) - 'A'.charCodeAt(0) + 1)
    );
  }
}

export function colNumToLetter(num: number): string {
  if (!(1 <= num && num <= (26 + 1) * 26)) {
    throw new Error(`[colNumToLetter] ${num}`);
  }
  if (num <= 26) {
    return String.fromCharCode(num - 1 + 'A'.charCodeAt(0));
  } else {
    const rem = num % 26;
    const secondCharCode = (rem === 0 ? 25 : rem - 1) + 'A'.charCodeAt(0);
    const $26s = (num - rem) / 26;
    return String.fromCharCode(
      $26s - (rem === 0 ? 2 : 1) + 'A'.charCodeAt(0),
      secondCharCode,
    );
  }
}

type CellValueType = {
  [Excel.ValueType.Null]: string | number | object | boolean | null;
  [Excel.ValueType.Merge]: string;
  [Excel.ValueType.Number]: number | null;
  [Excel.ValueType.String]: string;
  [Excel.ValueType.Date]: string | null;
  [Excel.ValueType.Hyperlink]: object | string | null;
  [Excel.ValueType.Formula]: string;
  [Excel.ValueType.SharedString]: string;
  [Excel.ValueType.RichText]: object | string | null;
  [Excel.ValueType.Boolean]: boolean;
  [Excel.ValueType.Error]: object | string | null;
};

function typeFromValue(value: unknown): Excel.ValueType {
  if (value === null || value === undefined) {
    return Excel.ValueType.Null;
  }
  if (typeof value === 'string') {
    return Excel.ValueType.String;
  }
  if (typeof value === 'number') {
    return Excel.ValueType.Number;
  }
  if (typeof value === 'boolean') {
    return Excel.ValueType.Boolean;
  }
  if (typeof value === 'object') {
    if (_.has(value, 'richText')) {
      return Excel.ValueType.RichText;
    }
    if (_.has(value, 'formula')) {
      return Excel.ValueType.Formula;
    }
    if (_.has(value, 'error')) {
      return Excel.ValueType.Error;
    }
    if (_.has(value, 'hyperlink')) {
      return Excel.ValueType.Hyperlink;
    }
    return Excel.ValueType.SharedString;
  }
  throw new Error(`[typeFromValue] ${value}`);
}

// https://stackoverflow.com/questions/60996253/infer-typescript-function-return-type-from-enum-parameter
export function typeCellValue<T extends Excel.ValueType>(
  rawValue: unknown,
  type: T,
  excelMtdt?: XATypes.Cell,
): CellValueType[T] {
  return {
    get [Excel.ValueType.Null]() {
      const valueType = typeFromValue(rawValue);
      if (valueType === Excel.ValueType.Null) {
        return null;
      }
      return typeCellValue(rawValue, valueType, excelMtdt);
    },
    get [Excel.ValueType.Merge]() {
      return '';
    },
    get [Excel.ValueType.Number]() {
      const s = `${rawValue}`;
      let v: number | null = Number.parseFloat(s);
      if (Number.isNaN(v)) {
        v = null;
      }
      return v;
    },
    get [Excel.ValueType.String]() {
      return `${rawValue}`;
    },
    get [Excel.ValueType.Date]() {
      const dateStr = `${rawValue}`;
      if (dateStr === 'null') {
        return null;
      }
      return dateStr;
    },
    get [Excel.ValueType.Hyperlink]() {
      if (typeof rawValue === 'object') {
        return rawValue;
      }
      return `${rawValue}`;
    },
    get [Excel.ValueType.Formula]() {
      return `${rawValue}`;
    },
    get [Excel.ValueType.SharedString]() {
      return `${rawValue}`;
    },
    get [Excel.ValueType.RichText]() {
      if (typeof rawValue === 'object' && _.has(rawValue, 'richText')) {
        return rawValue;
      }
      return `${rawValue}`;
    },
    get [Excel.ValueType.Boolean]() {
      return !!rawValue;
    },
    get [Excel.ValueType.Error]() {
      if (typeof rawValue === 'object') {
        return rawValue;
      }
      return `${rawValue}`;
    },
  }[type];
}

const EMPTY_CELL_TPL: XATypes.Cell = {
  isMerged: false,
  effectiveType: Excel.ValueType.Null,
  isHyperlink: false,
  text: '',
  value: null,
  type: Excel.ValueType.String,
  columnSpan: 1,
  isStatic: true,
  cellAddress: "'[<workBookName>]<workSheetName>'!A1",
  fullAddress: {
    sheetName: '<workSheetName>',
    address: 'A1',
    row: 1,
    col: 1,
  },
};

const EMPTY_CELL_WIDTH = 65;
const EMPTY_CELL_HEIGHT = 20;

export function addLTEmptyCells(
  wbName: string,
  worksheetsNOrigLocs: [XATypes.Worksheet, Excel.Location][],
  uiCfg?: XATypes.UICfg,
): XATypes.Worksheet[] {
  const newWorksheets = worksheetsNOrigLocs.map(([ws, origLoc]) => {
    if (origLoc.left === 1 && origLoc.top === 1) {
      return ws;
    }

    const sheetUICfg = findSheetInUICfg(ws.name, uiCfg);

    const newLeft: number = sheetUICfg?.loc.left || 1;
    const newTop: number = sheetUICfg?.loc.top || 1;

    const addedRows = origLoc.top > newTop ? origLoc.top - newTop : 0;
    const addedCols = origLoc.left > newLeft ? origLoc.left - newLeft : 0;
    if (addedRows === 0 && addedCols === 0) {
      return ws;
    }

    const newLoc = {
      left: newLeft,
      top: newTop,
      bottom: sheetUICfg?.loc.bottom ?? origLoc.bottom,
      right: sheetUICfg?.loc.right ?? origLoc.right,
    };

    const rowRange = [...Array(addedRows).keys()];
    const addedHeight = addedRows * EMPTY_CELL_HEIGHT;
    const newRowsHeightPx = [
      ...rowRange.map((idx) => ({
        h: EMPTY_CELL_HEIGHT,
        ch: EMPTY_CELL_HEIGHT * (idx + 1),
      })),
      ...ws.rowsHeightPx.map((rh) => ({
        h: rh.h,
        ch: rh.ch + addedHeight,
      })),
    ];

    const colRange = [...Array(addedCols).keys()];
    const addedWidth = addedCols * EMPTY_CELL_WIDTH;
    const newColsWidthPx = [
      ...colRange.map((idx) => ({
        w: EMPTY_CELL_WIDTH,
        cw: EMPTY_CELL_WIDTH * (idx + 1),
      })),
      ...ws.colsWidthPx.map((cw) => ({
        w: cw.w,
        cw: cw.cw + addedWidth,
      })),
    ];

    function _createEmptyCell(_rowIdx: number, _colIdx: number): XATypes.Cell {
      const addr = `${colNumToLetter(_colIdx + 1)}${_rowIdx + 1}`;
      const cell: XATypes.Cell = {
        ...EMPTY_CELL_TPL,
        cellAddress: `'[${wbName}]${ws.name}'!${addr}`,
        fullAddress: {
          sheetName: ws.name,
          address: addr,
          row: _rowIdx + 1,
          col: _colIdx + 1,
        },
      };
      return cell;
    }

    const addedLytRows: XATypes.UILytRow[] = rowRange.map((rowIdx) => ({
      top: rowIdx + newTop,
      bottom: rowIdx + newTop,
      left: newLeft,
      right: newLoc.right,
      heightPx: EMPTY_CELL_HEIGHT,
      widthPx: newColsWidthPx[newColsWidthPx.length - 1].cw,
      columns: [...Array(newLoc.right - newLeft + 1).keys()].map((colIdx) => {
        return {
          top: rowIdx + newTop,
          bottom: rowIdx + newTop,
          left: colIdx + newLeft,
          right: colIdx + newLeft,
          widthPx: newColsWidthPx[colIdx].w,
          cell: _createEmptyCell(rowIdx + newTop - 1, colIdx + newLeft - 1),
        };
      }),
    }));

    const addedLytCols: XATypes.UILytColumn[] = colRange.map((colIdx) => ({
      top: newLoc.top + addedRows,
      bottom: newLoc.bottom,
      left: colIdx + newLeft,
      right: colIdx + newLeft,
      widthPx: EMPTY_CELL_WIDTH,
      rows: [...Array(newLoc.bottom - newLoc.top - addedRows + 1).keys()].map(
        (rowIdx) => {
          return {
            top: rowIdx + newTop,
            bottom: rowIdx + newTop,
            left: colIdx + newLeft,
            right: colIdx + newLeft,
            heightPx: newRowsHeightPx[rowIdx].h,
            widthPx: EMPTY_CELL_WIDTH,
            cell: _createEmptyCell(rowIdx + newTop - 1, colIdx + newLeft - 1),
          };
        },
      ),
    }));

    const prependedRow: XATypes.UILytRow = {
      top: newLoc.top + addedRows,
      bottom: newLoc.bottom,
      left: newLeft,
      right: newLoc.right,
      widthPx: newColsWidthPx[newColsWidthPx.length - 1].cw,
      heightPx: ws.rowsHeightPx[ws.rowsHeightPx.length - 1].ch,
      columns: [
        ...addedLytCols,
        {
          top: newLoc.top + addedRows,
          bottom: newLoc.bottom,
          left: newLoc.left,
          right: newLoc.right,
          widthPx: ws.colsWidthPx[ws.colsWidthPx.length - 1].cw,
          rows: ws.lyt,
        },
      ],
    };

    const newLyt: XATypes.UILyt = [...addedLytRows, prependedRow];

    return {
      name: ws.name,
      loc: newLoc,
      lyt: newLyt,
      rowsHeightPx: newRowsHeightPx,
      colsWidthPx: newColsWidthPx,
    };
  });

  return newWorksheets;
}

export const EXCEL_CONTENT_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const RE_LOCAL_CELL_ADDRESS = /^([a-zA-Z]{1,2})(\d+)$/;
export function parseCellAddress(cellAddress: string): XATypes.CellFullAddress {
  const cellFullAddress: XATypes.CellFullAddress = {};
  const exclamationPos = cellAddress.lastIndexOf('!');

  const cell = cellAddress.substring(exclamationPos + 1);
  const m = cell.match(RE_LOCAL_CELL_ADDRESS);
  if (!m) {
    throw new Error(`Invalid cell address: ${cellAddress}`);
  }
  cellFullAddress.col = m[1];
  cellFullAddress.row = parseInt(m[2], 10);

  if (exclamationPos > 0) {
    const containsQuotes =
      cellAddress.slice(exclamationPos - 1, exclamationPos) === "'";
    const rightSquareBracketPos = cellAddress.lastIndexOf(']', exclamationPos);
    cellFullAddress.sheetName = cellAddress.substring(
      rightSquareBracketPos < 0 && containsQuotes
        ? 1
        : rightSquareBracketPos + 1,
      exclamationPos - (containsQuotes ? 1 : 0),
    );

    if (rightSquareBracketPos > 0) {
      cellFullAddress.fileName = cellAddress.substring(
        containsQuotes ? 2 : 1,
        rightSquareBracketPos,
      );
    }
  }
  return cellFullAddress;
}
