import React from 'react';
import Chip from '@material-ui/core/Chip';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from '@material-ui/core/CircularProgress';
import { grey } from '@material-ui/core/colors';
import Popper from '@material-ui/core/Popper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Task, NewTaskUserData } from 'xacmn';
import 'react-datepicker/dist/react-datepicker.css';

interface TaskCardDueAtProps {
  dueAt: Task['dueAt'];
  readonly: boolean;
  saveTaskField: (newTask: Partial<NewTaskUserData>) => Promise<void>;
}

const TaskCardDueAt: React.FC<TaskCardDueAtProps> = ({ dueAt, readonly, saveTaskField }) => {
  const [isDueAtOpen, setIsDueAtOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const dueAtRef = React.useRef<HTMLDivElement>(null);
  const gone = React.useRef(false);

  React.useEffect(() => {
    return () => {
      gone.current = true;
    };
  }, []);

  const changeDueAt = (newDueAt: Task['dueAt']) => {
    setIsDueAtOpen(false);
    setSaving(true);
    saveTaskField({ dueAt: newDueAt }).finally(() => {
      if (gone.current) return;
      setSaving(false);
    });
  };

  return (
    <>
      <Chip
        size="small"
        ref={dueAtRef}
        icon={<DateRangeIcon />}
        label={
          saving ? (
            <CircularProgress size={16} style={{ color: grey[800], marginTop: '2px' }} />
          ) : dueAt ? (
            new Date(dueAt).toLocaleDateString()
          ) : (
            'due'
          )
        }
        onClick={readonly ? undefined : () => setIsDueAtOpen(true)}
      />
      <Popper
        open={isDueAtOpen && !saving}
        anchorEl={dueAtRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={() => setIsDueAtOpen(false)}>
          <DatePicker
            selected={dueAt ? new Date(dueAt) : new Date()}
            onChange={(dueAt) => changeDueAt(dueAt?.toISOString())}
            inline
          />
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default TaskCardDueAt;
