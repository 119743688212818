import CSS from 'csstype';
import { Cell, Worksheet, Loc, parseCellAddress } from 'xacmn';
import * as SSF from 'ssf';
import { check } from 'xacmn';
import * as Excel from 'exceljs';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import _ from 'lodash';

function getCellAlignmentStyle(cell: Cell): CSS.Properties {
  // TODO: handle other alignments
  let alignmentStyle = {};

  const horizontalAlignment = cell.style?.alignment?.horizontal;
  if (horizontalAlignment === 'right') {
    alignmentStyle = {
      display: 'flex',
      justifyContent: 'flex-end',
    };
  } else if (horizontalAlignment === 'center') {
    alignmentStyle = {
      display: 'flex',
      justifyContent: 'center',
    };
  } else if (horizontalAlignment === 'justify') {
    alignmentStyle = {
      display: 'flex',
      textAlign: 'justify',
    };
  }

  const verticalAlignment = cell.style?.alignment?.vertical;
  if (verticalAlignment === 'bottom') {
    alignmentStyle = {
      ...alignmentStyle,
      display: 'flex',
      alignItems: 'flex-end',
    };
  } else if (verticalAlignment === 'middle') {
    alignmentStyle = {
      ...alignmentStyle,
      display: 'flex',
      alignItems: 'center',
    };
  } else if (verticalAlignment === 'top') {
    alignmentStyle = {
      ...alignmentStyle,
      display: 'flex',
      alignItems: 'flex-start',
    };
  } else {
    // default as bottom
    alignmentStyle = {
      ...alignmentStyle,
      display: 'flex',
      alignItems: 'center',
    };
  }

  return alignmentStyle;
}

function getCellBorderStyle(cell: Cell): CSS.Properties {
  let borderStyle = {};

  const left = cell.style?.border?.left;
  if (left) {
    borderStyle = {
      ...borderStyle,
      borderLeftWidth: '1px',
      borderLeftStyle: 'solid',
    };
  }
  const right = cell.style?.border?.right;
  if (right) {
    borderStyle = {
      ...borderStyle,
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
    };
  }
  const top = cell.style?.border?.top;
  if (top) {
    borderStyle = {
      ...borderStyle,
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
    };
  }
  const bottom = cell.style?.border?.bottom;
  if (bottom) {
    borderStyle = {
      ...borderStyle,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
    };
  }

  return borderStyle;
}
export function getCellStyle(cell: Cell, focused: boolean): CSS.Properties {
  let cellImposedDivStyle: CSS.Properties = {
    display: 'block',
    whiteSpace: 'nowrap',
    padding: '2px',
    ...(cell.isStatic ? {} : { overflow: 'hidden' }),
  };

  if (cell.style?.alignment?.wrapText) {
    cellImposedDivStyle.whiteSpace = 'normal';
  }

  cellImposedDivStyle = {
    ...(focused ? { outline: '2px solid green' } : {}),
    ...cellImposedDivStyle,
    ...getCellAlignmentStyle(cell),
    ...getCellBorderStyle(cell),
  };

  if (cell.bgColor) {
    cellImposedDivStyle = {
      ...cellImposedDivStyle,
      background: cell.bgColor,
    };
  }

  const backgroundOnly: boolean = isBackgroundOnly(cell);
  cellImposedDivStyle = {
    ...cellImposedDivStyle,
    zIndex: backgroundOnly ? 1 : 2,
  };

  return cellImposedDivStyle;
}

interface Size {
  left: string;
  top: string;
  width: string;
  height: string;
}

interface HiddenAreas {
  left: Size;
  top: Size;
  right: Size;
  bottom: Size;
}

export function isBackgroundOnly(cell: Cell): boolean {
  return (
    check.isEmpty(cell?.text) &&
    check.isEmpty(cell?.value) &&
    check.isEmpty(cell?.formulaType) &&
    check.isEmpty(cell?.result) &&
    cell?.isStatic === true &&
    cell?.type === Excel.ValueType.Null
  );
}

export function getHiddenAreas(excelMtdt: Worksheet, loc: Loc): HiddenAreas {
  // TODO: deal with sheet LT is not (1, 1)
  const l = loc.left <= 1 ? 0 : excelMtdt.colsWidthPx[loc.left - 2].cw;
  const t = loc.top <= 1 ? 0 : excelMtdt.rowsHeightPx[loc.top - 2].ch;
  const r = excelMtdt.colsWidthPx[loc.right - 1].cw;
  const b = excelMtdt.rowsHeightPx[loc.bottom - 1].ch;
  const w = excelMtdt.colsWidthPx[excelMtdt.colsWidthPx.length - 1].cw;
  const h = excelMtdt.rowsHeightPx[excelMtdt.rowsHeightPx.length - 1].ch;
  const left: Size = {
    left: '0px',
    top: '0px',
    width: `${l}px`,
    height: `${h}px`,
  };
  const top: Size = {
    left: `${l}px`,
    top: '0px',
    width: `${w - l}px`,
    height: `${t}px`,
  };
  const right: Size = {
    left: `${r}px`,
    top: `${t}px`,
    width: `${w - r}px`,
    height: `${h - t}px`,
  };
  const bottom: Size = {
    left: `${l}px`,
    top: `${b}px`,
    width: `${w - l - (w - r)}px`,
    height: `${h - b}px`,
  };
  return {
    left,
    top,
    right,
    bottom,
  };
}

export const DEFAULT_FONT_SIZE = 11;
export const DEFAULT_FONT_FAMILY = 'Calibri';
export const DEFAULT_FONT_COLOR = '#000000';

export function getFontStyle(excelMtdt: Cell) {
  const style: CSSProperties = {
    fontWeight: excelMtdt.style?.font?.bold === true ? 'bold' : 'normal',
    fontStyle: excelMtdt.style?.font?.italic === true ? 'italic' : 'normal',
    fontSize: `${excelMtdt.style?.font?.size || DEFAULT_FONT_SIZE}pt`,
    fontFamily: excelMtdt.style?.font?.name || DEFAULT_FONT_FAMILY,
    color: excelMtdt.fontColor || DEFAULT_FONT_COLOR,
  };
  return style;
}

const RE_CELL_ADDRESS = /^([^/\\?*[\]]+)!([a-zA-Z]{0,2})(\d*)(:([a-zA-Z]{0,2})(\d*))?$/;
export function normalizeCellAddress(svrAddr: string, fileName: string): string {
  const m = svrAddr.match(RE_CELL_ADDRESS);
  if (m) {
    let sheetName = m[1];
    if (sheetName.startsWith("'") && sheetName.endsWith("'")) {
      sheetName = sheetName.substring(1, sheetName.length - 1);
    }
    const col1 = m[2];
    const row1 = m[3];
    const col2 = m[5];
    const row2 = m[6];
    return `'[${fileName}]${sheetName}'!${col1.toUpperCase()}${row1}${
      col2 ? `:${col2.toUpperCase()}${row2}` : ''
    }`;
  }
  throw new Error(`Invalid cell address: ${svrAddr}`);
}

export function cellAddressesEqual(firstCell: string, secondCell: string): boolean {
  const firstCellAddress = parseCellAddress(firstCell);
  const secondCellAddress = parseCellAddress(secondCell);
  return (
    firstCellAddress.col?.toUpperCase() === secondCellAddress.col?.toUpperCase() &&
    firstCellAddress.row === secondCellAddress.row &&
    firstCellAddress.sheetName?.toUpperCase() === secondCellAddress.sheetName?.toUpperCase()
  );
}

export function getCellValue(cellAddress: string, cells: Record<string, unknown>): unknown {
  const key = Object.keys(cells).find((cell) => cellAddressesEqual(cell, cellAddress));
  if (key) {
    return cells[key];
  }
  return undefined;
}

const ISO_DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/;

function getDateDisplay(cellValue: Date | string, cell: Cell): string {
  let dateValue: Date;
  if (typeof cellValue === 'string') {
    if (ISO_DATE_REGEX.test(cellValue)) {
      dateValue = new Date(cellValue);
    } else {
      return cellValue;
    }
  } else {
    dateValue = cellValue;
  }
  const numFmt = cell.style?.numFmt;
  if (numFmt) {
    return SSF.format(numFmt, dateValue);
  }
  return dateValue.toLocaleDateString();
}

const DEFAULT_DECIMAL_PLACES = 2;

function getNumberDisplay(cellValue: number, cell: Cell): string {
  const numFmt = cell.style?.numFmt;
  if (numFmt) {
    return SSF.format(numFmt, cellValue);
  } else if (cellValue % 1 !== 0) {
    return cellValue.toFixed(DEFAULT_DECIMAL_PLACES);
  }
  return `${cellValue}`;
}

function getErrorDisplay(error: Excel.CellErrorValue): string {
  // TODO: more meaningful error than Excel error
  return error.error;
}

function getFormulaResult(
  result: number | string | Date | { error: Excel.CellErrorValue } | undefined | null,
  cell: Cell,
): string {
  if (check.isDate(result) || cell.effectiveType === Excel.ValueType.Date) {
    return getDateDisplay(result as string | Date, cell);
  }
  if (check.isNumber(result)) {
    return getNumberDisplay(result as number, cell);
  }
  if (check.isObject(result)) {
    return getErrorDisplay((result as { error: Excel.CellErrorValue }).error);
  }
  if (check.isString(result)) {
    return result as string;
  }
  return '';
}

export function getCellValueDisplay(cellValue: Excel.CellValue, cell: Cell): string {
  if (check.isEmpty(cellValue)) return '';

  if (check.isObject(cellValue)) {
    if (_.has(cellValue, 'error')) {
      return getErrorDisplay(cellValue as Excel.CellErrorValue);
    }
    if (_.has(cellValue, 'richText')) {
      const richTextValue: Excel.CellRichTextValue = cellValue as Excel.CellRichTextValue;
      return richTextValue.richText.reduce((prevValue, rt) => {
        // TODO: keep font
        return `${prevValue}${rt.text}`;
      }, '');
    }
    if (_.has(cellValue, 'hyperlink')) {
      const hyperlinkValue: Excel.CellHyperlinkValue = cellValue as Excel.CellHyperlinkValue;
      // TODO: keep hyperlink here
      return hyperlinkValue.text;
    }
    if (_.has(cellValue, 'formula')) {
      const formulaValue: Excel.CellFormulaValue = cellValue as Excel.CellFormulaValue;
      return getFormulaResult(formulaValue.result, cell);
    }
    if (_.has(cellValue, 'sharedFormula')) {
      const formulaValue: Excel.CellSharedFormulaValue = cellValue as Excel.CellSharedFormulaValue;
      return getFormulaResult(formulaValue.result, cell);
    }
  }
  if (
    check.isDate(cellValue) ||
    cell.effectiveType === Excel.ValueType.Date ||
    cell.type === Excel.ValueType.Date
  ) {
    return getDateDisplay(cellValue as Date | string, cell);
  }
  if (check.isNumber(cellValue)) {
    return getNumberDisplay(cellValue as number, cell);
  }
  if (check.isString(cellValue)) {
    return cellValue as string;
  }
  if (check.isBool(cellValue)) {
    return `${cellValue}`.toUpperCase();
  }
  return '';
}

// below function turns the count of times into text like once, twice, etc.
export function getTimesText(times: number): string {
  if (times === 1) {
    return 'once';
  }
  if (times === 2) {
    return 'twice';
  }
  return `${times} times`;
}

export function isFormulaValue(
  cellValue: Excel.CellValue,
): cellValue is Excel.CellFormulaValue | Excel.CellSharedFormulaValue {
  if (check.isObject(cellValue)) {
    return _.has(cellValue, 'formula') || _.has(cellValue, 'sharedFormula');
  }
  return false;
}
