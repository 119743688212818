import React from 'react';
import UsersRoutes from './UsersRoutes';

const Frame: React.FC = () => {
  return (
    <>
      <UsersRoutes />
    </>
  );
};

export default Frame;
