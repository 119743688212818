import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link } from 'react-router-dom';
import { getComparator, stableSort, Order } from '../../utils';
import { check } from 'xacmn';
import Placeholder from './Placeholder';

type StrProp<T> = Extract<keyof T, string>;

export interface HeadCell<T> {
  prop: StrProp<T>;
  element?: (row: T) => React.ReactNode;
  label?: string;
  hasLink?: boolean;
}

interface TableHeadProps<T> {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: StrProp<T>) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell<T>[];
  rows: T[] | null | undefined;
}

const TabledListHead = <T extends unknown>(props: TableHeadProps<T>) => {
  const { classes, order, orderBy, onRequestSort, headCells, rows } = props;
  const createSortHandler = (property: StrProp<T>) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.prop}
            align={
              rows && rows.length > 0 && check.isNumber(rows[0][headCell.prop]) ? 'right' : 'left'
            }
            sortDirection={orderBy === headCell.prop ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.prop}
              direction={orderBy === headCell.prop ? order : 'asc'}
              onClick={createSortHandler(headCell.prop)}
            >
              {headCell.label || headCell.prop}
              {orderBy === headCell.prop ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
  }),
);

interface TableToolbarProps {
  tableTitle: string;
  showAddButton?: boolean;
  addFn?: () => void;
  TRElement?: React.ReactElement;
}

const TableToolbar = (props: TableToolbarProps) => {
  const classes = useToolbarStyles();
  const { tableTitle } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {tableTitle}
      </Typography>
      {null && (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
      {props.TRElement ? props.TRElement : null}
      {props.showAddButton ? (
        <Tooltip title="add">
          <IconButton aria-label="add" onClick={props.addFn} color="primary">
            <AddCircleIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

interface TabledListProps<T> {
  rows: T[] | null | undefined;
  generateLink: (row: T, prop: Extract<keyof T, string>) => string;
  defaultOrderBy?: StrProp<T>;
  pkProp: StrProp<T>;
  tableTitle: string;
  headCells: HeadCell<T>[];
  loading?: boolean;
  showAddButton?: boolean;
  addFn?: () => void;
  TRElement?: React.ReactElement;
}

const TabledList = <T extends unknown>(props: TabledListProps<T>) => {
  const classes = useStyles();
  const { rows, generateLink, defaultOrderBy, pkProp, tableTitle, headCells, loading } = props;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<StrProp<T>>(defaultOrderBy || pkProp);
  const [selected, setSelected] = React.useState<unknown[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: StrProp<T>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = React.useCallback(
    (event: React.MouseEvent<unknown>, name: unknown) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected: unknown[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }

      setSelected(newSelected);
    },
    [selected],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, (rows || []).length - page * rowsPerPage);

  const showRows = React.useCallback(() => {
    return (
      <>
        {rows
          ? stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const isItemSelected = selected.indexOf(row[pkProp]) !== -1;

                return (
                  <TableRow
                    hover
                    onClick={(event: React.MouseEvent<unknown>) => handleClick(event, row[pkProp])}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row[pkProp] as unknown as React.Key}
                    selected={isItemSelected}
                  >
                    {headCells.map((headCell, headCellIdx) => {
                      const cellId = `${row[pkProp]}-${headCell.prop}`;
                      return (
                        <TableCell
                          component={headCellIdx === 0 ? 'th' : undefined}
                          id={cellId}
                          key={cellId}
                          scope={headCellIdx === 0 ? 'row' : undefined}
                        >
                          {headCell.hasLink ? (
                            <Link to={generateLink(row, headCell.prop)}>{row[headCell.prop]}</Link>
                          ) : headCell.element ? (
                            headCell.element(row)
                          ) : (
                            row[headCell.prop]
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
          : null}
        {emptyRows > 0 ? (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={headCells.length + 1} />
          </TableRow>
        ) : null}
      </>
    );
  }, [
    emptyRows,
    generateLink,
    handleClick,
    headCells,
    order,
    orderBy,
    page,
    pkProp,
    rows,
    rowsPerPage,
    selected,
  ]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          tableTitle={tableTitle}
          showAddButton={props.showAddButton}
          addFn={props.addFn}
          TRElement={props.TRElement}
        />
        <TableContainer>
          <Table className={classes.table} size="medium">
            <TabledListHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              rows={rows}
            />
            <TableBody>
              {loading ? (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={headCells.length + 1} style={{ verticalAlign: 'top' }}>
                    <Placeholder info="loading items ..." />
                  </TableCell>
                </TableRow>
              ) : (
                showRows()
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={(rows || []).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default TabledList;
