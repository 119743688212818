import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { Tmpl } from 'xacmn';
import { useAppDispatch } from '../../app/configureStore';
import Tmpls from './Tmpls';
import { getTmpls, selectTmpls, selectCurrentTmpl, setCurrentTmpl } from './workflowSlice';

interface TmplsCtrProp {}

const TmplsCtr: React.FC<TmplsCtrProp> = () => {
  const tmpls = useSelector(selectTmpls());
  const currentTmpl = useSelector(selectCurrentTmpl());
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!tmpls) {
      dispatch(getTmpls()).then((rslt) => {
        if (rslt.meta.requestStatus === 'fulfilled' && (rslt.payload as Tmpl[]).length === 0) {
          navigate('../template');
        }
      });
    }
  }, [dispatch, navigate, tmpls]);

  React.useEffect(() => {
    if (tmpls?.length && !currentTmpl) {
      dispatch(setCurrentTmpl(tmpls[0]));
    }
  }, [currentTmpl, dispatch, tmpls]);

  const setTmpl = React.useCallback(
    (tmpl: Tmpl) => {
      dispatch(setCurrentTmpl(tmpl));
    },
    [dispatch],
  );

  return <Tmpls tmpls={tmpls} currentTmpl={currentTmpl} setTmpl={setTmpl} />;
};
export default TmplsCtr;
