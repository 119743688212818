import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import login from './login';
import FullscreenMessage from './features/general/FullscreenMessage';

login()
  .then((loggedIn: boolean) => {
    if (loggedIn) {
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById('root'),
      );
    } else {
      ReactDOM.render(
        <React.StrictMode>
          <FullscreenMessage info="Going to login page ..." />
        </React.StrictMode>,
        document.getElementById('root'),
      );
    }
  })
  .catch((error: Error) => {
    console.error(error);
    const tenantName = sessionStorage.getItem('logout_account');
    sessionStorage.removeItem('logout_account');
    ReactDOM.render(
      <React.StrictMode>
        <FullscreenMessage
          info={
            tenantName
              ? `Please click <a href="${tenantName}">here</a> to sign back in.`
              : "You've been logged out."
          }
        />
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
