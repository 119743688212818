import React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useAppDispatch } from '../../app/configureStore';
import Chip from '@material-ui/core/Chip';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { grey } from '@material-ui/core/colors';
import { Task, TaskToCreate, NewTaskUserData } from 'xacmn';
import { getTaskTypes, selectTaskTypes } from '../task/taskSlice';

interface TaskCardTypeProps {
  task: Task | undefined | TaskToCreate;
  readonly: boolean;
  handleListKeyDown: (
    setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>,
  ) => (event: React.KeyboardEvent) => void;
  handleToggle: (setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>) => () => void;
  handleClose: (
    setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>,
    ref: React.RefObject<HTMLElement>,
  ) => (event: React.MouseEvent<EventTarget>) => void;
  saveTaskField: (newTask: Partial<NewTaskUserData>) => Promise<void>;
}

const TaskCardType: React.FC<TaskCardTypeProps> = ({
  task,
  readonly,
  handleListKeyDown,
  handleToggle,
  handleClose,
  saveTaskField,
}) => {
  const taskTypes = useSelector(selectTaskTypes());
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [openType, setOpenType] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const typeRef = React.useRef<HTMLDivElement>(null);
  const gone = React.useRef(false);

  React.useEffect(() => {
    return () => {
      gone.current = true;
    };
  }, []);

  React.useEffect(() => {
    if (!taskTypes) {
      setLoading(true);
      dispatch(getTaskTypes()).finally(() => {
        if (gone.current) return;
        setLoading(false);
      });
    }
  }, [taskTypes, dispatch]);

  const chooseType = (taskType: Task['taskType']) => {
    return (event: React.MouseEvent<EventTarget>) => {
      handleClose(setOpenType, typeRef)(event);
      setSaving(true);
      saveTaskField({ taskType }).finally(() => {
        if (gone.current) return;
        setSaving(false);
      });
    };
  };

  return (
    <>
      <div
        style={{ float: 'right', display: 'inline' }}
        ref={typeRef}
        onClick={!readonly ? handleToggle(setOpenType) : undefined}
      >
        <Chip
          size="small"
          style={{ backgroundColor: grey[200], cursor: 'pointer' }}
          icon={<LocalOfferIcon />}
          label={
            loading || saving ? (
              <CircularProgress size={16} style={{ color: grey[800], marginTop: '2px' }} />
            ) : (
              task?.taskType || 'type'
            )
          }
        />
      </div>
      <Popper
        open={openType && !saving}
        anchorEl={typeRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Paper>
            <ClickAwayListener onClickAway={handleClose(setOpenType, typeRef)}>
              <MenuList
                autoFocusItem={true}
                id="task-type-menu"
                onKeyDown={handleListKeyDown(setOpenType)}
              >
                {(taskTypes || []).map((type) => (
                  <MenuItem onClick={chooseType(type)} dense key={type}>
                    {type}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

export default TaskCardType;
