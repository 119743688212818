import * as React from 'react';
import * as Excel from 'exceljs';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import Cleave from 'cleave.js/react';
import { Cell } from 'xacmn';

interface ExcelCellEditNumberProps {
  excelMtdt: Cell;
  className: string;
  val?: Excel.CellValue;
  onBlur?: (value: unknown) => void;
  onFocus?: () => void;
}

const useStyles = makeStyles<Theme, { excelMtdt: Cell }>((theme: Theme) =>
  createStyles({
    number: (prop) => ({
      textAlign: 'right',
    }),
  }),
);

const ExcelCellEditNumber: React.FC<ExcelCellEditNumberProps> = ({
  excelMtdt,
  className,
  val,
  onBlur,
  onFocus,
}) => {
  const classes = useStyles({ excelMtdt });
  return (
    <Cleave
      className={`${className} ${classes.number}`}
      options={{ numeral: true, numeralDecimalScale: 4 }}
      onBlur={onBlur && ((e) => onBlur(_.get(e.target, 'rawValue')))}
      value={val as number}
      onFocus={onFocus}
    />
  );
};

export default ExcelCellEditNumber;
