import * as React from 'react';
import { useParams } from 'react-router-dom';
import TmplsCtr from './TmplsCtr';
import TasksCtr from './TasksCtr';
import Placeholder from '../general/Placeholder';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

const drawerWidth = 320;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      position: 'relative',
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: '0px',
      minWidth: 0, // So the Typography noWrap works
      boxSizing: 'border-box',
    },
    leftDrawer: {
      height: '100%',
      boxSizing: 'border-box',
    },
  }),
);

const Dashboard: React.FC = () => {
  const { account } = useParams();
  const classes = useStyles();
  if (!account) {
    return <Placeholder info="Account not specified. I am not able to show anything." />;
  }

  return (
    <>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        className={classes.leftDrawer}
      >
        <TmplsCtr />
      </Drawer>
      <main className={classes.content}>
        <TasksCtr />
      </main>
    </>
  );
};
export default Dashboard;
