import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Task, TaskAssignmentParams } from 'xacmn';
import { useAppDispatch } from '../../app/configureStore';
import { abortTask } from '../task/taskSlice';
import { notify } from '../general/generalSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      float: 'right',
      marginLeft: theme.spacing(1),
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }),
);

interface TaskCardMenuProps {
  task: Task;
  handleListKeyDown: (
    setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>,
  ) => (event: React.KeyboardEvent) => void;
  handleToggle: (setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>) => () => void;
  handleClose: (
    setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>,
    ref: React.RefObject<HTMLElement>,
  ) => (event: React.MouseEvent<EventTarget>) => void;
}

const TaskCardMenu: React.FC<TaskCardMenuProps> = ({
  task,
  handleListKeyDown,
  handleToggle,
  handleClose,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [cfmDlgOpen, setCfmDlgOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const complete = React.useCallback(() => {
    const params: TaskAssignmentParams = {
      assignmentId: task.assignmentId,
    };
    dispatch(abortTask(params)).then((fulfilledAction) => {
      if (fulfilledAction?.payload) {
        dispatch(
          notify({
            variant: 'success',
            message: 'Task has been successfully deleted',
          }),
        );
      } else {
        dispatch(
          notify({
            variant: 'error',
            message: 'Task deletion failed.',
          }),
        );
      }
    });
  }, [dispatch, task.assignmentId]);

  const handleCfmDlgCancel = () => {
    setCfmDlgOpen(false);
    handleClose(setOpen, anchorRef);
  };

  const handleCfmDlgConfirm = () => {
    setCfmDlgOpen(false);
    handleClose(setOpen, anchorRef);
    complete();
  };

  return task ? (
    <>
      <IconButton
        ref={anchorRef}
        aria-controls={open && task ? `task-action-menu-${task.assignmentId}` : undefined}
        aria-haspopup="true"
        onClick={handleToggle(setOpen)}
        className={classes.menuButton}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Paper>
            <ClickAwayListener onClickAway={handleClose(setOpen, anchorRef)}>
              <MenuList
                autoFocusItem={true}
                id={`task-action-menu-${task.assignmentId}`}
                onKeyDown={handleListKeyDown(setOpen)}
              >
                <MenuItem onClick={() => setCfmDlgOpen(true)} dense>
                  Delete
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
      <Dialog
        open={cfmDlgOpen}
        onClose={handleCfmDlgCancel}
        aria-labelledby="cfm-dlg-cmpl-title"
        aria-describedby="cfm-dlg-cmpl-desc"
      >
        <DialogContent>
          <DialogContentText id="cfm-dlg-cmpl-desc">
            You are about to delete this task. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCfmDlgCancel} color="default">
            Cancel
          </Button>
          <Button onClick={handleCfmDlgConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};

export default TaskCardMenu;
