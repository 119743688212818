import * as React from 'react';
import Button from '@material-ui/core/Button';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import SaveIcon from '@material-ui/icons/Save';
import { useAppDispatch, useAppSelector as useSelector } from '../../app/configureStore';
import { saveUICfg } from '../excel/excelUICfgSlice';
import { useButtonStackRootStyles } from '../general/utils';
import { parseFileKey } from 'xacmn';
import { TmplInPath } from 'xacmn';
import { isTrackDone } from '../wsmsg/wsmsgSlice';
import { selectTmpl } from './tplSlice';
import { notify } from '../general/generalSlice';
import { Base64 } from 'js-base64';

enum SaveState {
  waitingForSaveNNext,
}

const TplUICfgBtnBar: React.FC = () => {
  const { root } = useButtonStackRootStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { encodedKey } = useParams();
  const [saveTrackId, setSaveTrackId] = React.useState<string | undefined>();
  const isSaveDone = useSelector(isTrackDone(saveTrackId));
  const [saveState, setSaveState] = React.useState<SaveState>();
  const tpl = useSelector(selectTmpl());

  React.useEffect(() => {
    if (isSaveDone && encodedKey && saveState === SaveState.waitingForSaveNNext) {
      navigate(`../preview/${encodedKey}`, { replace: true });
    }
    // TODO: handle track failure
  }, [encodedKey, isSaveDone, navigate, saveState]);

  if (!encodedKey) return null;
  const { tmplId, fileId, fileName } = parseFileKey(Base64.decode(encodedKey));
  const tmplInPath: TmplInPath = { tmplId, fileId, fileName };

  async function startSave(): Promise<void> {
    try {
      const trackId = await dispatch(saveUICfg(tmplInPath)).unwrap();
      setSaveTrackId(trackId);
    } catch (err) {
      dispatch(
        notify({
          variant: 'error',
          message: (err as Error).message,
        }),
      );
    }
  }

  async function save(): Promise<void> {
    await startSave();
    setSaveState(undefined);
  }

  async function saveNNext(): Promise<void> {
    if (tpl?.tmplStatus === 'archived') {
      navigate(`../preview/${encodedKey}`, { replace: true });
    } else {
      await startSave();
      setSaveState(SaveState.waitingForSaveNNext);
    }
  }

  return (
    <div className={root}>
      <Button
        variant="contained"
        color="default"
        onClick={() => navigate('..')}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        Back to Template List
      </Button>
      {tpl?.tmplStatus === 'archived' ? null : (
        <Button variant="contained" color="primary" onClick={save} startIcon={<SaveIcon />}>
          Save
        </Button>
      )}
      <Button
        style={{ marginLeft: 'auto' }}
        variant="contained"
        color="primary"
        onClick={saveNNext}
        endIcon={<KeyboardArrowRightIcon />}
      >
        Preview
      </Button>
    </div>
  );
};

export default TplUICfgBtnBar;
