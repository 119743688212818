import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import TplRSBCtr from './TplRSBCtr';

const TplRSBRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={'uicfg/:encodedKey'} element={<TplRSBCtr step={1} />} />
      <Route path={'preview/:encodedKey'} element={<TplRSBCtr step={2} />} />
    </Routes>
  );
};

export default TplRSBRoutes;
