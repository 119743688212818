import React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { Task, TaskToCreate, NewTaskUserData } from 'xacmn';
import TaskCard from './TaskCard';
import { selectTaskUserNameBySub } from '../task/taskSlice';

interface TaskCardCtrProps {
  task?: Task | TaskToCreate;
  loadingName: boolean;
  setNewTaskProp?: (newTask: Partial<NewTaskUserData>) => void;
}

const TaskCardCtr: React.FC<TaskCardCtrProps> = ({ task, setNewTaskProp, loadingName }) => {
  const assignedName = useSelector(selectTaskUserNameBySub(task?.assignedToSub));

  return (
    <TaskCard
      task={task}
      assignedName={loadingName ? '__loading__' : assignedName ? assignedName : 'N/A'}
      setNewTaskProp={setNewTaskProp}
    />
  );
};

export default TaskCardCtr;
