import * as React from 'react';
import * as Excel from 'exceljs';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { Cell } from 'xacmn';
import { selectDropdownOptions } from './excelSlice';

interface ExcelCellEditDropdownProps {
  excelMtdt: Cell;
  className: string;
  val?: Excel.CellValue;
  onBlur?: (value: unknown) => void;
  onFocus?: () => void;
}

const ExcelCellEditDropdown: React.FC<ExcelCellEditDropdownProps> = ({
  excelMtdt,
  className,
  val,
  onBlur,
  onFocus,
}) => {
  const options = useSelector(selectDropdownOptions(excelMtdt.cellAddress));
  return (
    <select
      className={className}
      defaultValue={val as any}
      onChange={onBlur && ((e) => onBlur(e.target.value))}
      onFocus={onFocus}
    >
      {options.map((option) => (
        <option value={option} key={option}>
          {option}
        </option>
      ))}
      <option value={''} key={''} />
    </select>
  );
};

export default ExcelCellEditDropdown;
