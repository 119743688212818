import * as React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useAppDispatch } from '../../app/configureStore';
import { check, ExcelRuntimeCfg, Mode, UICfg, Worksheet } from 'xacmn';
import ExcelSheets from './ExcelSheets';
import { getExcelUIWorkbook, selectExcelWorkbook, change, focus } from './excelSlice';
import { selectSheetsUICfg, toggleSheetDisplayAction, getUICfg, fixUICfg } from './excelUICfgSlice';
import Placeholder from '../general/Placeholder';
import RunCalculate from './RunCalculate';

interface ExcelSheetsCtrProps {
  fileKey: string;
  mode?: Mode;
  readonly?: boolean; // only used in uiCfg mode
}

const ExcelSheetsCtr: React.FC<ExcelSheetsCtrProps> = ({
  fileKey,
  mode = 'dataEntry',
  readonly = false,
}) => {
  const workbook = useSelector(selectExcelWorkbook());
  const [loading, setLoading] = React.useState(true);
  const sheetsUICfg = useSelector(selectSheetsUICfg());
  const dispatch = useAppDispatch();

  const onCellChanged = React.useCallback(
    (cellAddress: string, oldValue: unknown, newValue: unknown) => {
      // eslint-disable-next-line eqeqeq
      if (oldValue != newValue && !(check.isEmpty(newValue) && check.isEmpty(oldValue))) {
        dispatch(change({ cellAddress: cellAddress, value: newValue }));
      }
    },
    [dispatch],
  );

  const onBlur = React.useCallback(() => {
    dispatch(focus(undefined));
  }, [dispatch]);

  const onFocus = React.useCallback(
    (cellAddress) => {
      dispatch(focus(cellAddress));
    },
    [dispatch],
  );

  const onToggleSheetDisplay = React.useCallback(
    (name: string, sheet: Worksheet) => dispatch(toggleSheetDisplayAction({ name, sheet })),
    [dispatch],
  );

  React.useEffect(() => {
    let gone = false;

    dispatch(getExcelUIWorkbook({ fileKey, mode }))
      .unwrap()
      .then((rtCfg: ExcelRuntimeCfg | null) => {
        if (gone) return;
        if (rtCfg) {
          if (mode === 'uiCfg') {
            dispatch(getUICfg(fileKey))
              .unwrap()
              .then((uiCfg: UICfg | null) => {
                if (gone) return;
                dispatch(fixUICfg(rtCfg));
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    return () => {
      gone = true;
    };
  }, [dispatch, fileKey, mode]);

  React.useEffect(() => {
    if ((sheetsUICfg === undefined || sheetsUICfg === null) && workbook?.worksheets?.length) {
      const firstSheet = workbook.worksheets[0];
      dispatch(toggleSheetDisplayAction({ name: firstSheet.name, sheet: firstSheet }));
    }
  }, [dispatch, sheetsUICfg, workbook]);

  return loading ? (
    <Placeholder info={`Loading  ...`} />
  ) : (
    <>
      <RunCalculate fileKey={fileKey} mode={mode} />
      <ExcelSheets
        excelMtdt={workbook}
        onCellChanged={onCellChanged}
        onToggleSheetDisplay={onToggleSheetDisplay}
        sheetsUICfg={mode === 'uiCfg' ? sheetsUICfg : undefined}
        mode={mode}
        readonly={readonly}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </>
  );
};

export default ExcelSheetsCtr;
