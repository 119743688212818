import * as React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useAppDispatch } from '../../app/configureStore';
import { useParams } from 'react-router-dom';
import { getTask, selectFileKey } from './taskSlice';
import ExcelSheetsCtr from '../excel/ExcelSheetsCtr';
import Placeholder from '../general/Placeholder';
import { changeAll } from '../excel/excelSlice';
import { Base64 } from 'js-base64';

const TaskCtr: React.FC = () => {
  const fileKey = useSelector(selectFileKey());
  const [taskLoading, setTaskLoading] = React.useState(false);
  const { assignmentId, encodedFileKeyNew } = useParams();
  const dispatch = useAppDispatch();
  const gone = React.useRef(false);

  React.useEffect(() => {
    return () => {
      gone.current = true;
    };
  }, []);

  React.useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (assignmentId && assignmentId != '0') {
      setTaskLoading(true);
      dispatch(getTask(assignmentId)).finally(() => {
        if (gone.current) return;
        setTaskLoading(false);
      });
    } else {
      dispatch(changeAll({}));
    }
  }, [dispatch, assignmentId]);

  // eslint-disable-next-line eqeqeq
  if (encodedFileKeyNew && encodedFileKeyNew != '0') {
    const fileKeyNew = Base64.decode(encodedFileKeyNew);

    return <ExcelSheetsCtr fileKey={fileKeyNew} />;
  } else {
    if (taskLoading) {
      return <Placeholder info={`Loading your task ...`} />;
    } else {
      if (fileKey) {
        return <ExcelSheetsCtr fileKey={fileKey} />;
      } else {
        return <Placeholder info="no task loaded" />;
      }
    }
  }
};

export default TaskCtr;
