import React from 'react';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { brown } from '@material-ui/core/colors';
import { NewTaskUserData, Task, TaskReassignParams, TaskToCreate, TaskUser } from 'xacmn';
import TaskUsersMenu from './TaskUsersMenu';
import { useAppDispatch } from '../../app/configureStore';
import { assignTask } from '../task/taskSlice';
import { notify } from '../general/generalSlice';
import { isExistingTask } from '../../utils';

interface TaskCardAssigneeProps {
  task: Task | undefined | TaskToCreate;
  assignedName: string;
  handleListKeyDown: (
    setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>,
  ) => (event: React.KeyboardEvent) => void;
  handleToggle: (setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>) => () => void;
  handleClose: (
    setOpenFlag: React.Dispatch<React.SetStateAction<boolean>>,
    ref: React.RefObject<HTMLElement>,
  ) => (event: React.MouseEvent<EventTarget>) => void;
  saveTaskField: (newTask: Partial<NewTaskUserData>) => Promise<void>;
}

const TaskCardAssignee: React.FC<TaskCardAssigneeProps> = ({
  task,
  assignedName,
  handleListKeyDown,
  handleToggle,
  handleClose,
  saveTaskField,
}) => {
  const dispatch = useAppDispatch();
  const [openAssignees, setOpenAssignees] = React.useState(false);
  const assigneeRef = React.useRef<HTMLDivElement>(null);

  const changeUser = React.useCallback(
    (newUser: TaskUser) => {
      if (!isExistingTask(task)) {
        saveTaskField({ assignedToSub: newUser.sub });
        return;
      }
      const params: TaskReassignParams = {
        assignmentId: task.assignmentId,
        assignedToSub: newUser.sub,
      };
      dispatch(assignTask(params)).then((fulfilledAction) => {
        if (fulfilledAction?.payload) {
          dispatch(
            notify({
              variant: 'success',
              message: `Task has been successfully assign to ${newUser.name}`,
            }),
          );
        } else {
          dispatch(
            notify({
              variant: 'error',
              message: 'Task assignment failed.',
            }),
          );
        }
      });
    },
    [dispatch, saveTaskField, task],
  );

  return (
    <>
      <div
        style={{ display: 'inline', float: 'right' }}
        ref={assigneeRef}
        onClick={task ? handleToggle(setOpenAssignees) : undefined}
      >
        <Chip
          style={{ float: 'right', backgroundColor: brown[200], cursor: 'pointer' }}
          size="small"
          icon={<FaceIcon />}
          label={
            !task ? (
              'assigned'
            ) : assignedName === '__loading__' ? (
              <CircularProgress size={16} style={{ color: brown[100], marginTop: '2px' }} />
            ) : (
              assignedName
            )
          }
        />
      </div>
      {task && (
        <Popper
          open={openAssignees}
          anchorEl={assigneeRef.current}
          role={undefined}
          transition
          placement="bottom-end"
        >
          {({ TransitionProps }) => (
            <Paper>
              <ClickAwayListener onClickAway={handleClose(setOpenAssignees, assigneeRef)}>
                <div>
                  <TaskUsersMenu
                    task={task}
                    handleListKeyDown={handleListKeyDown(setOpenAssignees)}
                    handleClose={handleClose(setOpenAssignees, assigneeRef)}
                    changeUser={changeUser}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          )}
        </Popper>
      )}
    </>
  );
};

export default TaskCardAssignee;
