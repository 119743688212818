import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Checkbox from '@material-ui/core/Checkbox';
import { Scrollbars } from 'react-custom-scrollbars';
import _ from 'lodash';
import { Workbook, SheetsUICfg, OnCellChangedEventHandler, Mode, Worksheet } from 'xacmn';
import ExcelZone from './ExcelZone';
import Placeholder from '../general/Placeholder';

interface ExcelSheetsProps {
  excelMtdt?: Workbook | null;
  sheetsUICfg?: SheetsUICfg | null;
  onCellChanged: OnCellChangedEventHandler;
  onToggleSheetDisplay: (name: string, sheet: Worksheet) => void;
  mode: Mode;
  readonly?: boolean; // only used for uiCfg mode
  onBlur?: () => void;
  onFocus?: (cellAddress: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      flex: '0 0 48px',
    },
    excelContent: {
      margin: '8px 0 0 8px',
      flex: 1,
    },
  }),
);

function getSheets(excelMtdt: Workbook) {
  return excelMtdt.worksheets.map((ws) => ws.name);
}

const ExcelSheets: React.FC<ExcelSheetsProps> = ({
  excelMtdt,
  sheetsUICfg,
  onCellChanged,
  onToggleSheetDisplay,
  mode,
  readonly = false,
  onBlur,
  onFocus,
}) => {
  const classes = useStyles();
  const [currentSheet, setCurrentSheet] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentSheet(newValue);
  };

  const handleToggleSheetDisplay =
    (name: string, sheet: Worksheet) => (event: React.ChangeEvent<{}>, checked: boolean) => {
      onToggleSheetDisplay(name, sheet);
    };

  let cpnt;

  if (excelMtdt) {
    if (excelMtdt.worksheets.length === 0) {
      return <Placeholder info="No sheets selected" />;
    }
    const sheets = getSheets(excelMtdt);
    const sheet = excelMtdt.worksheets[currentSheet];
    const sheetUICfg = _.get(sheetsUICfg, sheet.name);
    cpnt = (
      <div className={classes.root}>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            value={currentSheet}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {sheets.map((label, index) => {
              let tabLabel: React.ReactElement | string;
              if (mode === 'uiCfg') {
                tabLabel = (
                  <div>
                    <Checkbox
                      disabled={readonly}
                      checked={!!_.get(sheetsUICfg, [label, 'display'])}
                      onChange={handleToggleSheetDisplay(label, excelMtdt.worksheets[index])}
                      id={`checkbox-${label}-${index}`}
                      color="primary"
                    />
                    <span>{label}</span>
                  </div>
                );
              } else {
                tabLabel = label;
              }
              return <Tab label={tabLabel} id={`${label}-${index}`} key={`${label}-${index}`} />;
            })}
          </Tabs>
        </AppBar>
        <div className={classes.excelContent}>
          <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
            <ExcelZone
              excelMtdt={sheet}
              sheetUICfg={sheetUICfg}
              onCellChanged={onCellChanged}
              mode={mode}
              readonly={readonly}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          </Scrollbars>
        </div>
      </div>
    );
  } else {
    cpnt = <Placeholder info="Loading..." />;
  }

  return cpnt;
};

export default ExcelSheets;
