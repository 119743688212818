import * as React from 'react';
import * as Excel from 'exceljs';
import { Cell } from 'xacmn';
import { getCellValueDisplay, getFontStyle } from './excelUtil';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ExcelStaticValueView from './ExcelStaticValueView';

interface ExcelCellStaticProps {
  excelMtdt: Cell;
  val: Excel.CellValue;
}

const ExcelCellStatic: React.FC<ExcelCellStaticProps> = ({ excelMtdt, val }) => {
  const valDisplay: string = getCellValueDisplay(val, excelMtdt);

  const style: CSSProperties = getFontStyle(excelMtdt);

  return <ExcelStaticValueView value={valDisplay} style={style} />;
};

export default ExcelCellStatic;
