import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  FlatTmplVer,
  getErrorMessageOfError,
  getErrorMessageOfResponse,
  parseFileKey,
  Tmpl,
  TmplToSave,
  WsMsgClient,
  WsMsgUIEl,
} from 'xacmn';
import { State } from '../../app/configureStore';
import { wait, finishWaiting, notify } from '../general/generalSlice';
import { newTrack } from '../wsmsg/wsmsgSlice';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { getLoggedInUserInfo } from '../../utils';
import { apiUrl } from '../../config';
import { Base64 } from 'js-base64';

export interface TplState {
  tmpls: Tmpl[] | null;
  tmpl: Tmpl | null | undefined;
}

const initialState: TplState = {
  tmpls: null,
  tmpl: null,
};

// TODO: change to own method
export const getTmpls = createAsyncThunk('tpl/getTmpls', async (arg, thunkAPI) => {
  const server = apiUrl;
  const path = '/admin';

  const token = sessionStorage.getItem('id_token');
  const res = await fetch(`${server}${path}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'getTmpls',
    }),
  });
  if (res.status !== 200) {
    if (res.status === 404) {
      thunkAPI.dispatch(
        notify({ variant: 'info', message: 'You have not created any template yet.' }),
      );
    } else {
      const errMsg = await getErrorMessageOfResponse(res);
      thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
    }
    return [];
  }
  const tmpls = await res.json();
  return tmpls as Tmpl[];
});

export const saveTmpl = createAsyncThunk('tpl/saveTmpl', async (tmpl: TmplToSave, thunkAPI) => {
  const server = apiUrl;
  const path = '/admin';

  const token = sessionStorage.getItem('id_token');
  const res = await fetch(`${server}${path}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'saveTmpl',
      data: tmpl,
    }),
  });
  if (res.status !== 200) {
    const errMsg = await getErrorMessageOfResponse(res);
    throw new Error(errMsg);
  }
  return await res.json();
});

export const fetchTmpl = async (tmplId: string) => {
  const server = apiUrl;
  const pathTmpl = '/admin';

  const token = sessionStorage.getItem('id_token');
  const resTmpl = await fetch(`${server}${pathTmpl}?`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      // TODO: user Bearer
      Authorization: `${token}`,
    },
    body: JSON.stringify({
      action: 'getTmpl',
      data: {
        tmplId,
      },
    }),
  });
  if (resTmpl.status !== 200) {
    const errMsg = await getErrorMessageOfResponse(resTmpl);
    throw new Error(errMsg);
  }
  const tmpl = (await resTmpl.json()) as Tmpl;

  return tmpl;
};

export const getTmpl = createAsyncThunk('tpl/getTmpl', async (tmplId: string, thunkAPI) => {
  try {
    const tmpl = await fetchTmpl(tmplId);
    return tmpl;
  } catch (err) {
    thunkAPI.dispatch(notify({ variant: 'error', message: getErrorMessageOfError(err) }));
    return undefined;
  }
});

export const uploadTmpl = createAsyncThunk(
  'tpl/uploadTmpl',
  async (ulTplParm: { selectedFile?: File; tmplId?: string }, thunkAPI) => {
    thunkAPI.dispatch(wait());
    try {
      if (!ulTplParm.selectedFile) return;

      const server = apiUrl;
      const path = '/admin';

      const token = sessionStorage.getItem('id_token');
      const res = await fetch(`${server}${path} `, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // TODO: user Bearer
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          action: 'getTmplUplUrl',
          data: {
            fileName: ulTplParm.selectedFile.name,
            contentType: ulTplParm.selectedFile.type,
            ...(ulTplParm.tmplId ? { tmplId: ulTplParm.tmplId } : {}),
          },
        }),
      });
      if (res.status !== 200) {
        throw new Error(await getErrorMessageOfResponse(res));
      }
      const uploadInfo: {
        uploadURL: string;
        Key: string;
      } = await res.json();

      await fetch(uploadInfo.uploadURL, {
        method: 'PUT',
        headers: {
          'Content-Type': ulTplParm.selectedFile.type,
        },
        body: ulTplParm.selectedFile,
      });

      const state = thunkAPI.getState() as State;
      const existingTmpl = state.tpl.tmpls?.find((tmpl) => tmpl.tmplId === ulTplParm.tmplId);
      const { tmplId, fileId, fileName } = parseFileKey(uploadInfo.Key);
      const tmpl: TmplToSave = {
        tmplId,
        tmplName: existingTmpl?.tmplName ?? fileName,
        fileId,
        fileName,
      };
      const savedTmpl: Tmpl = await thunkAPI.dispatch(saveTmpl(tmpl)).unwrap();

      const wsMsgClient: WsMsgClient = {
        uiEl: WsMsgUIEl.Global,
        apiName: 'genRawUI',
        trackId: nanoid(),
        sub: getLoggedInUserInfo()?.sub || '',
      };
      await fetch(`${server}${path} `, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // TODO: user Bearer
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          action: 'genRawUI',
          data: uploadInfo.Key,
          meta: wsMsgClient,
        }),
      });
      if (res.status !== 200) {
        throw new Error(await getErrorMessageOfResponse(res));
      }
      thunkAPI.dispatch(newTrack({ ...wsMsgClient, message: 'generating raw UI from Excel file' }));

      return {
        encodedKey: Base64.encodeURI(uploadInfo.Key),
        trackId: wsMsgClient.trackId,
        tmpl: savedTmpl,
      };
    } catch (err) {
      thunkAPI.dispatch(notify({ variant: 'error', message: getErrorMessageOfError(err) }));
      return undefined;
    } finally {
      thunkAPI.dispatch(finishWaiting());
    }
  },
);

export const downloadExcel = createAsyncThunk(
  'tpl/downloadExcel',
  async ({ fileKey }: { fileKey: string }, thunkAPI) => {
    thunkAPI.dispatch(wait());
    try {
      const server = apiUrl;
      const path = '/admin';

      const params: Record<string, string> = {
        ef: fileKey,
      };

      const token = sessionStorage.getItem('id_token');
      const res = await fetch(`${server}${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // TODO: user Bearer
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          action: 'getExcelTmplDlUrl',
          data: params,
        }),
      });
      if (res.status !== 200) {
        const errMsg = await getErrorMessageOfResponse(res);
        thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
        return;
      }

      const downloadUrl = await res.json();

      // Trigger the download using the signed URL
      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      try {
        downloadLink.click();
      } finally {
        document.body.removeChild(downloadLink);
      }
    } finally {
      thunkAPI.dispatch(finishWaiting());
    }
  },
);

export const tplSlice = createSlice({
  name: 'tpl',
  initialState,
  reducers: {
    setTplName: (state, action: PayloadAction<string>) => {
      if (!state.tmpl) return;
      state.tmpl = {
        ...state.tmpl,
        tmplName: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTmpls.fulfilled, (state, action) => {
      state.tmpls = action.payload;
    });
    builder.addCase(getTmpl.fulfilled, (state, action) => {
      state.tmpl = action.payload;
    });
  },
});

export const { setTplName } = tplSlice.actions;

export default tplSlice.reducer;

export const selectTmpl = () => (state: State) => {
  return state.tpl.tmpl;
};

export const selectTmpls = () => (state: State) => {
  return state.tpl.tmpls;
};

export const selectTmplFiles =
  (showArchived: boolean = true) =>
  (state: State) => {
    const tmpls = state.tpl.tmpls;
    const flatTmplVer = tmpls
      ?.reduce((soFar, tmpl) => {
        if (!showArchived && tmpl.tmplStatus === 'archived') return soFar;
        const lastFileId = tmpl.effFiles[tmpl.effFiles.length - 1].fileId;
        const lastFile = tmpl.filesArc[lastFileId];
        return soFar.concat({
          ...lastFile,
          ..._.pick(tmpl, ['tenantName', 'tmplId', 'tmplName', 'tmplStatus']),
        });
      }, [] as FlatTmplVer[])
      .map((flatTmplVer) => {
        flatTmplVer.tmplStatus = flatTmplVer.tmplStatus || 'published';
        flatTmplVer.uplAt = flatTmplVer.uplAt
          ? new Date(flatTmplVer.uplAt).toLocaleDateString()
          : 'N/A';
        return flatTmplVer;
      });
    return flatTmplVer;
  };
