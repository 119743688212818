import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CircularProgress from '@material-ui/core/CircularProgress';
import grey from '@material-ui/core/colors/grey';
import { Task, TaskToCreate, NewTaskUserData } from 'xacmn';
import styles from './TaskCard.module.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      cursor: 'text',
    },
    titleEditor: {
      fontSize: '1rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      outline: 'none',
      resize: 'none',
      width: '100%',
    },
    progress: {
      color: grey[500],
    },
  }),
);

interface TaskCardTitleProps {
  task: Task | undefined | TaskToCreate;
  saveTaskField: (newTask: Partial<NewTaskUserData>) => Promise<void>;
}

const TaskCardTitle: React.FC<TaskCardTitleProps> = ({ task, saveTaskField }) => {
  const classes = useStyles();
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [progressSize, setProgressSize] = React.useState(40);
  const taRef = React.useRef<HTMLTextAreaElement>(null);

  const saveTitle = (_newTitle: Task['title']) => {
    setEditing(false);
    const newTitle = _newTitle.trim();
    if (!newTitle) return;
    if (newTitle !== task?.title) {
      if (taRef.current) {
        setProgressSize(taRef.current.clientHeight * 0.6);
      }
      setSaving(true);
      saveTaskField({ title: newTitle }).finally(() => {
        setSaving(false);
      });
    }
  };

  return editing || saving ? (
    <div className={styles.editorContainer}>
      <TextareaAutosize
        className={classes.titleEditor}
        ref={taRef}
        aria-label="task title"
        placeholder="title"
        defaultValue={task?.title || ''}
        onBlur={saving ? undefined : (e) => saveTitle(e.target.value as string)}
      />
      {saving ? (
        <div className={styles.editorProgressContainer}>
          <CircularProgress className={classes.progress} size={progressSize} />
        </div>
      ) : null}
    </div>
  ) : (
    <Typography
      gutterBottom
      variant="subtitle1"
      component="h2"
      className={classes.title}
      onClick={() => {
        setEditing(true);
        setTimeout(() => {
          if (taRef.current) {
            taRef.current.focus();
            taRef.current.select();
          }
        }, 1);
      }}
    >
      {task?.title || 'task not created yet'}
    </Typography>
  );
};

export default TaskCardTitle;
