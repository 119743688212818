export const dummyContext = {
  callbackWaitsForEmptyEventLoop: false,
  functionName: 'dummyFn',
  functionVersion: '1',
  invokedFunctionArn:
    'arn:aws:lambda:us-east-2:123456789012:function:my-function:1',
  memoryLimitInMB: '256',
  awsRequestId: 'dummyId',
  logGroupName: 'dummyLogGroup',
  logStreamName: 'dummyLogStream',
  getRemainingTimeInMillis: () => {
    return 5000;
  },
  done: (error?: Error, result?: any) => {},
  fail: (error: Error | string) => {},
  succeed: (messageOrObject: any, object?: any) => {},
};
