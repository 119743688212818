import CSS from 'csstype';
import * as React from 'react';
import {
  Worksheet,
  UILytRow,
  UILytColumn,
  OnCellChangedEventHandler,
  Mode,
  SheetUICfg,
} from 'xacmn';
import ExcelCellCtr, { ExcelCellCtrProps } from './ExcelCellCtr';
import { useAppSelector as useSelector } from '../../app/configureStore';
// import { getRandColor } from '../../utils/color';
import './ExcelZone.css';
import { getCellStyle, getHiddenAreas, isBackgroundOnly } from './excelUtil';
import { selectFocusedCell } from './excelSlice';
import ExcelCellEditOverlayCtr from './ExcelCellEditOverlayCtr';

interface ExcelZoneProps {
  excelMtdt: string | Worksheet;
  sheetUICfg?: SheetUICfg;
  onCellChanged: OnCellChangedEventHandler;
  mode: Mode;
  readonly: boolean; // only used in uiCfg mode
  onBlur?: () => void;
  onFocus?: (cellAddress: string) => void;
}

interface UILytRowsProps {
  excelMtdt: UILytRow[];
  onCellChanged: OnCellChangedEventHandler;
  mode: Mode;
  widthPx?: number;
  readonly: boolean; // only used in uiCfg mode
  onBlur?: () => void;
  onFocus?: (cellAddress: string) => void;
  left: number;
  top: number;
}

interface UILytColumnsProps {
  excelMtdt: UILytColumn[];
  onCellChanged: OnCellChangedEventHandler;
  mode: Mode;
  heightPx?: number;
  readonly: boolean; // only used in uiCfg mode
  onBlur?: () => void;
  onFocus?: (cellAddress: string) => void;
  left: number;
  top: number;
}

interface ExcelUnitProps {
  unit: UILytRow | UILytColumn;
  onCellChanged: OnCellChangedEventHandler;
  rowOrCol: 'row' | 'col';
  mode: Mode;
  heightPx?: number;
  widthPx?: number;
  readonly: boolean; // only used in uiCfg mode
  onBlur?: () => void;
  onFocus?: (cellAddress: string) => void;
  left: number;
  top: number;
}

const ExcelUnit = ({
  unit,
  onCellChanged,
  rowOrCol,
  mode,
  heightPx,
  widthPx,
  readonly,
  onBlur,
  onFocus,
  left,
  top,
}: ExcelUnitProps): React.ReactElement | null => {
  const focusedCell = useSelector(selectFocusedCell());

  const onExcelUnitFocus = React.useCallback(() => {
    if (onFocus && unit.cell?.cellAddress) onFocus(unit.cell.cellAddress);
  }, [onFocus, unit.cell?.cellAddress]);

  let innerCpnt: React.ReactElement | null = null;

  let row: UILytRow | undefined = undefined;
  let col: UILytColumn | undefined = undefined;
  if (rowOrCol === 'row') {
    row = unit as UILytRow;
    if (row.columns) {
      innerCpnt = UILytColumns({
        excelMtdt: row.columns,
        onCellChanged,
        mode,
        heightPx: row.heightPx,
        readonly,
        onBlur,
        onFocus,
        left,
        top,
      });
    }
  } else {
    // col
    col = unit as UILytColumn;
    if (col.rows) {
      innerCpnt = UILytRows({
        excelMtdt: col.rows,
        onCellChanged,
        mode,
        widthPx: col.widthPx,
        readonly,
        onBlur,
        onFocus,
        left,
        top,
      });
    }
  }

  let cellUnitStyle: CSS.Properties = {};
  if (!innerCpnt && unit.cell) {
    if (isBackgroundOnly(unit.cell)) {
      innerCpnt = null;
    } else {
      const props: ExcelCellCtrProps = {
        excelMtdt: unit.cell,
        onCellChanged,
        onBlur,
        onFocus: onExcelUnitFocus,
      };
      innerCpnt = <ExcelCellCtr {...props} />;
    }
    cellUnitStyle = {
      gridColumn: `${unit.left - left + 1} / ${unit.right - left + 1 + 1}`,
      gridRow: `${unit.top - top + 1} / ${unit.bottom - top + 1 + 1}`,
      ...getCellStyle(unit.cell, focusedCell === unit.cell.cellAddress),
    };
  }

  return unit.cell ? (
    <div
      key={`${unit.left}-${unit.top}-${unit.right}-${unit.bottom}`}
      style={cellUnitStyle}
      className="xa-cell"
    >
      {innerCpnt}
      {mode === 'uiCfg' && !readonly && unit.cell ? (
        <ExcelCellEditOverlayCtr
          cell={unit.cell}
          width={rowOrCol === 'row' ? `${widthPx || 0}px` : `${col?.widthPx || 0}px`}
          height={rowOrCol === 'col' ? `${heightPx || 0}px` : `${row?.heightPx || 0}px`}
        />
      ) : null}
    </div>
  ) : (
    innerCpnt
  );
};

const UILytRows: React.FC<UILytRowsProps> = ({
  excelMtdt,
  onCellChanged,
  mode,
  widthPx,
  readonly,
  onBlur,
  onFocus,
  left,
  top,
}) => {
  return (
    <>
      {excelMtdt.map((row) => {
        return (
          <ExcelUnit
            unit={row}
            onCellChanged={onCellChanged}
            rowOrCol="row"
            mode={mode}
            widthPx={widthPx}
            key={`${row.top}-${row.left}`}
            readonly={readonly}
            onBlur={onBlur}
            onFocus={onFocus}
            left={left}
            top={top}
          />
        );
      })}
    </>
  );
};

const UILytColumns: React.FC<UILytColumnsProps> = ({
  excelMtdt,
  onCellChanged,
  mode,
  heightPx,
  readonly,
  onBlur,
  onFocus,
  left,
  top,
}) => {
  return (
    <>
      {excelMtdt.map((col) => {
        return (
          <ExcelUnit
            unit={col}
            onCellChanged={onCellChanged}
            rowOrCol="col"
            mode={mode}
            heightPx={heightPx}
            key={`${col.top}-${col.left}`}
            readonly={readonly}
            onBlur={onBlur}
            onFocus={onFocus}
            left={left}
            top={top}
          />
        );
      })}
    </>
  );
};

const ExcelZone: React.FC<ExcelZoneProps> = ({
  excelMtdt,
  sheetUICfg,
  onCellChanged,
  mode,
  readonly = false,
  onBlur,
  onFocus,
}) => {
  if (typeof excelMtdt === 'string') {
    // TODO: display loading...
    return <div>{excelMtdt}</div>;
  }

  const loc = { ...excelMtdt.loc, ...sheetUICfg?.loc };

  const hiddenAreas = mode === 'uiCfg' ? getHiddenAreas(excelMtdt, loc) : null;
  const gridTemplateColumns = excelMtdt.colsWidthPx.map((col) => `${col.w}px`).join(' ');
  const gridTemplateRows = excelMtdt.rowsHeightPx.map((row) => `${row.h}px`).join(' ');
  const styleCells = {
    gridTemplateColumns,
    gridTemplateRows,
    display: 'grid',
  };

  return (
    <div>
      <div>
        <div style={styleCells}>
          <UILytRows
            excelMtdt={excelMtdt.lyt}
            onCellChanged={onCellChanged}
            mode={mode}
            readonly={readonly}
            onBlur={onBlur}
            onFocus={onFocus}
            left={excelMtdt.loc.left}
            top={excelMtdt.loc.top}
          />
        </div>
      </div>
      {hiddenAreas ? (
        <>
          <div className="xa-hidden-cells" style={hiddenAreas.left} />
          <div className="xa-hidden-cells" style={hiddenAreas.right} />
          <div className="xa-hidden-cells" style={hiddenAreas.top} />
          <div className="xa-hidden-cells" style={hiddenAreas.bottom} />
        </>
      ) : null}
    </div>
  );
};

export default ExcelZone;
