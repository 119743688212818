import React from 'react';
import { useLocation } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Scrollbars } from 'react-custom-scrollbars';
import Template from './Template';
import RSB from './TplRSBRoutes';
import TemplateBtnBar from './TplBtnBar';
import { useFrameStyles } from '../general/utils';

const Frame: React.FC = () => {
  const classes = useFrameStyles();
  const location = useLocation();

  return (
    <>
      <div className={classes.contentLeft}>
        <div className={classes.contentMain}>
          <Template />
        </div>
        <Paper className={classes.buttonBar} square elevation={0}>
          <TemplateBtnBar />
        </Paper>
      </div>

      {location.pathname.endsWith('template') ? null : (
        <Paper className={classes.rightSideBar} square elevation={0}>
          <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
            <RSB />
          </Scrollbars>
        </Paper>
      )}
    </>
  );
};

export default Frame;
