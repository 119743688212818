import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import * as _ from 'lodash';
import {
  UICfg,
  CellUICfg,
  Loc,
  OnSetSheetLocArgs,
  TmplInPath,
  WsMsgUIEl,
  WsMsgClient,
  Worksheet,
  getErrorMessageOfResponse,
  ExcelRuntimeCfg,
  colLetterToNum,
  colNumToLetter,
  parseCellAddress,
} from 'xacmn';
import { AppDispatch, State } from '../../app/configureStore';
import { wait, finishWaiting, notify } from '../general/generalSlice';
import { getLoggedInUserInfo } from '../../utils';
import { newTrack } from '../wsmsg/wsmsgSlice';
import { apiUrl } from '../../config';

export interface ExcelUICfgState {
  uiCfg?: UICfg | null;
}

interface ChangeCellUICfgAction {
  type: string;
  payload: {
    cellAddress: string;
    cellUICfg: CellUICfg;
  };
}

interface ToggleCellEditableAction {
  type: string;
  payload: {
    cellAddress: string;
  };
}

interface ToggleSheetDisplayAction {
  type: string;
  payload: {
    name: string;
    sheet: Worksheet;
  };
}

interface SetSheetLocAction {
  type: string;
  payload: OnSetSheetLocArgs;
}

const initialState: ExcelUICfgState = { uiCfg: null };

function getUICfgErrorMsg(uiCfg: UICfg | undefined | null): string | undefined {
  if (!Object.keys(uiCfg?.sheets || {}).filter((k) => uiCfg?.sheets?.[k].display).length) {
    return 'Please select at least one sheet.';
  }
  return undefined;
}

export const saveUICfg = createAsyncThunk<
  string,
  TmplInPath,
  { state: State; dispatch: AppDispatch }
>('uicfg/saveUICfg', async (tmplInPath: TmplInPath, thunkAPI) => {
  const errMsg = getUICfgErrorMsg(thunkAPI.getState().excelUICfg.uiCfg);
  if (errMsg) {
    throw new Error(errMsg);
  }
  thunkAPI.dispatch(wait());
  const token = sessionStorage.getItem('id_token');
  try {
    const uiCfg = selectUICfg()(thunkAPI.getState() as State);
    const wsMsgClient: WsMsgClient = {
      uiEl: WsMsgUIEl.Global,
      apiName: 'saveUICfg',
      trackId: nanoid(),
      sub: getLoggedInUserInfo()?.sub || '',
    };
    const server = apiUrl;
    const path = '/admin';

    const res = await fetch(`${server}${path}?`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'saveUICfg',
        data: { uiCfg, where: tmplInPath },
        meta: wsMsgClient,
      }),
    });
    if (res.status !== 200) {
      const errMsg = `Save UICfg failed - ${await getErrorMessageOfResponse(res)}`;
      throw new Error(errMsg);
    }
    thunkAPI.dispatch(newTrack({ ...wsMsgClient, message: 'generating UI from configuration' }));
    return wsMsgClient.trackId;
  } finally {
    thunkAPI.dispatch(finishWaiting());
  }
});

export const getUICfg = createAsyncThunk('uicfg/getUICfg', async (fileKey: string, thunkAPI) => {
  thunkAPI.dispatch(wait());
  try {
    const server = apiUrl;
    const path = '/admin';

    const params: Record<string, string> = {
      ef: fileKey,
    };

    const token = sessionStorage.getItem('id_token');
    const res = await fetch(`${server}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // TODO: user Bearer
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        action: 'getUiCfgDlUrl',
        data: params,
      }),
    });
    if (res.status !== 200) {
      const errMsg = await getErrorMessageOfResponse(res);
      thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
      return null;
    }

    const downloadUrl = await res.json();
    const dataRes = await fetch(downloadUrl);
    // when first entering config page, there is no uiCfg yet
    if (dataRes.status === 404) {
      return null;
    }
    if (dataRes.status !== 200) {
      const errMsg = await getErrorMessageOfResponse(res);
      thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
      return null;
    }
    const uiCfg: UICfg = await dataRes.json();
    return uiCfg;
  } finally {
    thunkAPI.dispatch(finishWaiting());
  }
});

export const excelUICfgSlice = createSlice({
  name: 'excelUICfg',
  initialState,
  reducers: {
    changeCellUICfg: (state, action: ChangeCellUICfgAction) => {
      _.set(state, ['uiCfg', 'cells', action.payload.cellAddress], action.payload.cellUICfg);
    },
    toggleCellEditable: (state, action: ToggleCellEditableAction) => {
      const newEditable = !state.uiCfg?.cells?.[action.payload.cellAddress]?.editable;
      _.set(state, ['uiCfg', 'cells', action.payload.cellAddress], {
        ...state.uiCfg?.cells?.[action.payload.cellAddress],
        editable: newEditable,
      });
    },
    toggleSheetDisplayAction: (state, action: ToggleSheetDisplayAction) => {
      const newDisplay = !state.uiCfg?.sheets?.[action.payload.name]?.display;
      const uiCfgLoc = state.uiCfg?.sheets?.[action.payload.name]?.loc;
      _.set(state, ['uiCfg', 'sheets', action.payload.name], {
        ...state.uiCfg?.sheets?.[action.payload.name],
        display: newDisplay,
        loc: uiCfgLoc || action.payload.sheet.loc,
      });
    },
    setSheetLoc: (state, action: SetSheetLocAction) => {
      _.set(state, ['uiCfg', 'sheets', action.payload.sheetName, 'loc'], {
        ...state.uiCfg?.sheets?.[action.payload.sheetName]?.loc,
        left: action.payload.left,
        top: action.payload.top,
        right: action.payload.right,
        bottom: action.payload.bottom,
      });
    },
    setUICfg: (state, action: PayloadAction<UICfg>) => {
      state.uiCfg = action.payload;
    },
    fixUICfg: (state, action: PayloadAction<ExcelRuntimeCfg>) => {
      const uiCfg = state.uiCfg;
      if (!uiCfg) {
        return;
      }
      const workbook = action.payload.workbook;

      const uiCfgSheets = uiCfg.sheets;
      if (uiCfgSheets) {
        Object.keys(uiCfgSheets).forEach((sheetName) => {
          const sheet = workbook.worksheets.find((ws) => ws.name === sheetName);
          if (!sheet) {
            delete uiCfgSheets[sheetName];
          } else {
            // check whether loc in UI config is valid; if not, shrink it to fit the sheet
            const uiCfgSheet = uiCfgSheets[sheetName];
            if (uiCfgSheet) {
              const loc = uiCfgSheet.loc;
              if (loc) {
                const newLoc = { ...loc };
                if (newLoc.left < sheet.loc.left) {
                  newLoc.left = sheet.loc.left;
                }
                if (newLoc.top < sheet.loc.top) {
                  newLoc.top = sheet.loc.top;
                }
                if (newLoc.right > sheet.loc.right) {
                  newLoc.right = sheet.loc.right;
                }
                if (newLoc.bottom > sheet.loc.bottom) {
                  newLoc.bottom = sheet.loc.bottom;
                }
                if (
                  newLoc.left !== loc.left ||
                  newLoc.top !== loc.top ||
                  newLoc.right !== loc.right ||
                  newLoc.bottom !== loc.bottom
                ) {
                  uiCfgSheet.loc = newLoc;
                }
              }
            }
          }
        });
      }

      const cells = uiCfg.cells;
      if (cells) {
        const sheetNames = workbook.worksheets.map((sheet) => sheet.name);
        Object.keys(cells).forEach((cellAddress) => {
          const cellUICfg = cells[cellAddress];
          const cellFullAddress = parseCellAddress(cellAddress);
          if (cellFullAddress.sheetName && !sheetNames.includes(cellFullAddress.sheetName)) {
            delete cells[cellAddress];
            return;
          }
          const sheet = workbook.worksheets.find(
            (ws) => ws.name === cellFullAddress.sheetName,
          ) as Worksheet;
          let col: number = 1;
          if (cellFullAddress.col) {
            col = colLetterToNum(cellFullAddress.col);
          }
          if (col < sheet.loc.left || col > sheet.loc.right) {
            delete cells[cellAddress];
            return;
          }
          let row: number = cellFullAddress.row || 1;
          if (row < sheet.loc.top || row > sheet.loc.bottom) {
            delete cells[cellAddress];
            return;
          }
          const newCellAddress = `'[${workbook.name.toUpperCase()}]${
            cellFullAddress.sheetName
          }'!${colNumToLetter(col)}${row}`;
          if (cellAddress !== newCellAddress) {
            cells[newCellAddress] = cellUICfg;
            delete cells[cellAddress];
          }
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUICfg.fulfilled, (state, action) => {
      state.uiCfg = action.payload;
    });
  },
});

export const {
  changeCellUICfg,
  toggleCellEditable,
  toggleSheetDisplayAction,
  setSheetLoc,
  setUICfg,
  fixUICfg,
} = excelUICfgSlice.actions;

export default excelUICfgSlice.reducer;

export const selectUICfg = () => (state: State) => {
  return state?.excelUICfg.uiCfg;
};

export const selectSheetsUICfg = () => (state: State) => {
  return state?.excelUICfg.uiCfg?.sheets;
};

export const selectCellUICfg = (cellAddress: string) => (state: State) => {
  return state?.excelUICfg.uiCfg?.cells?.[cellAddress];
};

export const selectLoc =
  (sheetName: string) =>
  (state: State): Loc => {
    const uiCfgLoc = state?.excelUICfg.uiCfg?.sheets?.[sheetName]?.loc;
    const ws = state?.excel?.workbook?.worksheets.find((ws) => ws.name === sheetName);
    const loc = { ...ws?.loc, ...uiCfgLoc };
    function isLoc(_loc: typeof loc): _loc is Loc {
      return !(
        loc.bottom === undefined ||
        loc.top === undefined ||
        loc.left === undefined ||
        loc.right === undefined
      );
    }
    if (!isLoc(loc)) {
      throw new Error(
        `selectLoc cannot select a valid location - some dimensions are undefined - ${JSON.stringify(
          loc,
        )}`,
      );
    }
    return loc;
  };
