import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import TplListBtnBar from './TplListBtnBar';
import TplPreviewBtnBar from './TplPreviewBtnBar';
import TplUICfgBtnBar from './TplUICfgBtnBar';

const TemplateBtnBar: React.FC = () => {
  return (
    <Routes>
      <Route path={'uicfg/:encodedKey'} element={<TplUICfgBtnBar />} />
      <Route path={'preview/:encodedKey'} element={<TplPreviewBtnBar />} />
      <Route path={''} element={<TplListBtnBar />} />
    </Routes>
  );
};

export default TemplateBtnBar;
