import React from 'react';
import styled from 'styled-components';
import { useAppSelector as useSelector } from '../../app/configureStore';
import TaskList from './TaskCardList';
import {
  getUsersForTask,
  selectTask,
  selectTaskTmpl,
  selectTaskUsers,
  selectTransTasks,
} from './taskSlice';
import CalculatingCtr from '../excel/CalculatingCtr';
import TaskSummaryCard from '../OA/TaskCardCtr';
import { useAppDispatch } from '../../app/configureStore';
import { Divider, Typography } from '@material-ui/core';

const Tmpl = styled.div`
  height: 24px;
  margin-bottom: 8px;
`;

const TmplDivider = styled(Divider)`
  margin-bottom: 8px;
`;

export default function TaskRSB() {
  const dispatch = useAppDispatch();
  const mainTask = useSelector(selectTask());
  const allTasks = useSelector(selectTransTasks());
  const taskUsers = useSelector(selectTaskUsers());
  const tmpl = useSelector(selectTaskTmpl());
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!taskUsers) {
      setLoading(true);
      dispatch(getUsersForTask()).finally(() => setLoading(false));
    }
  }, [dispatch, taskUsers]);

  return (
    <>
      <CalculatingCtr />
      <Tmpl>
        <Typography variant="h6" color="textSecondary">
          {tmpl?.tmplName}
        </Typography>
      </Tmpl>
      <TmplDivider />
      {mainTask && (
        <>
          <div>
            <TaskSummaryCard task={mainTask} loadingName={loading} />
          </div>
          <TaskList tasks={allTasks || []} loadingName={loading} />
        </>
      )}
    </>
  );
}
