import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import Frame from './Frame';

const TenantRoute: React.FC = () => {
  return (
    <Routes>
      <Route path="/:account/*" element={<Frame />} />
    </Routes>
  );
};

export default TenantRoute;
