import React from 'react';
import styled from 'styled-components';
import { Base64 } from 'js-base64';
import IconButton from '@material-ui/core/IconButton';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useAppDispatch } from '../../app/configureStore';
import { downloadExcel, getTmpls, selectTmplFiles } from './tplSlice';
import { FlatTmplVer } from 'xacmn';
import { HeadCell } from '../general/TabledList';
import TabledList from '../general/TabledList';
import { composeFileKey } from 'xacmn';
import TplStatus from './TplStatus';
import useFileUpload from './useFileUpload';
import { FormControlLabel, Switch } from '@material-ui/core';

const FileUploadInput = styled.input`
  display: none;
`;

const ShowArchived = styled(FormControlLabel)`
  white-space: nowrap;
`;

const TplListCtr: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [showArchived, setShowArchived] = React.useState(false);
  const rows: FlatTmplVer[] | undefined = useSelector(selectTmplFiles(showArchived));
  const dispatch = useAppDispatch();
  const gone = React.useRef(false);
  const [selectedFile, setSelectedFile] = React.useState<
    { file: File | undefined; tmplId: string } | undefined
  >();
  const { handleAdd } = useFileUpload(selectedFile?.file, selectedFile?.tmplId);

  React.useEffect(() => {
    handleAdd();
  }, [handleAdd]);

  React.useEffect(() => {
    return () => {
      gone.current = true;
    };
  }, []);

  React.useEffect(() => {
    setLoading(true);
    dispatch(getTmpls()).finally(() => {
      if (gone.current) return;
      setLoading(false);
    });
  }, [dispatch]);

  const headCells: HeadCell<FlatTmplVer>[] = [
    { prop: 'tmplName', hasLink: true, label: 'Template Name' },
    { prop: 'fileName', label: 'File Name' },
    {
      prop: 'tmplStatus',
      label: 'Status',
      element: (row) => <TplStatus tplStatus={row.tmplStatus || 'published'} />,
    },
    {
      prop: 'uplAt',
      label: 'Uploaded At',
      element: (row) => <span>{row.uplAt}</span>,
    },
    {
      prop: 'tmplId',
      label: 'Action',
      element: (row) => (
        <>
          <IconButton
            aria-label="download"
            component="span"
            onClick={() => {
              dispatch(downloadExcel({ fileKey: composeFileKey(row) }));
            }}
          >
            <DownloadIcon />
          </IconButton>
          <FileUploadInput
            accept=".xlsx,.xlsm"
            id={`file-upl-${row.tmplId}`}
            type="file"
            onChange={(e) => setSelectedFile({ file: e.target.files?.[0], tmplId: row.tmplId })}
          />
          <label htmlFor={`file-upl-${row.tmplId}`}>
            <IconButton aria-label="upload" component="span">
              <UploadIcon />
            </IconButton>
          </label>
        </>
      ),
    },
  ];

  const generateLink = (row: FlatTmplVer, prop: Extract<keyof FlatTmplVer, string>) => {
    const fileId = row.fileId;
    const fileKey = composeFileKey({
      tenantName: row.tenantName,
      tmplId: row.tmplId,
      fileId,
      fileName: row.fileName,
    });
    // return `${row.tmplStatus === 'draft' ? 'uicfg' : 'preview'}/${btoa(fileKey)}`;
    return `uicfg/${Base64.encodeURI(fileKey)}`;
  };

  return (
    <TabledList
      rows={rows}
      generateLink={generateLink}
      pkProp={'fileId'}
      tableTitle="Templates"
      headCells={headCells}
      loading={loading}
      TRElement={
        <div>
          <ShowArchived
            control={
              <Switch
                checked={showArchived}
                onChange={(e) => setShowArchived(e.target.checked)}
                name="checkedB"
                color="primary"
              />
            }
            label="Show Archived"
          />
        </div>
      }
    />
  );
};

export default TplListCtr;
