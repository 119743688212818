import React from 'react';
import { Chip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Task } from 'xacmn';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useAppDispatch } from '../../app/configureStore';
import { getMyTasks, selectCurrentTmpl, selectMyTasks } from './workflowSlice';
import { HeadCell } from '../general/TabledList';
import TabledList from '../general/TabledList';
import Placeholder from '../general/Placeholder';
import { TaskCardPriorityView } from '../OA/TaskCardPriority';

interface TasksProps {}

const headCells: HeadCell<Task>[] = [
  {
    prop: 'priority',
    label: 'Priority',
    element: (row) => (
      <TaskCardPriorityView priority={row.priority} saving={false} hideEmpty larger />
    ),
  },
  { prop: 'title', label: 'Title', hasLink: true },
  {
    prop: 'shortTransId',
    label: 'File ID',
    element: (row) => (
      <Chip
        label={row.shortTransId}
        style={{ backgroundColor: row.transColorCode, color: grey[500] }}
      />
    ),
  },
  { prop: 'taskType', label: 'Task Type' },
  {
    prop: 'assignedAt',
    label: 'Assigned On',
    element: (row) => (row.assignedAt ? new Date(row.assignedAt).toLocaleDateString() : ''),
  },
  {
    prop: 'dueAt',
    label: 'Due On',
    element: (row) => (row.dueAt ? new Date(row.dueAt).toLocaleDateString() : ''),
  },
];

const TasksCtr: React.FC<TasksProps> = () => {
  const [loading, setLoading] = React.useState(false);
  const rows: Task[] | null = useSelector(selectMyTasks());
  const tmpl = useSelector(selectCurrentTmpl());
  const dispatch = useAppDispatch();
  const gone = React.useRef(false);

  React.useEffect(() => {
    return () => {
      gone.current = true;
    };
  }, []);

  React.useEffect(() => {
    setLoading(true);
    dispatch(getMyTasks()).finally(() => {
      if (gone.current) return;
      setLoading(false);
    });
  }, [dispatch]);

  const generateLink = React.useCallback(
    (row: Task, prop: Extract<keyof Task, string>) => `task/${row.assignmentId}/0`,
    [],
  );

  if (!tmpl) {
    return <Placeholder info="Please select a template to see its tasks" />;
  }

  const tmplRows = rows?.filter((row) => row.tmplId === tmpl.tmplId) || null;

  return (
    <TabledList
      rows={tmplRows}
      generateLink={generateLink}
      pkProp={'assignmentId'}
      tableTitle={`Tasks for template ${tmpl.tmplName}`}
      headCells={headCells}
      loading={loading}
    />
  );
};

export default TasksCtr;
