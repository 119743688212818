import * as React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useAppDispatch } from '../../app/configureStore';
import { useParams } from 'react-router-dom';
import TplRSB from './TplRSB';
import { getErrorMessageOfError, parseFileKey, Tmpl, TmplToSave } from 'xacmn';
import { getTmpl, saveTmpl, selectTmpl, setTplName } from './tplSlice';
import Placeholder from '../general/Placeholder';
import { notify } from '../general/generalSlice';
import { Base64 } from 'js-base64';

interface TplRSBCtrProps {
  step: number;
}

const TplRSBCtr: React.FC<TplRSBCtrProps> = ({ step }) => {
  const { encodedKey } = useParams();
  const dispatch = useAppDispatch();
  const [tplLoading, setTplLoading] = React.useState(false);
  const [tplSaving, setTplSaving] = React.useState(false);
  const tpl = useSelector(selectTmpl());
  const gone = React.useRef(false);

  React.useEffect(() => {
    return () => {
      gone.current = true;
    };
  }, []);
  const { tmplId, fileId, fileName } = parseFileKey(Base64.decode(encodedKey || ''));

  React.useEffect(() => {
    if (encodedKey) {
      setTplLoading(true);
      dispatch(getTmpl(tmplId))
        .unwrap()
        .then((tplGot: Tmpl | undefined) => {
          if (gone.current) return;
          setTplLoading(false);
        });
    }
  }, [dispatch, encodedKey, tmplId]);

  const save = (tplName: string): void => {
    setTplSaving(true);
    const tmpl: TmplToSave = {
      tmplId,
      tmplName: tplName,
      fileId,
      fileName,
    };
    dispatch(saveTmpl(tmpl))
      .unwrap()
      .catch((err) => {
        dispatch(
          notify({
            variant: 'error',
            message: getErrorMessageOfError(err),
          }),
        );
      })
      .finally(() => {
        setTplSaving(false);
      });
  };

  const changeTplName = (tplName: string) => {
    dispatch(setTplName(tplName));
  };

  if (!encodedKey) {
    return null;
  }

  const uplAt = tpl?.filesArc[fileId]?.uplAt;

  if (tplLoading) {
    return <Placeholder info={`Loading the ${fileName} template ...`} />;
  } else {
    if (tpl && fileId in tpl.filesArc /* not dirty tpl from last load */) {
      return (
        <TplRSB
          fileName={tpl.filesArc[fileId].fileName}
          tplName={tpl.tmplName}
          onChangeTplName={changeTplName}
          uplAt={uplAt ? new Date(uplAt) : undefined}
          tplStatus={tpl.tmplStatus}
          tplSaving={tplSaving}
          onTplNameChanged={save}
          step={step}
        />
      );
    } else {
      return <Placeholder info="no template loaded" />;
    }
  }
};
export default TplRSBCtr;
