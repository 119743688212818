import * as React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

interface NothingProps {
  info?: string;
}

const Placeholder: React.FC<NothingProps> = ({ info }) => {
  return (
    <Container>
      <Typography color="textSecondary">{info || 'Nothing can be displayed'}</Typography>
    </Container>
  );
};
export default Placeholder;
