import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector as useSelector } from '../../app/configureStore';
import ExcelSheetsCtr from '../excel/ExcelSheetsCtr';
import { selectTmpl } from './tplSlice';
import Placeholder from '../general/Placeholder';
import { Base64 } from 'js-base64';

const TplUICfg: React.FC = () => {
  const { encodedKey } = useParams();
  const tpl = useSelector(selectTmpl());
  if (!encodedKey) {
    return <Placeholder />;
  }
  return (
    <ExcelSheetsCtr
      fileKey={Base64.decode(encodedKey)}
      mode="uiCfg"
      readonly={tpl?.tmplStatus === 'archived'}
    />
  );
};

export default TplUICfg;
