import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

const TopBarRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={null} />
    </Routes>
  );
};

export default TopBarRoutes;
