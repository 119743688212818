import styled from 'styled-components';
import { ReactComponent as TopLeftIconSvg } from '../excel/top-left-corner.svg';
import { ReactComponent as BottomRightIconSvg } from '../excel/bottom-right-corner.svg';
import { ReactComponent as EditableIconSvg } from '../excel/edit.svg';
import { ReactComponent as ReadOnlyIconSvg } from '../excel/edit_off.svg';

interface IconProps {
  $disabled?: boolean;
}

export const EditableIcon = styled(EditableIconSvg)<IconProps>`
  && {
    fill: ${(props) => (props.$disabled ? 'lightgrey' : 'green')};
  }
`;

export const TopLeftIcon = styled(TopLeftIconSvg)<IconProps>`
  && {
    fill: ${(props) => (props.$disabled ? 'lightgrey' : 'steelblue')};
  }
`;

export const BottomRightIcon = styled(BottomRightIconSvg)<IconProps>`
  && {
    fill: ${(props) => (props.$disabled ? 'lightgrey' : 'steelblue')};
  }
`;

export const ReadOnlyIcon = styled(ReadOnlyIconSvg)<IconProps>`
  && {
    fill: ${(props) => (props.$disabled ? 'lightgrey' : 'palevioletred')};
  }
`;
