import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from 'xacmn';
import { State } from '../../app/configureStore';

export interface GeneralState {
  waitingDepth: number;
  notification?: NotificationType;
  redirectTo?: string;
}

interface WaitAction {
  type: string;
}

interface FinishWaitingAction {
  type: string;
}

const initialState: GeneralState = {
  waitingDepth: 0,
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    redirectTo: (state, action: PayloadAction<string | undefined>) => {
      console.log('generalSlice/redirectTo reducer ', action.payload);
      state.redirectTo = action.payload;
    },
    wait: (state, action: WaitAction) => {
      state.waitingDepth += 1;
    },
    finishWaiting: (state, action: FinishWaitingAction) => {
      state.waitingDepth = Math.max(0, state.waitingDepth - 1);
    },
    notify: (state, action: PayloadAction<NotificationType>) => {
      state.notification = action.payload;
    },
  },
});

export const { redirectTo, wait, finishWaiting, notify } = generalSlice.actions;

export default generalSlice.reducer;

export const isWaiting = () => (state: State) => {
  return state.general.waitingDepth > 0;
};

export const selectNotification = () => (state: State) => state.general.notification;

export const selectRedirectTo = () => (state: State) => state.general.redirectTo;
