import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2),
    },
    message: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(2),
    },
  }),
);

interface FullscreenMessageProps {
  info: string;
}

const FullscreenMessage: React.FC<FullscreenMessageProps> = ({ info }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.message}>{ReactHtmlParser(info)}</Typography>
    </div>
  );
};

export default FullscreenMessage;
