import { ParsedFileKey } from '../types';

export function parseFileKey(fileKey: string): ParsedFileKey {
  const splitKey = fileKey.split('/');
  if (splitKey.length !== 4) {
    throw new Error(`file key ${fileKey} is wrong`);
  }
  const [tenantName, tmplId, fileId, fileName] = splitKey;
  return { tenantName, tmplId, fileId, fileName };
}

export function composeFileKey(fileInfo: ParsedFileKey): string {
  return `${fileInfo.tenantName}/${fileInfo.tmplId}/${fileInfo.fileId}/${fileInfo.fileName}`;
}
