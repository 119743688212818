import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import TplUICfg from './TplUICfg';
import TplListCtr from './TplListCtr';
import TplPreview from './TplPreview';

const Template: React.FC = () => {
  return (
    <Routes>
      <Route path={'uicfg/:encodedKey'} element={<TplUICfg />} />
      <Route path={'preview/:encodedKey'} element={<TplPreview />} />
      <Route path={''} element={<TplListCtr />} />
    </Routes>
  );
};

export default Template;
