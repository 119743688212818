import { LoggedInUserInfo, Task, TaskToCreate } from 'xacmn';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<T>(order: Order, orderBy: keyof T): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getLoggedInUserInfo(): LoggedInUserInfo | null {
  const userInfoStr = sessionStorage.getItem('userInfo');
  if (userInfoStr) {
    const userInfo = JSON.parse(userInfoStr) as LoggedInUserInfo;
    return userInfo;
  }
  return null;
}

export function isExistingTask(task: Task | TaskToCreate | undefined): task is Task {
  return task !== undefined && !!(task as Task)?.assignmentId;
}

const COLOR_RANGE_DIVIDER = 6;
const COLOR_RANGE = (COLOR_RANGE_DIVIDER - 1) / COLOR_RANGE_DIVIDER;
const COLOR_BASE = Math.ceil(255 * COLOR_RANGE);

export async function generateShortCode(inputSequence: string): Promise<[string, string]> {
  // Convert input sequence to ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(inputSequence);

  // Generate SHA-256 hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert hash to hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  const r = Math.floor(hashArray[0] * (1 - COLOR_RANGE)) + COLOR_BASE;
  const g = Math.floor(hashArray[1] * (1 - COLOR_RANGE)) + COLOR_BASE;
  const b = Math.floor(hashArray[2] * (1 - COLOR_RANGE)) + COLOR_BASE;
  const colorCode = `rgb(${r}, ${g}, ${b})`;

  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  // Extract first 6 characters from hash and convert to alphanumeric
  const alphanumericCode = hashHex
    .substr(0, 6)
    .replace(/[0-9]/g, (c) => String.fromCharCode(65 + parseInt(c)))
    .toUpperCase();

  return [alphanumericCode, colorCode];
}
