import * as React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useAppDispatch } from '../../app/configureStore';
import { selectUsersList, getUsersList } from './usersSlice';
import { HeadCell } from '../general/TabledList';
import TabledList from '../general/TabledList';
import Placeholder from '../general/Placeholder';
import { UserInfo } from 'xacmn';
import { useNavigate } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const headCells: HeadCell<UserInfo>[] = [
  { prop: 'userName', hasLink: true, label: 'User Name' },
  { prop: 'name', label: 'Name' },
  { prop: 'email', label: 'Email' },
  { prop: 'role', label: 'Role' },
  {
    prop: 'enabled',
    label: 'Enabled?',
    element: (row) => (
      <Chip
        label={row.enabled ? 'Yes' : 'No'}
        style={{ backgroundColor: row.enabled ? 'lightgreen' : 'lightgrey', color: grey[700] }}
      />
    ),
  },
];

const UsersList: React.FC = () => {
  const users = useSelector(selectUsersList());
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  const handleAdd = React.useCallback(() => {
    navigate('user');
  }, [navigate]);

  if (users === null) {
    return <Placeholder info="Loading..." />;
  }

  const generateLink = (row: UserInfo, prop: Extract<keyof UserInfo, string>) =>
    `user/${row.userName}`;

  return (
    <TabledList
      rows={users}
      generateLink={generateLink}
      pkProp={'userName'}
      tableTitle="Users"
      headCells={headCells}
      showAddButton
      addFn={handleAdd}
    />
  );
};
export default UsersList;
