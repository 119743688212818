import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useWebSocket from 'react-use-websocket';
import FeatureRoutes from './FeatureRoutes';
import TopBar from './TopBar';
import ProgressCtr from '../features/general/ProgressCtr';
import { useAppDispatch } from './configureStore';
import { receiveWsMsg } from '../features/wsmsg/wsmsgSlice';
import Notification from '../features/general/Notification';
import { redirectTo, selectRedirectTo } from '../features/general/generalSlice';
import { useAppSelector as useSelector } from './configureStore';
import { useNavigate } from 'react-router-dom';
import { refreshToken } from '../login';
import { wsUrl } from '../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    topBarSpace: {
      ...theme.mixins.toolbar,
    },
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingTop: theme.spacing(1),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    content: {
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'row',
      height: '100%',
    },
  }),
);

let isActive = true;
const _refreshToken = () => {
  if (isActive) {
    refreshToken();
  }
  isActive = false;
};

const TOKEN_REFRESH_INTERVAL = 10 * 60 * 1000;

const Frame: React.FC = () => {
  const dispatch = useAppDispatch();
  const redirectToURL = useSelector(selectRedirectTo());
  const navigate = useNavigate();

  const classes = useStyles();

  useEffect(() => {
    const timer = setInterval(_refreshToken, TOKEN_REFRESH_INTERVAL);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleMouseMove = () => {
      isActive = true;
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // TODO: web socket url in conf
  const webSktURL = `${wsUrl}?Authorization=${sessionStorage.getItem('id_token')}`;
  const { lastJsonMessage } = useWebSocket(webSktURL, {
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastJsonMessage !== null) {
      const msgStr = JSON.stringify(lastJsonMessage);
      console.debug('web socket message received ->', msgStr);
      dispatch(receiveWsMsg(msgStr));
    }
  }, [dispatch, lastJsonMessage]);

  useEffect(() => {
    if (redirectToURL && redirectToURL.length > 0) {
      const _next = redirectToURL;
      dispatch(redirectTo(undefined));
      navigate(_next);
    }
  }, [dispatch, navigate, redirectToURL]);

  return (
    <div className={classes.root}>
      <Notification />
      <ProgressCtr />
      <TopBar />
      <main className={clsx(classes.container)}>
        <div className={classes.topBarSpace} />
        <div className={classes.content}>
          <FeatureRoutes />
        </div>
      </main>
    </div>
  );
};

export default Frame;
