import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import setUpStore from './app/configureStore';
import TenantRoute from './app/TenantRoute';
import theme from './theme';

const store = setUpStore();

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Router>
          <TenantRoute />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
