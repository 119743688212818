import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TaskFrame from '../task/TaskFrame';
import TaskListFrame from './TaskListFrame';

const WorkflowRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="task/:assignmentId/:encodedFileKeyNew" element={<TaskFrame />} />
      <Route path="" element={<TaskListFrame />} />
    </Routes>
  );
};

export default WorkflowRoutes;
