import * as React from 'react';
import * as Excel from 'exceljs';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { Cell, OnCellChangedEventHandler, typeCellValue } from 'xacmn';
import ExcelCell from './ExcelCell';
import { selectCellValue } from './excelSlice';

export interface ExcelCellCtrProps {
  excelMtdt: Cell;
  onCellChanged: OnCellChangedEventHandler;
  onFocus?: () => void;
  onBlur?: () => void;
}

const ExcelCellCtr: React.FC<ExcelCellCtrProps> = React.memo(
  ({ excelMtdt, onCellChanged, onFocus, onBlur }) => {
    const val = useSelector(selectCellValue(excelMtdt));

    const blurHandler = React.useCallback(
      (value: unknown) => {
        const newValue = typeCellValue(value, excelMtdt.effectiveType, excelMtdt);
        onCellChanged(excelMtdt.cellAddress, val, newValue);
        if (onBlur) onBlur();
      },
      [val, excelMtdt.cellAddress, excelMtdt.effectiveType, onBlur, onCellChanged],
    );

    return (
      <ExcelCell
        excelMtdt={excelMtdt}
        val={val as Excel.CellValue}
        onBlur={excelMtdt.isStatic ? undefined : blurHandler}
        onFocus={excelMtdt.isStatic ? undefined : onFocus}
      />
    );
  },
);
export default ExcelCellCtr;
