import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import {
  CalculateResult,
  Cell,
  ExcelRuntimeCfg,
  FormulaInfo,
  getErrorMessageOfError,
  getErrorMessageOfResponse,
  Mode,
  typeCellValue,
  Workbook,
} from 'xacmn';
import { State } from '../../app/configureStore';
import { wait, finishWaiting, notify } from '../general/generalSlice';
import { getCellValue, isFormulaValue, normalizeCellAddress } from './excelUtil';
import { apiUrl } from '../../config';

export interface ExcelState {
  changed: Record<string, unknown>;
  changeVersion: number;
  calculated: Record<string, unknown>;
  dropdownOptionsCalculated?: FormulaInfo['dropdownOptions'];
  workbook?: Workbook | null;
  formulaInfo?: FormulaInfo | null;
  calculating: boolean;
  versionCalculated: number | null;
  calculateFailedTime?: string;
  calculateFailedCounter: number;
  focusedCell?: string;
}

interface SetExcelAction {
  type: string;
  payload: {
    workbook: Workbook;
  };
}

interface ChangeAction {
  type: string;
  payload: {
    cellAddress: string;
    value: unknown;
  };
}

const initialState: ExcelState = {
  changed: {},
  changeVersion: 0,
  calculated: {},
  calculating: false,
  calculateFailedCounter: 0,
  versionCalculated: null,
};

export const getExcelUIWorkbook = createAsyncThunk(
  'excel/getExcelUIWorkbook',
  async ({ fileKey, mode }: { fileKey: string; mode: Mode }, thunkAPI) => {
    thunkAPI.dispatch(wait());
    try {
      const server = apiUrl;
      const path = '/admin';

      const params: Record<string, string> = {
        ef: fileKey,
      };

      const token = sessionStorage.getItem('id_token');
      const res = await fetch(`${server}${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // TODO: user Bearer
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          action: mode === 'uiCfg' ? 'getUiDlUrl' : 'getUserUiDlUrl',
          data: params,
        }),
      });
      if (res.status !== 200) {
        const errMsg = await getErrorMessageOfResponse(res);
        thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
        return null;
      }

      const downloadUrl = await res.json();
      const dataRes = await fetch(downloadUrl);
      if (dataRes.status !== 200) {
        const errMsg = await getErrorMessageOfResponse(dataRes);
        thunkAPI.dispatch(notify({ variant: 'error', message: errMsg }));
        return null;
      }
      const rtCfg: ExcelRuntimeCfg = await dataRes.json();
      return rtCfg;
    } finally {
      thunkAPI.dispatch(finishWaiting());
    }
  },
);

export const calculate = createAsyncThunk(
  'excel/calculate',
  async (key: string, thunkAPI): Promise<CalculateResult | void> => {
    const state: ExcelState = _.get(thunkAPI.getState(), 'excel') as unknown as ExcelState;
    const wb = state.workbook;
    if (!wb) {
      return;
    }
    if (state.calculating) return;
    try {
      thunkAPI.dispatch(excelSlice.actions.startCalculate());
      const versionCalculated = state.changeVersion;
      const changed = _.mapKeys(state.changed, (v, k) => {
        return "'" + k.slice(wb.name.length + 3);
      });
      const server = apiUrl;
      const path = '/formula';

      const token = sessionStorage.getItem('id_token');
      const res = await fetch(`${server}${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          key,
          inputs: changed,
        }),
      });
      if (res.status !== 200) {
        const errMsg = `Excel formulas running failed:  ${await getErrorMessageOfResponse(res)}`;
        throw new Error(errMsg);
      }
      const calculateResult: CalculateResult = await res.json();
      thunkAPI.dispatch(
        excelSlice.actions.calculateSucceeded({
          calculateResult,
          versionCalculated,
        }),
      );

      return calculateResult;
    } catch (err) {
      thunkAPI.dispatch(excelSlice.actions.calculateFailed());
      thunkAPI.dispatch(
        notify({
          variant: 'error',
          message: getErrorMessageOfError(err),
        }),
      );
    }
  },
);

interface ChangAllAction {
  type: string;
  payload: Record<string, unknown>;
}

export const excelSlice = createSlice({
  name: 'excel',
  initialState,
  reducers: {
    focus: (state, action: PayloadAction<string | undefined>) => {
      state.focusedCell = action.payload;
    },
    change: (state, action: ChangeAction) => {
      state.changed[action.payload.cellAddress] = action.payload.value;
      state.changeVersion = state.changeVersion + 1;
    },
    changeAll: (state, action: ChangAllAction) => {
      state.changed = action.payload;
      state.changeVersion = 0;
    },
    cleanExcel: (state) => {
      state.changed = {};
      state.calculated = {};
      state.dropdownOptionsCalculated = {};
      state.workbook = undefined;
      state.calculating = false;
      state.formulaInfo = null;
      state.changeVersion = 0;
      state.versionCalculated = null;
      state.calculateFailedCounter = 0;
      state.calculateFailedTime = undefined;
      state.focusedCell = undefined;
    },
    setExcel: (state, action: SetExcelAction) => {
      state.changed = {};
      state.calculated = {};
      state.dropdownOptionsCalculated = undefined;
      state.workbook = action.payload.workbook;
      state.calculating = false;
      state.formulaInfo = null;
      state.changeVersion = 0;
      state.versionCalculated = null;
      state.calculateFailedCounter = 0;
      state.calculateFailedTime = undefined;
      state.focusedCell = undefined;
    },
    startCalculate: (state) => {
      state.calculating = true;
    },
    calculateSucceeded: (
      state,
      action: PayloadAction<{ calculateResult: CalculateResult; versionCalculated: number }>,
    ) => {
      const wbName = state.workbook?.name;
      if (!wbName) {
        throw new Error('Workbook name is not defined');
      }
      state.calculated = action.payload.calculateResult.cells;
      const newDropdownOptions = _.mapKeys(
        action.payload.calculateResult.validations,
        (options, cellAddr) => normalizeCellAddress(cellAddr, wbName),
      );
      state.dropdownOptionsCalculated = {
        ...state.dropdownOptionsCalculated,
        ...newDropdownOptions,
      };
      state.versionCalculated = action.payload.versionCalculated;
      state.calculateFailedTime = undefined;
      state.calculateFailedCounter -= 1;
      if (state.calculateFailedCounter < 0) state.calculateFailedCounter = 0;
      state.calculating = false;
    },
    calculateFailed: (state) => {
      state.calculateFailedTime = new Date().toISOString();
      state.calculateFailedCounter += 1;
      state.calculating = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExcelUIWorkbook.fulfilled, (state, action) => {
      if (!action.payload) return;
      const rtCfg = action.payload;
      state.workbook = rtCfg.workbook;
      const dropdownOptions = _.mapKeys(
        action.payload.formulaInfo.dropdownOptions,
        (options, cellAddr) => normalizeCellAddress(cellAddr, rtCfg.workbook.name),
      );
      const fi: FormulaInfo = {
        inputs: action.payload.formulaInfo.inputs.map((input) =>
          normalizeCellAddress(input, rtCfg.workbook.name),
        ),
        dropdownOptions,
      };
      state.formulaInfo = fi;
      state.dropdownOptionsCalculated = dropdownOptions;

      state.calculated = {};
      state.versionCalculated = null;
      state.calculateFailedCounter = 0;
      state.calculateFailedTime = undefined;
    });
  },
});

export const { change, changeAll, cleanExcel, setExcel, focus } = excelSlice.actions;

export default excelSlice.reducer;

export const selectCellValue = (excelMtdt: Cell) => (state: State) => {
  let cellValueExists = false;
  let cellValue: unknown = undefined;
  if (state.excel.changed) {
    cellValue = getCellValue(excelMtdt.cellAddress, state.excel.changed);
    cellValueExists = cellValue !== undefined;
  }
  if (!cellValueExists && state.excel.calculated) {
    cellValue = getCellValue(excelMtdt.cellAddress, state.excel.calculated);
    cellValueExists = cellValue !== undefined;
  }
  // TODO: deal with other types of cell values
  const val = cellValueExists
    ? cellValue
    : isFormulaValue(excelMtdt.value)
    ? excelMtdt.result
    : excelMtdt.value;
  const typedValue = typeCellValue(val, excelMtdt.effectiveType, excelMtdt);
  return typedValue;
};

export const selectChangeVersion = () => (state: State) => {
  return state.excel.changeVersion;
};

export const selectFocusedCell = () => (state: State) => {
  return state.excel.focusedCell;
};

export const selectCellChanged = () => (state: State) => {
  return state.excel.changed;
};

export const selectExcelWorkbook = () => (state: State) => {
  return state.excel.workbook;
};

export const selectCalculating = () => (state: State) =>
  state.excel.workbook && state.excel.calculating;

export const selectCalculateFailedCounter = () => (state: State) =>
  state.excel.calculateFailedCounter;

export const selectVersionCalculated = () => (state: State) => state.excel.versionCalculated;

export const selectShallCalculate = () => (state: State) =>
  !state.excel.calculating &&
  ((state.excel.versionCalculated !== null &&
    state.excel.changeVersion > state.excel.versionCalculated) ||
    state.excel.versionCalculated === null);

export const selectInputs = () => (state: State) => {
  return state.excel.formulaInfo?.inputs;
};

export const selectInitialDropdownOptions = () => (state: State) => {
  return state.excel.formulaInfo?.dropdownOptions;
};

export const selectDropdownOptions = (cellAddr: string) => (state: State) => {
  return state.excel.dropdownOptionsCalculated?.[cellAddr] || [];
};
