import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green, grey } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Task, TaskAssignmentParams } from 'xacmn';
import { useAppDispatch } from '../../app/configureStore';
import { completeTask } from '../task/taskSlice';
import { notify } from '../general/generalSlice';

interface TaskCardCompletedProps {
  task: Task;
}

const TaskCardCompleted: React.FC<TaskCardCompletedProps> = ({ task }) => {
  const dispatch = useAppDispatch();
  const [cfmDlgOpen, setCfmDlgOpen] = React.useState(false);

  const handleCfmDlgCancel = () => {
    setCfmDlgOpen(false);
  };

  const handleCfmDlgConfirm = () => {
    setCfmDlgOpen(false);
    complete();
  };

  const complete = React.useCallback(() => {
    const params: TaskAssignmentParams = {
      assignmentId: task.assignmentId,
    };
    dispatch(completeTask(params)).then((fulfilledAction) => {
      if (fulfilledAction?.payload) {
        dispatch(
          notify({
            variant: 'success',
            message: 'Task has been successfully completed',
          }),
        );
      } else {
        dispatch(
          notify({
            variant: 'error',
            message: 'Task failed to complete.',
          }),
        );
      }
    });
  }, [dispatch, task]);

  const canNotComplete = !task || task.completed || task.aborted || !!task.reassignedAt;

  return (
    <>
      <CheckCircleIcon
        style={{
          float: 'right',
          color: task.completed ? green[500] : grey[500],
          cursor: canNotComplete ? 'not-allowed' : 'pointer',
        }}
        onClick={
          !canNotComplete
            ? () => {
                setCfmDlgOpen(true);
              }
            : undefined
        }
      />
      <Dialog
        open={cfmDlgOpen}
        onClose={handleCfmDlgCancel}
        aria-labelledby="cfm-dlg-cmpl-title"
        aria-describedby="cfm-dlg-cmpl-desc"
      >
        <DialogContent>
          <DialogContentText id="cfm-dlg-cmpl-desc">
            You are about to complete this task. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCfmDlgCancel} color="default">
            Cancel
          </Button>
          <Button onClick={handleCfmDlgConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskCardCompleted;
