import * as React from 'react';
import * as Excel from 'exceljs';
// import EditIcon from '@material-ui/icons/Edit';
import { Cell } from 'xacmn';
import ExcelCellStatic from './ExcelCellStatic';
import ExcelCellEdit from './ExcelCellEdit';
// import styles from './ExcelCell.module.css';

interface ExcelCellProps {
  excelMtdt: Cell;
  val?: Excel.CellValue;
  onBlur?: (value: unknown) => void;
  onFocus?: () => void;
}

const ExcelCell: React.FC<ExcelCellProps> = React.memo(({ excelMtdt, val, onBlur, onFocus }) => {
  let cpnt;

  if (excelMtdt.isStatic) {
    cpnt = <ExcelCellStatic excelMtdt={excelMtdt} val={val} />;
  } else {
    cpnt = (
      <>
        <ExcelCellEdit excelMtdt={excelMtdt} val={val} onBlur={onBlur} onFocus={onFocus} />
        {/* <EditIcon className={styles['edit-overlay']} /> */}
      </>
    );
  }

  return cpnt;
});

export default ExcelCell;
