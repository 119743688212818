import * as Excel from 'exceljs';
import AWS from 'aws-sdk';
import { APIGatewayProxyEventV2 } from 'aws-lambda';

export type LambdaAuthorizedEventV2 = APIGatewayProxyEventV2 & {
  requestContext: {
    authorizer?: {
      lambda: Record<string, string | number | boolean | []>;
    };
  };
};

export interface TenantPublic {
  tenantId: string;
  tenantName: string;
  domain: string;
  appClientId: string;
  userPoolId: string;
}

export interface Tenant extends TenantPublic {
  creator: User;
  taskTypes?: string[];
}

// TODO: define user roles and authorities
export const bizRoles = [
  'Actuary',
  'Adjuster',
  'Agent',
  'Broker',
  'Clerk',
  'Customer Service Representative',
  'Executive',
  'IT',
  'Underwriter',
] as const;
export type BizRole = (typeof bizRoles)[number];

export const roles = ['Administrator', 'User'] as const;
export type Role = (typeof roles)[number];

export interface TenantUser {
  userName: string;
  name: string;
  email: string;
  phone?: string;
  role?: string;
}

export interface User extends TenantUser {
  tenantName: string;
  companyName?: string;
}

export interface UserInfo extends User {
  userPoolId: string;
  tenantId?: string;
  sub: string;
  aud?: string;
  enabled?: boolean;
}

export type TaskUser = Pick<UserInfo, 'sub' | 'userName' | 'name'>;

export type TmplStatus = 'draft' | 'published' | 'archived';

interface TmplVer {
  fileId: string;
  fileDesc?: string;
  fileName: string;
  uplAt: string; // when the file is uploaded
  s3key: string;
  effFrom: string;
  seq: number; // decide precedence when effFrom is the same
}

export interface Tmpl {
  tenantName: string;
  tmplId: string;
  tmplName?: string;
  tmplStatus: TmplStatus;
  filesArc: Record<string, TmplVer>; // fileId: TmplVer
  effFiles: { effFrom: string; fileId: string }[];
}

export interface ParsedFileKey {
  tenantName: string;
  tmplId: string;
  fileId: string;
  fileName: string;
}

export interface FlatTmplVer extends ParsedFileKey {
  tmplName?: string;
  tmplStatus?: TmplStatus;
  fileDesc?: string;
  uplAt?: string; // when the file is uploaded
  s3key: string;
  effFrom: string;
  seq: number; // decide precedence when effFrom is the same
}

export interface TmplToSave {
  tmplId: string;
  tmplName?: string;
  fileId: string;
  fileDesc?: string;
  tmplStatus?: TmplStatus;
  fileName: string;
  effFrom?: string;
}

export interface TmplInPath {
  tmplId: string;
  fileId: string;
  fileName: string;
}

export interface TaskUserData {
  // changeable by user
  taskType: string;
  title: string;
  taskDesc?: string;
  dueAt?: string;
  priority?: 'high' | 'medium' | 'low';
}

export interface Task extends TaskUserData {
  // unchangeble across re-assignments
  tenantName: string;
  taskId: string; // a taskId can have multiple assignmentId(s)
  createdBySub: string;
  createdAt: string;
  transId: string;
  shortTransId?: string; // transient for UI
  transColorCode?: string; // transient for UI
  tmplId: string;
  fileId: string;
  // unchangeable once created; note re-assignment would create a new record
  assignmentId: string; // unique within a tenant
  assignedToSub: string; // once completed or re-assigned or aborted, append a '!' at the end for index purpose
  assignedBySub: string;
  assignedAt: string;
  // maintained automatically
  lastModifiedAt?: string;
  workedOn: boolean;
  workedOnAt?: string;
  // only set when reassigned
  reassignedAt?: string;
  // only set once upon completion
  completed: boolean;
  completedAt?: string;
  // only set once upon deletion / abortion
  aborted: boolean;
  abortedAt?: string;
}

export interface NewTaskUserData extends Partial<TaskUserData> {
  assignedToSub?: string;
}

export interface TaskToCreate extends NewTaskUserData {
  transId: string;
  tmplId: string;
  fileId: string;
}

export interface TaskToSave extends TaskUserData {
  assignmentId: string;
}

export interface TaskReassignParams {
  assignmentId: string;
  assignedToSub: string;
}

export interface TaskAssignmentParams {
  assignmentId: string;
}

export interface AssignTask extends TaskUserData {
  assignmentId: string;
  assignedToSub: string;
}

export interface Trans {
  tenantName: string;
  transId?: string;
  tmplId: string;
  fileId: string;
  cellsChanged: Record<string, unknown>;
}

export interface GetTmplUplUrlParams {
  fileName: string;
  contentType: string;
  tmplId?: string;
}

export interface GetTmplParams {
  tenantName: string;
  tmplId: string;
}

export interface GetTransParams {
  transId: string;
}

export interface ExcelCell extends Excel.Cell {
  _row: Excel.RowModel;
  _column: Excel.Column;
}

export interface Cell {
  readonly isMerged: boolean;
  readonly effectiveType: Excel.ValueType;
  readonly isHyperlink: boolean;
  readonly hyperlink?: string;
  readonly protection?: Partial<Excel.Protection>;
  readonly text: string;
  readonly fullAddress?: {
    sheetName: string;
    address: string;
    row: string | number;
    col: string | number;
  };
  readonly cellAddress: string;
  readonly dataValidation?: Excel.DataValidation;
  readonly value: Excel.CellValue;
  readonly note?: string | Excel.Comment;
  readonly result?: number | string | Date;
  readonly type: Excel.ValueType;
  readonly formulaType?: Excel.FormulaType;
  style?: Partial<Excel.Style>;
  readonly bgColor?: string;
  readonly fontColor?: string;

  readonly columnSpan: number;
  readonly isStatic: boolean;
  readonly cellPath?: string[];
}

export interface Worksheet {
  readonly name: string;
  readonly loc: Excel.Location;
  lyt: UILyt;
  rowsHeightPx: { h: number; ch: number }[];
  colsWidthPx: { w: number; cw: number }[];
}

export interface UILytRow {
  top: number;
  left: number;
  bottom: number;
  right: number;
  heightPx?: number;
  widthPx?: number;
  columns?: UILytColumn[];
  cell?: Cell;
}

export interface UILytColumn {
  top: number;
  left: number;
  bottom: number;
  right: number;
  widthPx?: number;
  rows?: UILytRow[];
  cell?: Cell;
}

export type UILyt = UILytRow[];

export interface Workbook {
  name: string;
  worksheets: Worksheet[];
  mappings?: Record<string, string[]>;
  defaultInputs?: Record<string, unknown>;
}

export type Merges = Readonly<{ [P in string]: Excel.Location }>;

export interface Worksheet4UILyt {
  readonly dimensions: Excel.Location;
  readonly _merges: Merges;
}

export interface ThemeTintColor {
  theme: number;
  tint?: number;
}

export interface IndexedColor {
  indexed: number;
}

export interface ARGBColor {
  argb?: string;
}

export type Color = ThemeTintColor | IndexedColor | ARGBColor;

export interface UILytCallbacks {
  getHeight(top: number, bottom: number): { heightPx: number };
  getWidth(left: number, right: number): { widthPx: number };
  getCell(loc: Excel.Location): Cell;
}

export type PromiseResult<D, E> = D & { $response: AWS.Response<D, E> };

export type ComponentType =
  | 'checkbox'
  | 'date'
  | 'dropdown'
  | 'text'
  | 'currency'
  | 'number'
  | 'Percent'
  | 'richText'
  | 'email'
  | 'phone'
  | 'year'
  | 'zipCode'
  | 'address';

export interface CellUICfg {
  editable: boolean;
  component?: ComponentType;
}

export interface Loc {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

export interface SheetUICfg {
  display: boolean;
  loc: Loc;
}

export interface SheetsUICfg {
  [name: string]: SheetUICfg;
}

export interface UICfg {
  sheets?: SheetsUICfg;
  cells?: Record<string, CellUICfg>;
}

export interface MatchParams {
  account: string;
  encodedKey: string;
}

export type OnCellChangedEventHandler = (
  cellAddress: string,
  oldValue: unknown,
  newValue: unknown,
) => void;

export type DummyFn = () => void;

export type Mode = 'uiCfg' | 'dataEntry' | 'query';

export interface OnSetSheetLTArgs {
  sheetName: string;
  left: number;
  top: number;
}

export type OnSetSheetLTEventHandler = (args: OnSetSheetLTArgs) => void;

export interface OnSetSheetRBArgs {
  sheetName: string;
  bottom: number;
  right: number;
}

export interface OnSetSheetLocArgs {
  sheetName: string;
  left: number;
  top: number;
  bottom: number;
  right: number;
}

export type OnSetSheetRBEventHandler = (args: OnSetSheetRBArgs) => void;

export interface SaveUICfgParams {
  uiCfg: UICfg;
  where: TmplInPath;
}

export type Json =
  | string
  | number
  | boolean
  | null
  | { [property: string]: Json }
  | Json[];

export enum WsTrackStatus {
  created = 0,
  ongoing = 1,
  done = 2,
  failed = 3,
}

export enum WsMsgUIEl {
  Global = 0,
  RunRules = 1,
  Pane = 2,
}

export interface WsMsgClient {
  uiEl: WsMsgUIEl; // name of the UI element showing progress
  apiName: string; // name of the API call
  trackId: string; // track an API call across multiple lambda functions
  sub: string; // user calling the API
}

export interface WsMsgServer {
  svcName?: string; // service name of backend
  message: string; // message presentable on front-end
  status: WsTrackStatus; // status of the overall API call
  createdAt: Date | string;
}

export type WsMsgInfo = WsMsgClient & WsMsgServer;

export interface LoggedInUserInfo {
  sub: string;
  'custom:tenant_name': string;
  'custom:company_name': string;
  name: string;
  'custom:tenant_id': string;
  'custom:role': string;
  email: string;
  username: string;
}

export interface RendererQueueInfo {
  s3key: string;
  meta: WsMsgClient;
}

export const notificationVariant = [
  'success',
  'warning',
  'error',
  'info',
] as const;

export type NotificationVariant = (typeof notificationVariant)[number];

export interface NotificationType {
  variant: NotificationVariant;
  message: string;
  duration?: number;
}

export interface FormulaInfo {
  inputs: string[];
  dropdownOptions: {
    [cellAddr: string]: (string | number)[];
  };
}

export interface ExcelRuntimeCfg {
  workbook: Workbook;
  formulaInfo: FormulaInfo;
}

export interface CalculateResult {
  cells: Record<string, unknown>;
  validations: FormulaInfo['dropdownOptions'];
}

export interface CellFullAddress {
  fileName?: string;
  sheetName?: string;
  row?: number;
  col?: string;
}

export enum Validity {
  'NA',
  'Yes',
  'No',
  'WIP',
}
