import React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import Link from '@material-ui/core/Link';
import { TaskToCreate, NewTaskUserData } from 'xacmn';
import { useAppDispatch } from '../../app/configureStore';
import TaskCardCtr from '../OA/TaskCardCtr';
import { createTask, getTasksByTrans, selectTask } from '../task/taskSlice';
import { notify } from '../general/generalSlice';
import _ from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles<Theme, { added: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      borderBottom: (props) => (props.added ? '1px solid rgba(0, 0, 0, 0.13)' : 0),
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    link: {
      marginTop: theme.spacing(2),
      color: 'rgba(0, 0, 0, 0.32)',
      width: '100%',
      border: '1px dashed rgba(0, 0, 0, 0.32)',
      height: '32px',
      transition:
        'color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      alignItems: 'center',
      borderRadius: '4px',
      justifyContent: 'center',
      display: 'flex',
    },
    taskContainer: {
      margin: `${theme.spacing(2)}px 0`,
    },
    progressContainer: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
    },
    progress: {
      color: grey[500],
    },
  }),
);

interface TaskAddProps {
  loadingName: boolean;
}

const TaskAdd: React.FC<TaskAddProps> = ({ loadingName }) => {
  const dispatch = useAppDispatch();
  const mainTask = useSelector(selectTask());
  const [added, setAdded] = React.useState(false);
  const classes = useStyles({ added });
  const [creating, setCreating] = React.useState(false);
  const [newTask, setNewTask] = React.useState<TaskToCreate>();
  const gone = React.useRef(false);

  React.useEffect(() => {
    return () => {
      gone.current = true;
    };
  }, []);

  const add = () => {
    if (!mainTask) return;
    const _newTask: TaskToCreate = _.pick(mainTask, ['transId', 'tmplId', 'fileId']);
    setNewTask(_newTask);
    setAdded(true);
  };

  function hasAdded(_newTask: typeof newTask): _newTask is TaskToCreate {
    return added;
  }

  const setNewTaskProp = (_newTask: Partial<NewTaskUserData>) => {
    if (!hasAdded(newTask)) return;
    setNewTask({ ...newTask, ..._newTask });
  };

  const reset = () => {
    if (gone.current) return;
    setNewTask(undefined);
    setAdded(false);
  };

  const addTask = async () => {
    if (!added || !newTask?.transId) return;
    setCreating(true);
    dispatch(createTask(newTask))
      .then(() => {
        dispatch(getTasksByTrans(newTask.transId)).then(() => {
          reset();
          dispatch(
            notify({
              variant: 'success',
              message: 'Task has been successfully created',
            }),
          );
        });
      })
      .finally(() => {
        if (gone.current) return;
        setCreating(false);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        {added ? (
          <>
            <IconButton onClick={reset}>
              <ClearIcon />
            </IconButton>
            <IconButton onClick={addTask}>
              <DoneIcon />
            </IconButton>
          </>
        ) : (
          <Link href="#" className={classes.link} onClick={add}>
            <AddIcon />
          </Link>
        )}
      </div>
      {added ? (
        <div className={classes.taskContainer}>
          <TaskCardCtr task={newTask} setNewTaskProp={setNewTaskProp} loadingName={loadingName} />
        </div>
      ) : null}
      {creating ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progress} size={64} />
        </div>
      ) : null}
    </div>
  );
};

export default TaskAdd;
