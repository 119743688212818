import * as React from 'react';
import { useAppSelector as useSelector } from '../../app/configureStore';
import Button from '@material-ui/core/Button';
import { TmplInPath } from 'xacmn';
import { composeFileKey } from 'xacmn';
import { useNavigate, useParams } from 'react-router-dom';
import { selectCurrentTmpl } from './workflowSlice';
import { Base64 } from 'js-base64';

const WorkflowBtnCtr: React.FC = () => {
  const tmpl = useSelector(selectCurrentTmpl());
  const navigate = useNavigate();
  const { account } = useParams();

  if (!account) {
    return null;
  }

  const createTrans: (t: TmplInPath) => void = ({ tmplId, fileId, fileName }) => {
    const encodedKey = Base64.encodeURI(
      composeFileKey({
        tenantName: account,
        tmplId,
        fileId,
        fileName,
      }),
    );
    navigate(`task/0/${encodedKey}`, {
      replace: true,
    });
  };

  const tmplId = tmpl?.tmplId;
  const fileId = tmpl?.effFiles[tmpl?.effFiles.length - 1].fileId;
  const fileName = fileId ? tmpl?.filesArc[fileId].fileName : 'N/A';

  return (
    <>
      <Button
        disabled={!tmplId}
        variant="contained"
        color="primary"
        value="Submit"
        onClick={
          tmplId && fileId && fileName
            ? (e) => {
                createTrans({ tmplId, fileId, fileName });
              }
            : undefined
        }
      >
        Create File
      </Button>
    </>
  );
};

export default WorkflowBtnCtr;
