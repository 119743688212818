import React, { FocusEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import styled from 'styled-components';
import { TmplStatus } from 'xacmn';
import CalculatingCtr from '../excel/CalculatingCtr';
import styles from './TplRSB.module.css';
import TplStatus from './TplStatus';
import { grey } from '@material-ui/core/colors';
import { EditableIcon, TopLeftIcon, BottomRightIcon, ReadOnlyIcon } from '../excel/Icons';

interface TplRSBProp {
  step: number;
  fileName?: string;
  tplName?: string;
  onChangeTplName: (newTplName: string) => void;
  uplAt?: Date;
  tplStatus?: TmplStatus;
  tplSaving?: boolean;
  onTplNameChanged: (newTplName: string) => void;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  stepper: {
    marginTop: '16px',
    padding: '16px',
  },
  progress: {
    color: grey[500],
  },
  buttonGrid: {
    display: 'flex',
  },
  listItem: {
    paddingLeft: '0px',
  },
});

const CalculatingPlaceHolder = styled.div`
  height: 24px;
  margin-bottom: 16px;
`;

const UICfgListItemIcon = styled(ListItemIcon)`
  min-width: 0px;
`;

const TplRSB: React.FC<TplRSBProp> = ({
  step,
  fileName,
  tplName,
  onChangeTplName,
  uplAt,
  tplStatus,
  tplSaving,
  onTplNameChanged,
}) => {
  const classes = useStyles();

  const handleTplNameChange = (event: FocusEvent<HTMLInputElement>) => {
    onTplNameChanged(event.target.value);
  };

  return (
    <>
      {step === 2 /* preview */ ? (
        <CalculatingCtr />
      ) : (
        <CalculatingPlaceHolder>
          <Typography color="textSecondary">Excel formulas status here!</Typography>
        </CalculatingPlaceHolder>
      )}
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} className={classes.buttonGrid}>
          <TplStatus tplStatus={tplStatus || 'draft'} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="tmpl-file-name"
            label="File Name"
            value={fileName}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid item xs={12} className={styles.textFieldWithProgress}>
          <TextField
            fullWidth
            label="Template Name"
            required
            value={tplName}
            onChange={tplStatus === 'archived' ? undefined : (e) => onChangeTplName(e.target.value)}
            onBlur={tplSaving || tplStatus === 'archived' ? undefined : handleTplNameChange}
            InputProps={
              tplStatus === 'archived'
                ? {
                    readOnly: true,
                    disableUnderline: true,
                  }
                : {}
            }
          />
          {tplSaving ? (
            <div className={styles.editorProgressContainer}>
              <CircularProgress className={classes.progress} size={40} />
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Uploaded At"
            value={uplAt ? uplAt.toLocaleString() : 'N/A'}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
      </Grid>
      <Stepper activeStep={step} orientation="vertical" className={classes.stepper}>
        <Step>
          <StepLabel>Uploaded Excel File!</StepLabel>
        </Step>
        <Step>
          <StepLabel>Configure UI</StepLabel>
          <StepContent>
            <>
              <List dense>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Select the sheet to show it." />
                </ListItem>
                Hover over the sheet to reveal a toolbox above the current cell for configuring ...
                <ListItem className={classes.listItem}>
                  <ListItemText
                    primary="Visible region:"
                    primaryTypographyProps={{ color: 'textSecondary' }}
                  />
                </ListItem>
                <ListItem>
                  <UICfgListItemIcon>
                    <TopLeftIcon />
                  </UICfgListItemIcon>
                  <ListItemText primary="top left corner" />
                </ListItem>
                <ListItem>
                  <UICfgListItemIcon>
                    <BottomRightIcon />
                  </UICfgListItemIcon>
                  <ListItemText primary="bottom right corner" />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    primary="Cell editable?"
                    primaryTypographyProps={{ color: 'textSecondary' }}
                  />
                </ListItem>
                <ListItem>
                  <UICfgListItemIcon>
                    <EditableIcon />
                  </UICfgListItemIcon>
                  <ListItemText primary="make editable" />
                </ListItem>
                <ListItem>
                  <UICfgListItemIcon>
                    <ReadOnlyIcon />
                  </UICfgListItemIcon>
                  <ListItemText primary="make readonly" />
                </ListItem>
              </List>
            </>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Preview</StepLabel>
          <StepContent>
            <Typography variant="body2">
              Try it here! If you are ready, click "Publish" button above.
            </Typography>
          </StepContent>
        </Step>
      </Stepper>
    </>
  );
};

export default TplRSB;
