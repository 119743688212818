import * as React from 'react';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PublishIcon from '@material-ui/icons/Publish';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useAppSelector as useSelector } from '../../app/configureStore';
import { useButtonStackRootStyles } from '../general/utils';
import { TmplStatus, TmplToSave, getErrorMessageOfError, parseFileKey } from 'xacmn';
import { useAppDispatch } from '../../app/configureStore';
import { saveTmpl, selectTmpl } from './tplSlice';
import { notify } from '../general/generalSlice';
import { Base64 } from 'js-base64';

const TplPreviewBtnBar: React.FC = () => {
  const { root } = useButtonStackRootStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { encodedKey } = useParams();
  const tpl = useSelector(selectTmpl());
  if (!encodedKey) return null;

  const { tmplId, fileId, fileName } = parseFileKey(Base64.decode(encodedKey || ''));
  const updateTplStatus = (tplStatus: TmplStatus) => async (): Promise<void> => {
    try {
      const tmpl: TmplToSave = {
        tmplId,
        fileId,
        fileName,
        tmplStatus: tplStatus,
      };
      await dispatch(saveTmpl(tmpl)).unwrap();
      navigate('..', { replace: true });
    } catch (rejectedValueOrSerializedError) {
      dispatch(
        notify({
          variant: 'error',
          message: getErrorMessageOfError(rejectedValueOrSerializedError),
        }),
      );
    }
  };
  const publish = updateTplStatus('published');
  const archive = updateTplStatus('archived');

  return (
    <div className={root}>
      <Button
        variant="contained"
        color="default"
        value="Submit"
        onClick={() => navigate('..')}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        Back to Template List
      </Button>
      <Button
        variant="contained"
        color="secondary"
        value="Submit"
        onClick={() => navigate(`../uicfg/${encodedKey}`)}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        Configure UI
      </Button>
      {!tpl || (tpl && tpl.tmplStatus === 'archived') ? null : (
        <Button
          style={{ marginLeft: 'auto' }}
          variant="contained"
          color="primary"
          onClick={tpl.tmplStatus === 'draft' ? publish : archive}
          startIcon={tpl.tmplStatus === 'draft' ? <PublishIcon /> : <ArchiveIcon />}
        >
          {tpl.tmplStatus === 'draft' ? 'Publish' : 'Archive'}
        </Button>
      )}
    </div>
  );
};

export default TplPreviewBtnBar;
