import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { logout } from '../login';
import { getLoggedInUserInfo } from '../utils';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

const menuId = 'xlapp-profile-menu';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {
      marginLeft: theme.spacing(1),
    },
  }),
);

const UserMenu: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { account } = useParams();
  if (!account) {
    return null;
  }
  const userInfo = getLoggedInUserInfo();

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  const handleProfile = () => {
    setAnchorEl(null);
    const profileUrl = `/${account}/users/profile/${userInfo?.username}`;
    navigate(profileUrl);
  };

  const loggedInUserInfo = getLoggedInUserInfo();

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircle />
        {loggedInUserInfo ? (
          <Typography className={classes.name} variant="body2">
            {`${loggedInUserInfo?.name} (${loggedInUserInfo?.username})`}
          </Typography>
        ) : null}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleProfile}>Profile</MenuItem>
        <MenuItem onClick={handleLogout}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
