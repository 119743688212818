import _ from 'lodash';
import { CognitoJwtVerifier } from 'aws-jwt-verify';
import { TenantPublic } from 'xacmn';
import { apiUrl } from './config';
import { CognitoIdTokenPayload } from 'aws-jwt-verify/jwt-model';

var REGION = 'us-east-1';

const getTenantInURI = () => {
  // console.debug('getTenantInURI: windows.location', window.location.pathname);
  const tenant = window.location.pathname.split('/')[1];
  // console.debug(`getTenantInURI: tenant in URI: ${tenant}`);
  return tenant;
};

const isValidTenantName = (tenantName: string | null | undefined) => {
  if (!tenantName) {
    return false;
  }
  const isValid = /[0-9a-zA-Z_-]{3,16}/.test(tenantName);
  // console.debug(`isValidTenantName: tenant name ${tenantName} is valid: ${isValid}`);
  return isValid;
};

const getTenant = async (tenantName: string) => {
  // console.debug(`getTenant: fetching tenant info for ${tenantName}`);
  const tenant = await fetch(`${apiUrl}/tenant?tenantName=${tenantName}`).then((res) => res.json());
  // console.debug(`getTenant: tenant info: ${JSON.stringify(tenant)}`);
  return tenant;
};

//verify token
async function verifyToken(token: string, userPoolId: string, appClientId: string): Promise<void> {
  // console.debug(
  //   ` verifyToken: verifying token ${token}, userPoolId ${userPoolId}, appClientId ${appClientId}...`,
  // );
  const verifier = CognitoJwtVerifier.create({
    userPoolId,
    tokenUse: 'id',
    clientId: appClientId,
  });

  let isValid = false;
  let tokenPayload: CognitoIdTokenPayload | undefined = undefined;
  try {
    tokenPayload = await verifier.verify(token);
    isValid = true;
    // console.debug('Token is valid. Payload:', tokenPayload);
  } catch {
    console.error('verifyToken: Token not valid!');
  }

  // console.debug(`verifyToken: token validation result: ${isValid}`);
  if (!isValid || !tokenPayload) {
    throw new Error('Signature verification failed');
  }

  // console.debug('verifyToken: token signature verified');
  //verify token has not expired
  // console.debug('verifyToken: verifying token expiration...');
  if (Date.now() >= tokenPayload.exp * 1000) {
    throw new Error('Token expired');
  }

  //verify app_client_id
  // console.debug('verifyToken: verifying token audience...');
  var n = tokenPayload.aud.localeCompare(appClientId);
  if (n !== 0) {
    throw new Error('Token was not issued for this audience');
  }

  // verified!
}

//Generate a Random String
const getRandomString = () => {
  const randomItems = new Uint32Array(28);
  crypto.getRandomValues(randomItems);
  const binaryStringItems = _.map(randomItems, (dec) => `0${dec.toString(16).substr(-2)}`);
  const randomString = binaryStringItems.reduce((acc, item) => `${acc}${item}`, '');
  // console.debug(`getRandomString: random string: ${randomString}`);
  return randomString;
};

//Encrypt a String with SHA256
const encryptStringWithSHA256 = async (str: string) => {
  const PROTOCOL = 'SHA-256';
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(str);
  const dataDigest = await crypto.subtle.digest(PROTOCOL, encodedData);
  // console.debug(`encryptStringWithSHA256: encrypted string: ${dataDigest}`);
  return dataDigest;
};

//Convert Hash to Base64-URL
const hashToBase64url = (arrayBuffer: ArrayBuffer) => {
  const items = new Uint8Array(arrayBuffer);
  const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '');
  const decodedHash = btoa(stringifiedArrayHash);

  const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  // console.debug(`hashToBase64url: base64 url: ${base64URL}`);
  return base64URL;
};

interface Tokens {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: 'Bearer';
}

function _cognitoUrl(tenantId: string, region: string) {
  const url = 'https://' + tenantId + '.auth.' + region + '.amazoncognito.com';
  // console.debug(` _cognitoUrl: cognito url: ${url}`);
  return url;
}

async function checkTokens() {
  // console.debug('checkTokens: checking tokens...');
  // I must have account_info to verify tokens
  let tenant: TenantPublic = getTenantFromSession();
  // console.debug(`checkTokens: tenant info from session: ${JSON.stringify(tenant)}`);

  // get tenantName from second source
  // 1. browser URI
  let intendedTenant = getTenantInURI();
  // console.debug(`checkTokens: tenant name from URI: ${intendedTenant}`);
  let changeCurrentURL = false;
  let currentURI;
  if (!isValidTenantName(intendedTenant)) {
    // console.debug('checkTokens: tenant name from URI is invalid');
    // 2. 'current_url' saved before
    currentURI = sessionStorage.getItem('current_url');
    // console.debug(`checkTokens: current url: ${currentURI}`);
    if (currentURI) {
      intendedTenant = new URL(currentURI).pathname.split('/')[1];
      // console.debug(`checkTokens: tenant name from current url: ${intendedTenant}`);
      if (isValidTenantName(intendedTenant)) {
        // console.debug('checkTokens: tenant name from current url is valid');
        changeCurrentURL = true;
      } else {
        // console.debug('checkTokens: tenant name from current url is invalid');
      }
    } else {
      // console.debug('checkTokens: current url is not found');
    }
  }

  // verify everything consistent
  if (isValidTenantName(intendedTenant)) {
    // console.debug(`checkTokens: tenant name ${intendedTenant} is valid`);
    if (intendedTenant !== tenant.tenantName) {
      throw new Error('saved account is not intended');
    }
  } else {
    changeCurrentURL = true;
    currentURI = `${window.location.origin}/${tenant.tenantName}`;
    // console.debug(
    //   `checkTokens: tenant name ${intendedTenant} is invalid, set currrentURL as ${currentURI}`,
    // );
  }

  let tokens: Tokens = getSessionTokens();
  // console.debug(`checkTokens: tokens from session: ${JSON.stringify(tokens)}. Verifying...`);

  await verifyToken(tokens.id_token, tenant.userPoolId, tenant.appClientId);
  // console.debug('checkTokens: id token verified');

  // Fetch from /user_info
  // console.debug('checkTokens: fetching user info...');
  const response = await fetch(_cognitoUrl(tenant.tenantId, REGION) + '/oauth2/userInfo', {
    method: 'post',
    headers: {
      authorization: 'Bearer ' + tokens.access_token,
    },
  });
  const userInfo = await response.json();
  // console.debug(`checkTokens: user info: ${JSON.stringify(userInfo)}`);

  sessionStorage.removeItem('pkce_state');
  sessionStorage.removeItem('code_verifier');
  sessionStorage.removeItem('code_challenge');
  sessionStorage.removeItem('current_url');

  sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  sessionStorage.setItem('id_token', tokens.id_token);

  if (changeCurrentURL) {
    // console.debug(`checkTokens: change current url to ${currentURI}. Redirecting...`);
    window.history.pushState(null, '', currentURI);
  }
}

function getSessionTokens() {
  const savedTokens = sessionStorage.getItem('tokens');
  let tokens: Tokens;
  if (savedTokens) {
    tokens = JSON.parse(savedTokens);
  } else {
    throw new Error('tokens not found');
  }
  // console.debug(`getSessionTokens: tokens from session: ${JSON.stringify(tokens)}`);
  return tokens;
}

function getTenantFromSession() {
  let tenantStr = sessionStorage.getItem('account_info');
  let tenant: TenantPublic;
  if (!tenantStr) {
    throw new Error('failed to get account info');
  } else {
    tenant = JSON.parse(tenantStr);
  }
  // console.debug(`getTenantFromSession: tenant info from session: ${JSON.stringify(tenant)}`);
  return tenant;
}

function cleanLoginInfo() {
  sessionStorage.removeItem('account_info');
  sessionStorage.removeItem('pkce_state');
  sessionStorage.removeItem('code_verifier');
  sessionStorage.removeItem('code_challenge');
  sessionStorage.removeItem('current_url');

  sessionStorage.removeItem('tokens');
  sessionStorage.removeItem('userInfo');
  sessionStorage.removeItem('id_token');
  // console.debug('cleanLoginInfo: login info cleaned');
}

export function logout() {
  const tenant = getTenantFromSession();
  const currentURI = window.location.origin;
  const logoutUrl =
    _cognitoUrl(tenant.tenantId, REGION) +
    `/logout?client_id=${tenant.appClientId}&logout_uri=${encodeURIComponent(currentURI)}`;
  cleanLoginInfo();
  sessionStorage.setItem('logout_account', tenant.tenantName);
  // console.debug(`logout: Logging out ... redirecting to ${logoutUrl}`);
  window.location.href = logoutUrl;
}

async function startLogin(): Promise<void> {
  // console.debug('startLogin: attempting login...');
  // do we have tenant in url?
  let intendedTenant = getTenantInURI();
  // console.debug(`startLogin: tenant name from URI: ${intendedTenant}`);
  if (!isValidTenantName(intendedTenant)) {
    // console.debug('startLogin: tenant name from URI is invalid');
    // .. if not, try 'account_info'
    try {
      // console.debug('startLogin: trying to get tenant name from session ...');
      intendedTenant = getTenantFromSession()?.tenantName;
      // console.debug(`startLogin: tenant name from session: ${intendedTenant}`);
    } catch (e) {
      // console.debug(
      //   `startLogin: Get tenant from session encountered exception. We have tenant as ${intendedTenant}...`,
      // );
    }
    // .. if not, do we have 'current_url'?
    if (!isValidTenantName(intendedTenant)) {
      // console.debug('startLogin: tenant name is invalid');
      const currentURI = sessionStorage.getItem('current_url');
      // console.debug(`startLogin: current url: ${currentURI}`);
      if (currentURI) {
        intendedTenant = new URL(currentURI).pathname.split('/')[1];
        // console.debug(`startLogin: tenant name from current url: ${intendedTenant}`);
      }
      // .. tried everything, give up
      // console.debug(`startLogin: Now we have tenant as ${intendedTenant}...`);
      if (!isValidTenantName(intendedTenant)) {
        throw new Error('cannot identify account information');
      }
      // console.debug(`startLogin: tenant name ${intendedTenant} is valid`);
    }
  }
  // console.debug(`startLogin: attempting login with tenant name ${intendedTenant}...`);

  // console.debug('startLogin: cleaning login info...');
  cleanLoginInfo();

  // console.debug('startLogin: fetching tenant info...');
  const tenant: TenantPublic = await getTenant(intendedTenant);
  // console.debug(`startLogin: tenant info: ${JSON.stringify(tenant)}`);
  if (!isValidTenantName(tenant?.tenantName)) {
    throw new Error('failed to retrieve account info from server');
  }
  sessionStorage.setItem('account_info', JSON.stringify(tenant));
  // console.debug('startLogin: tenant info saved to session');

  const currentURI = window.location.href;
  sessionStorage.setItem('current_url', currentURI);
  // console.debug(`startLogin: current url ${currentURI} saved to session`);

  // Create random "state"
  const state = getRandomString();
  sessionStorage.setItem('pkce_state', state);
  // console.debug(`startLogin: pkce_state ${state} saved to session`);

  // Create PKCE code verifier
  const code_verifier = getRandomString();
  sessionStorage.setItem('code_verifier', code_verifier);
  // console.debug(`startLogin: code_verifier ${code_verifier} saved to session`);

  // Create code challenge
  var arrayHash = await encryptStringWithSHA256(code_verifier);
  var code_challenge = hashToBase64url(arrayHash);
  sessionStorage.setItem('code_challenge', code_challenge);
  // console.debug(`startLogin: code_challenge ${code_challenge} saved to session`);

  const redirectURI = window.location.origin;

  // Redirect user-agent to /authorize endpoint
  const authorizeEndpoint =
    _cognitoUrl(tenant.tenantId, REGION) +
    '/oauth2/authorize?response_type=code&state=' +
    state +
    '&client_id=' +
    tenant.appClientId +
    '&redirect_uri=' +
    redirectURI +
    '&scope=openid&code_challenge_method=S256&code_challenge=' +
    code_challenge +
    '&prompt=login';
  // console.debug(`startLogin: redirecting to ${authorizeEndpoint}`);
  window.location.href = authorizeEndpoint;
}

async function fetchTokenWithCode(
  code_verifier: string,
  code: string,
  tenantId: string,
  appClientId: string,
) {
  // console.debug('fetchTokenWithCode: fetching token with code...');
  const currentURI = sessionStorage.getItem('current_url');
  // console.debug(`fetchTokenWithCode: current url from session: ${currentURI}`);
  if (!currentURI) {
    throw new Error('current URL lost');
  }
  const redirectURI = new URL(currentURI).origin;
  const endPoint =
    _cognitoUrl(tenantId, REGION) +
    '/oauth2/token?grant_type=authorization_code&client_id=' +
    appClientId +
    '&code_verifier=' +
    code_verifier +
    '&redirect_uri=' +
    redirectURI +
    '&code=' +
    code;
  // console.debug(`fetchTokenWithCode: fetch token with code endpoint: ${endPoint}. Fetching...`);
  const tokenResp = await fetch(endPoint, {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const tokens = await tokenResp.json();
  // console.debug(`fetchTokenWithCode: tokens fetched: ${JSON.stringify(tokens)}`);
  return tokens;
}

// Main Function
// this function will be entered multiple times during the login process
async function login(): Promise<boolean> {
  try {
    // console.debug('login: login called ...');
    var urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    // console.debug(`login: code from location: ${code}`);

    //If code not present then request code else request tokens
    if (!code) {
      // console.debug('login: code not found, start checkTokens ...');
      await checkTokens();
    } else {
      // console.debug('login: code found, start fetching token with code ...');
      // Verify state matches
      const state = urlParams.get('state');
      if (sessionStorage.getItem('pkce_state') !== state) {
        throw new Error('Invalid state');
      }
      // console.debug('login: state verified');
      const code_verifier = sessionStorage.getItem('code_verifier');
      if (!code_verifier) {
        throw new Error('code verifier not found');
      }
      // console.debug(`login: code verifier: ${code_verifier}`);
      const tenant = getTenantFromSession();
      // console.debug(`login: tenant info from session: ${JSON.stringify(tenant)}`);
      // Fetch OAuth2 tokens from Cognito
      // console.debug('login: fetching token with code...');
      const tokens = await fetchTokenWithCode(
        code_verifier,
        code,
        tenant.tenantId,
        tenant.appClientId,
      );
      // console.debug(`login: tokens fetched: ${JSON.stringify(tokens)}`);
      sessionStorage.setItem('tokens', JSON.stringify(tokens));
      // console.debug('login: tokens saved to session. Start to check tokens...');
      await checkTokens();
    }
    return true;
  } catch (error) {
    // console.debug('login: login failed');
    // console.debug('login: ', error);
    // console.debug('login: start login again...');
    await startLogin();
    return false;
  }
}

export const refreshToken = async () => {
  let tokens: Tokens = getSessionTokens();
  const { refresh_token } = tokens;
  const tenant = getTenantFromSession();
  const client_id = tenant.appClientId;
  const res = await fetch(`${_cognitoUrl(tenant.tenantId, REGION)}/oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: Object.entries({
      grant_type: 'refresh_token',
      client_id: client_id,
      redirect_uri: window.location.origin,
      refresh_token: refresh_token,
    })
      .map(([k, v]) => `${k}=${v}`)
      .join('&'),
  });
  if (!res.ok) {
    throw new Error(await res.json());
  }
  const newTokens = await res.json();

  tokens.access_token = newTokens.access_token;
  tokens.id_token = newTokens.id_token;
  tokens.expires_in = newTokens.expires_in;
  tokens.token_type = newTokens.token_type;
  sessionStorage.setItem('tokens', JSON.stringify(tokens));
  sessionStorage.setItem('id_token', tokens.id_token);
};

export default login;
